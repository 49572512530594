import React, { useEffect, useState } from 'react';
import NavBar from '../../Layouts/Admin/NavBar';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))



function Dashbord() {


  const { id } = useParams();
  const [loading, setloading] = useState(true);
  const [eventsingle, setEventsingle] = useState([]);
  //const [evenbooth, setEventbooth] = useState([]);
  useEffect(() => {
    axios.get(`api/event/${id}`).then(res => {
      if (res.status === 200) {
        setEventsingle(res.data.event);
        const eventID = res.data.eventid;
        localStorage.setItem('eventid', eventID);
        localStorage.setItem('user_id', res.data.user_id);

      }
      else {
        setloading(false);
      }


    });

  }, []);

  localStorage.setItem('eventname', eventsingle.eventname);
  const onClickReload = () => {
    window.location.reload();
  }

  const eventid = localStorage.getItem('eventid');
  const selfprint = localStorage.getItem('auth_name');

  const [module, setModules] = useState([]);
  const organiserId = localStorage.getItem('user_id');
  const fetchData = async () => {
    try {

      const response = await axios.get(`api/modules-access/${eventid},${organiserId}`);
      setModules(response.data.mod);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  function createData(
    ids,
    name,
    calories,
    fat,
    carbs,
    protein,

  ) {
    return { ids, name, calories, fat, carbs, protein };
  }


  const rows = [
    createData(1, 'Frozen yoghurt', "Frozen@test.com", 123456,),
    createData(2, 'Ice cream sandwich', "cream@test.com", 123456,),
    createData(3, 'Eclair', "Eclair@test.com", 123456,),
    createData(4, 'Cupcake', "Cupcake@test.com", 123456,),
    createData(5, 'Gingerbread', "Gingerbread@test.com", 123456,)
  ]



  const role = localStorage.getItem('role');
  const abstractURL = window.location.origin + ('/#/abstract-author/') + eventid;
  const exhibitionURL = window.location.origin + ('/#/exhibition/guest/') + eventid;

  const [abstractURLQrCode, setAbstractURLQrCode] = useState(abstractURL);

  const downloadAbstractQRCode = () => {
    const qrCodeURL = document.getElementById('qrAbstractCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "Abstract_QrCode.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  const downloadAbstractBarcode = () => {
    alert('yes access')
    const qrCodeURL = document.getElementById('barcodeAbstractCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "Abstract_QrCode.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }


  const [exhibitionURLQrCode, setExhibitionURLQrCode] = useState(exhibitionURL);

  const downloadExhibitionQRCode = () => {
    const qrCodeURL = document.getElementById('exhibitionqrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "Exhibition_QrCode.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  const [reloadCounter, setReloadCounter] = useState(0);

  React.useEffect(() => {
    const organiserId = localStorage.getItem('user_id')
    axios.get(`api/modules-access/${eventid},${organiserId}`).then(res => {
      if (res.status === 200) {
        setModules(res.data.mod);
      }

    });
    if (reloadCounter < 5) {
      const timeoutId = setTimeout(() => {
        setReloadCounter(prevCounter => prevCounter + 1);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [reloadCounter]);

  let backGroundButtonByEvent = ''; // Declare the variable outside

  if (eventid == 63) {
    backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
  }


  // Using local time format
  const [deadline, setDeadline] = useState('2024-09-02T00:00:00+03:00');

  useEffect(() => {
    try {
      const currentDate = new Date();
      const deadlineDate = new Date(deadline);

      //console.log("currentDate", currentDate.toString());
      //console.log("deadline", deadlineDate.toString());

      // Check if the current date is greater than or equal to the deadline
      if (currentDate >= deadlineDate) {
        alert("The Abstract Deadline is over.");
      }
    } catch (error) {
      console.error("Error comparing dates:", error);
    }
  }, [deadline]);


  return (

    <Box sx={{ width: '100%' }}>

      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >


          <Button sx={{
            marginRight: '1%',
            backgroundColor: backGroundButtonByEvent,
            marginTop: '2%',
            float: 'right',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            onClick={() => onClickReload()}>
            Refresh
          </Button>
        </h4>
        {/* <button onClick={refreshData}>Refresh API Data</button> */}
      </Box>


      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={15}>

        <Grid item xs={4} >
          <Card sx={{ minWidth: 275 }}>
            <Item>1 </Item>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                ID   {eventsingle.id}
              </Typography>
              <Typography variant="h5" component="div">
                {eventsingle.eventname}

              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {/* object */}
              </Typography>
              <Typography variant="body2">
                {/* well meaning and kindly. */}
                <br />
                {/* {'"a benevolent smile"'} */}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        {
          module.map((item, index) => {

            if (item.module === 'AMS') {
              return (
                <Grid item xs={4} key={item.id}>
                  <Card sx={{ minWidth: 275 }}>
                    <Item>2</Item>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {eventsingle.eventname}
                      </Typography>
                      <Typography variant="h5" component="div">
                        Abstract Management System
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {/* adjective */}
                      </Typography>
                      <Typography variant="body2">
                        {/* well meaning and kindly. */}
                        <br />
                        {/* {'"a benevolent smile"'} */}
                      </Typography>
                    </CardContent>
                    <CardActions >
                      <Button size="small" component={Link} to={`/admin/abstract/dashbord`}>Learn More</Button>
                      <Button size="small" > </Button>
                      <Button size="small" > </Button>
                      {
                        role === "Author" ?
                          <Button size="small"  variant="contained" component={Link} to={`/admin/abstract/add-approve-article`} sx={{backgroundColor: backGroundButtonByEvent,}}> Submit Abstract</Button>
                          : ""
                      }


                    </CardActions>
                  </Card>
                </Grid>
              )
            }


          })

        }

        {/* {role === "Author" ?
            <Grid item xs={4} >
              <Card sx={{ minWidth: 275 }}>
                <Item>2</Item>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {eventsingle.eventname}
                  </Typography>
                  <Typography variant="h5" component="div">
                    Abstract Management System
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                   
                  </Typography>
                  <Typography variant="body2">
                  
                  </Typography>
                </CardContent>
                <CardActions >
                  <Button size="small" component={Link} to={`/admin/abstract/dashbord`}>Learn More</Button>
                  <Button size="small" > </Button>
                  <Button size="small" > </Button>
                  {
                    role === "Author" ?
                      <Button size="small" variant="contained" component={Link} to={`/admin/abstract/add-approve-article`}> Submit Abstract</Button>
                      : ""
                  }


                </CardActions>
              </Card>
            </Grid> : "" } */}

        {
          module.map((item, index) => {

            if (item.module === 'EMS') {
              return (

                <Grid item xs={4} key={item.id}>
                  <Card sx={{ minWidth: 275 }}>
                    <Item>3</Item>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {eventsingle.eventname}
                      </Typography>
                      <Typography variant="h5" component="div">
                        Exhibition Management System
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {/* adjective */}
                      </Typography>
                      <Typography variant="body2">
                        {/* well meaning and kindly. */}
                        <br />
                        {/* {'"a benevolent smile"'} */}
                      </Typography>
                    </CardContent>
                    <CardActions >
                      {
                        selfprint == "Self Printing" ? <Button size="small" component={Link} to={`/admin/exhibitor/scan`}>Learn More</Button> :

                          <Button size="small" component={Link} to={`/admin/exhibition/dashbord`}>Learn More</Button>
                      }
                      <Button size="small" > </Button>
                      <Button size="small" > </Button>



                    </CardActions>
                  </Card>
                </Grid>
              )
            }



          })
        }



        {/* <Grid item xs={4}> */}
        {/* <Card sx={{ minWidth: 275 }}>
          <Item>3</Item>
          <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card> */}
        {/* </Grid> */}
        {/* <Grid 
        item md={2} implementation="css" smDown component={Hidden}
         /> */}
        {
          module.map((item, index) => {

            if (item.module === 'AMS') {
              return (
                <Grid container spacing={2} key={index + 1}>
                  <Grid item xs={6} style={{ color: "purple", marginTop: "2%" }} >
                    {
                      role === "organiser" ?
                        <> <Item> {abstractURL}
                          <div>
                            <h1>Abstract Author Registration QRCode</h1>
                            <QRCode id='qrAbstractCodeEl' value={abstractURLQrCode} size={150} />
                            <div style={{ marginTop: "2em" }}>
                              <input value={abstractURLQrCode} style={{ width: "300px" }} onChange={(e) => { setAbstractURLQrCode(e.target.value) }} disabled hidden />
                              <Button sx={{ marginTop: '2%', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                value="Download"
                                value="Download"
                                onClick={downloadAbstractQRCode}>
                                Download
                              </Button>
                            </div>
                          </div>
                        </Item><br /> </> : ""
                    }
                  </Grid>

                  <Grid item xs={6} style={{ color: "purple", marginTop: "2%" }} >
                    {
                      role === "organiser" ?
                        <> <Item>{abstractURL}
                          <div>
                            <h1>Abstract Author Registration BarCode</h1>
                            <Barcode id='barcodeAbstractCodeEl' format="CODE128" width={1} value={abstractURLQrCode} />
                            <div style={{ marginTop: "2em" }}>
                              <input value={abstractURLQrCode} style={{ width: "300px" }} onChange={(e) => { setAbstractURLQrCode(e.target.value) }} disabled hidden />
                              <Button sx={{ marginTop: '2%', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                value="Download"
                                value="Download"
                                onClick={downloadAbstractBarcode}>
                                Download Barcode
                              </Button>
                            </div>
                          </div>
                        </Item>  <br /></> : ""
                    }
                  </Grid> </Grid>
              )
            }



          })
        }

        {
          module.map((item, index) => {

            if (item.module === 'EMS') {
              return (
                <Grid container spacing={2} key={index + 1}>
                  {
                    selfprint == "Self Printing" ? "" :
                      <>
                        <Grid item xs={6} style={{ color: "purple", }}>
                          {
                            role === "organiser" ?
                              <> <Item>{exhibitionURL}
                                <div>
                                  <h1>Exhibition Visitor Registration QRCode</h1>
                                  <QRCode id="exhibitionqrCodeEl" size={150} value={exhibitionURLQrCode} />
                                  <div style={{ marginTop: "2em" }}>
                                    <input
                                      value={exhibitionURLQrCode}
                                      style={{ width: "300px" }}
                                      onChange={(e) => { setExhibitionURLQrCode(e.target.value) }}
                                      disabled hidden />
                                    <Button sx={{ marginTop: '2%', ":hover": { color: "white" } }}
                                      color='success'
                                      size='small'
                                      variant='contained'
                                      value="Download"
                                      value="Download"
                                      onClick={downloadExhibitionQRCode}>
                                      Download
                                    </Button>
                                  </div>
                                </div>
                              </Item> <br /></> : ""
                          }
                        </Grid>

                        <Grid item xs={6} style={{
                          color: "purple",//backgroundColor: "green",marginTop: "2%"
                        }}>
                          {
                            role === "organiser" ?
                              <> <Item>{exhibitionURL}
                                <div>
                                  <h1>Exhibition Visitor Registration  BarCode</h1>

                                  <Barcode
                                    id="exhibitionqrCodeEl" width={1} value={exhibitionURLQrCode} />
                                  <div style={{ marginTop: "2em" }}>
                                    <input
                                      value={exhibitionURLQrCode}
                                      style={{ width: "300px" }} onChange={(e) => { setExhibitionURLQrCode(e.target.value) }} disabled hidden />
                                    <Button sx={{
                                      marginTop: '2%', ":hover": { color: "white" }
                                    }}
                                      color='success'
                                      size='small'
                                      variant='contained'
                                      value="Download"
                                      value="Download"
                                      onClick={downloadExhibitionQRCode}>
                                      Download BarCode
                                    </Button>
                                  </div>
                                </div>
                              </Item> <br /></> : ""
                          }
                        </Grid>
                      </>
                  }
                </Grid>
              )
            }
          })
        }


        {/* <Item>{window.location.origin}/#/abstract-author/{(eventid)}</Item> */}
        {/* window.location.href // Returns full path, with domain name
          window.location.origin // returns window domain url Ex : "https://stackoverflow.com"
          window.location.pathname // returns relative path, without domain name */}



        {/* <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '7%' }}>
                    <h4 sx={{ m: 2, color: '#000000' }}> Event Management
                       
                    </h4>
                    <Card sx={{ width: '60vw', float: 'center' }} >
                        <CardContent >
                            <TableContainer >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow sx={{ color: '#FFFFFF' }}>
                                            <TableCell >#</TableCell>
                                            <TableCell > Name</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell  >Contact</TableCell>
                                            <TableCell  >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, i) => (
                                            <TableRow

                                                key={row.ids}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    
                                                <TableCell component="th" scope="row">
                                                    {i + 1}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell >{row.calories}</TableCell>
                                                <TableCell >{row.fat}</TableCell>
                                             
                                               
                                                <TableCell >
                                                        
                                                        <Button sx={{
                                                       
                                                        marginRight: '11%',
                                                        marginTop: '2%',
                                                        
                                                        ":hover": {
                                                            color: "white"
                                                        }
                                                    }}
                                                        color='primary'
                                                        size='small'
                                                        variant='contained'
                                                        component={Link}
                                                        to={`/admin/edituser/${row.id}`}> 
                                                        <VisibilityIcon/>
                                                    </Button>
                                                   
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Box> */}

      </Grid >
      <hr></hr>
    </Box>
  )
}

export default Dashbord
