import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import EmsNavbar from "../../frontend/EmsNavbar";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
import { FormatBold } from "@mui/icons-material";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormLabel from '@mui/material/FormLabel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';


import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useEffect } from "react";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Form = () => {
  const theme = useTheme();
  const history = useHistory();
  const eventid = localStorage.getItem('eventid');
  const [natureOfBusiness, setNatureOfBusiness] = useState([]);

  useEffect(() => {
    axios.get(`api/nature-business?id=${eventid}`).then(res => {
      if (res.status == 200) {
        const indexNatureOfBusinessData = res.data.natureData;
        setNatureOfBusiness(indexNatureOfBusinessData);
      }
    })

  }, []);
  const [productsServicesData, setProductServicesData] = useState([]);
  useEffect(() => {
    axios.get(`api/product-services?id=${eventid}`).then(res => {

      if (res.status == 200) {
        const productServiceData = res.data.productData;
        setProductServicesData(productServiceData);

      }
    })

  }, []);
  //console.log(productsServicesData)


  const [productServices, setProductServices] = useState([]);
  const handleChangeProduct = (e) => {

    const value = e.target.value;
    const checked = e.target.checked;

    //console.log(value, checked);
    if (checked) {
      setProductServices([...productServices, value])

    }
    else {
      setProductServices(productServices.filter((e) => (e !== value)));

    }

  }
 

  const [exhibitionRegisterInput, setExhibitionRegisterInput] = useState({
    name: "",
    email: "",
    jobTatile: "",
    phone: "",
    password: "",
    error_list: [],
  });
  const [nameTitle, setNameTitle] = useState('');
  const handleNameTitle = (e) => {
    setNameTitle(e.target.value);
  }

  // const [selectedValues, setSelectedValues] = useState('');

  // const handleChange = (event, rowId) => {
  //   setSelectedValues((prevSelectedValues) => ({
  //     ...prevSelectedValues,
  //     [rowId]: event.target.value,
  //   }));
  // }
  //console.log(selectedValues)

  const [RegOffice, setRegOffice] = useState("");
  const handleChangeRegOffice = (event) => {
    setRegOffice(event.target.value);
  };
  

  const [sharingYourInformation, setSharingYourInformation] = useState('');
  const handleCheckboxChange = (event) => {
    setSharingYourInformation({
      ...sharingYourInformation,
      [event.target.name]: event.target.checked,
    });
  };




  const exhibition_email = localStorage.getItem('exhibition_email');
  const handleInput = (e) => {
    e.persist();
    setExhibitionRegisterInput({ ...exhibitionRegisterInput, [e.target.name]: e.target.value });
  }
  //CustomFromStrt
  const belongTo = 9;

  const [customFieldLoad, setCustomFieldLoad] = React.useState([]);
  React.useEffect(() => {
    axios.get(`api/load-customfield-for-guest/${belongTo}/${eventid}`).then(res => {

      if (res.status === 200) {
        const FieldLoad = res.data.customFieldLoad;
        setCustomFieldLoad(FieldLoad)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const [customFieldInput, setCustomFieldInput] = useState('');
  //  const handleInputcustom = (e) => {
  //   e.persist();
  //   setCustomFieldInput({ ...customFieldInput, [e.target.name]: e.target.value });
  // }
  const [customFieldTextArea, setCustomFieldTextArea] = useState('');
  const handleInputCustomTextArea = (e) => {
    e.persist();
    setCustomFieldTextArea({ ...customFieldTextArea, [e.target.name]: e.target.value });
  }
  const [customFieldNumber, setCustomFieldNumber] = useState('');
  const handleInputCustomnumber = (e) => {
    e.persist();
    setCustomFieldNumber({ ...customFieldNumber, [e.target.name]: e.target.value });
  }

  const [selectedGender, setSelectedGender] = useState({});
  const handleChangeGender = (e) => {
    setSelectedGender(e.target.value);
  };

  const [selectedLoadDropDwon, setSelectedLoadDropDwon] = React.useState('');
  const handleTypeCustomLoad = (event) => {
    setSelectedLoadDropDwon(event.target.value);
  };

  const [multipleSeleteValue, setMultipleSeleteValue] = React.useState([]);
  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setMultipleSeleteValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }
  const [customFieldcheckbox, setCumstomFieldCheckbox] = React.useState([]);
  const handleChangeCustomcheckbox = (e) => {

    const value = e.target.value;
    const checked = e.target.checked;

    //console.log(value, checked);
    if (checked) {
      setCumstomFieldCheckbox([...customFieldcheckbox, value])

    }
    else {
      setCumstomFieldCheckbox(customFieldcheckbox.filter((e) => (e !== value)));

    }

  }

  const [customImage, setCustomtImage] = useState('')
  const [customFile, setCustomtFile] = useState('')

  //CustomFromEnd



  const exhibitionRegisterSubmit = (e) => {
    e.preventDefault();

    const data = {
      eventid,
      nameTitle,
      exhibition_email,
      name: exhibitionRegisterInput.name,
      email: exhibitionRegisterInput.email,
      jobTitle: exhibitionRegisterInput.jobTatile,
      companyName: exhibitionRegisterInput.companyName,
      country: exhibitionRegisterInput.country,
      city: exhibitionRegisterInput.city,
      phone: exhibitionRegisterInput.phone,
      natureOFbusiness: RegOffice,


      productServices,
      sharingYourInformation,

      customFieldInput,
      customFieldTextArea,
      customFieldNumber,
      selectedGender,
      selectedLoadDropDwon,
      multipleSeleteValue,
      customFieldcheckbox,
      customFile,
      customImage,

    }
    axios.get('/sanctum/csrf-cookie').then(response => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(`/api/guest-registration`, data,).then(res => {
        if (res.data.status === true) {
          swal('Success', "Register  Successful ", 'success');
          // history.push('/exhibition/verification');
          history.push('/exhibition/email-found');
          return false;
        }
        else if(res.data.status === 101){
          swal('Success', res.data.message, 'success');
        }
        else {
          setExhibitionRegisterInput({ ...exhibitionRegisterInput, error_list: res.data.validate_error })
        }
      });
    });
    
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container fixed >

        <Box sx={{ color: '#000000', height: '100vh' }} >

          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Typography style={{ color: 'red' }}>

{exhibitionRegisterInput.error_list.eventid ? '* '  + " " +" You probably came through a wrong link Please Register from the confrence Website Thank you.." :""}  
</Typography>
          
          <Box component="form" onSubmit={exhibitionRegisterSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Title</InputLabel>
                  <Select
                    value={nameTitle}
                    onChange={handleNameTitle}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Action" >
                    <MenuItem value={'Mr'}>Mr</MenuItem>
                    <MenuItem value={'Mrs'}>Mrs</MenuItem>
                    <MenuItem value={'Ms'}> Ms</MenuItem>
                    <MenuItem value={'Dr'}>Dr </MenuItem>
                    <MenuItem value={'Prof'}>Prof</MenuItem>
                    <MenuItem value={'Eng'}>Eng </MenuItem>
                    <MenuItem value={'Sheikh'}>Sheikh</MenuItem>
                  </Select>
                  <FormHelperText>{exhibitionRegisterInput.error_list.nameTitle} </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.name}
                  helperText={exhibitionRegisterInput.error_list.name}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleInput} value={exhibitionRegisterInput.email || exhibition_email}
                  helperText={exhibitionRegisterInput.error_list.exhibition_email}
                />
              </Grid>


              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="jobTatile"
                  required
                  fullWidth
                  id="jobTatile"
                  label="Job Title"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.jobTatile}
                  helperText={exhibitionRegisterInput.error_list.jobTatile}
                />
              </Grid>
              <Grid item xs={12} lg={6} >
                <TextField
                  autoComplete="given-name"
                  name="companyName"
                  required
                  fullWidth
                  id="companyName"
                  label="company Name"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.companyName}
                  helperText={exhibitionRegisterInput.error_list.companyName}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="country"
                  required
                  fullWidth
                  id="country"
                  label="country"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.country}
                  helperText={exhibitionRegisterInput.error_list.country}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="city"
                  required
                  fullWidth
                  id="city"
                  label="city"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.city}
                  helperText={exhibitionRegisterInput.error_list.city}
                />
              </Grid>
              <Grid item xs={12} lg={6} >
                <TextField
                  autoComplete="given-name"
                  name="phone"
                  required
                  fullWidth
                  id="firstName"
                  label="Mobile No:"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.phone}
                  helperText={exhibitionRegisterInput.error_list.phone}
                />
              </Grid>
              {
                customFieldLoad.map((item, index) => {
                  return (
                    <Grid item xs={6} key={index + 1}>
                      {
                        item.type == 'input' ?
                          <TextField
                            autoComplete="given-name"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            label={item.name}
                            autoFocus
                            name={item.field_name}
                            onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} /> : ""}
                      {/* helperText={item.required == "required" ? "item.name field is required" : "" } */}
                      {
                        item.type == 'multiSelect' ?
                          <FormControl sx={{ width: 300, }}>
                            {/* <InputLabel id="demo-simple-select-label">{item.name}</InputLabel> */}
                            <Select
                              multiple
                              displayEmpty
                              value={multipleSeleteValue}
                              onChange={handleChangeMulti}
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>{item.name}</em>;
                                }
                                return selected.join(', ');
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ 'aria-label': 'Without label' }}>
                              <MenuItem disabled value="">
                                <em>{item.name}</em>
                              </MenuItem>
                              {item.options.map((multipleOption, index) => (
                                <MenuItem
                                  key={index + 1}
                                  value={multipleOption.name}
                                  style={getStyles(multipleOption, multipleSeleteValue, theme)}>
                                  {multipleOption.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl> : ""}
                      {item.type == 'select' ?
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Selete</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedLoadDropDwon}
                            onChange={handleTypeCustomLoad}
                            label="Action" >
                            {item.options.map((dropDwonValue, index) => {
                              return (
                                <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                              )
                            })
                            }
                          </Select>
                          <FormHelperText> </FormHelperText>
                        </FormControl> : ""}
                      {
                        item.type == 'textarea' ?
                          <TextField
                            autoComplete="given-name"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            label={item.name}
                            autoFocus
                            multiline
                            rows={4}
                            name={item.field_name}
                            onChange={handleInputCustomTextArea} /> : ""}
                      {
                        item.type == 'number' ?
                          <TextField
                            autoComplete="given-name"
                            fullWidth
                            type="number"
                            inputProps={{ maxLength: 50 }}
                            label={item.name}
                            autoFocus
                            name={item.field_name}
                            onChange={handleInputCustomnumber} /> : ""}
                      {
                        item.type == 'radioBox' ?

                          <div key={index + 1}>
                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                            {
                              item.options.map((radioOption, index) => {
                                return (
                                  <div key={index + 1}>
                                    <>
                                      <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={selectedGender}
                                        onChange={handleChangeGender} key={index + 3}>

                                        <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                      </RadioGroup>

                                    </>
                                  </div>
                                )
                              })
                            }
                          </div> : ""}
                      {
                        item.type == 'checkBox' ?
                          <>
                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                              {item.options.map((multipleOption, index) => (
                                <FormControlLabel key={index + 1} control={<Checkbox />} label={multipleOption.name} value={multipleOption.name} onChange={handleChangeCustomcheckbox} />
                              ))}
                            </FormGroup></> : ""}
                      {
                        item.type == 'link' ?
                          <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""}
                      {
                        item.type == 'image' ?
                          <>
                            <label htmlFor="icon-button-file">
                              <TextField id="icon-button-file" type="file" onChange={(e) => setCustomtImage(e.target.files[0])} name="[licenseFile]" />
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                              </IconButton>
                            </label>
                            <FormHelperText>{"Upload Image"} </FormHelperText></> : ""}
                      {
                        item.type == 'file' ?
                          <>
                            <label htmlFor="contained-button-file">
                              <TextField id="contained-button-file" multiple type="file" onChange={(e) => setCustomtFile(e.target.files[0])} />
                              <IconButton color="primary" aria-label="upload picture" component="span" >
                                <AttachmentIcon />
                              </IconButton>
                            </label>
                            <FormHelperText>{"Upload File"}</FormHelperText></> : ""}
                    </Grid>
                  )
                })
              }

              <Grid item xs={12} lg={12} >
                <hr />
                <Typography component="h4" variant="subtitle1">
                  Please select your company nature of business / يُرجى تحديد طبيعة الأعمال التجارية لشركتك
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} >
                <TableContainer component={Paper} >
                  <hr />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Select</TableCell>
            <TableCell>Option Name 1</TableCell>
            <TableCell>Option Name 2</TableCell>
            <TableCell>Option Name 3</TableCell> */}
                        {/* Add more columns as needed */}
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {/* <TableRow  >
                        {
                          natureOfBusiness.map((row) => {
                            return (
                              <TableCell key={row.id}>
                                <Radio
                                  value={row.title}
                                  checked={selectedValues[1] == row.title}
                                  onChange={(event) => handleChange(event, 1)}
                                /><label>{row.title} &emsp;
                                  {row.arbic_title}</label>

                              </TableCell>
                            )
                          })
                        }

                      </TableRow> */}


                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
              <Grid container direction={"row"} spacing={1} >
              
                {
                  natureOfBusiness.map((row) => {
                    return (
                      <Grid item xs={3}  key={row.id}>
                        <Radio
                          key={row.id}
                          value={row.title}
                          checked={RegOffice === row.title}
                          onChange={handleChangeRegOffice}
                          
                        />
                        {row.title} &emsp;
                        {row.arbic_title}
                      </Grid>

                    )
                  })
                }

              </Grid>


              <Grid item xs={12} lg={12} >
                <hr />
                <Typography component="h4" variant="subtitle1">
                  Please select all the products and services that you have an interest in / الرجاء تحديد جميع المنتجات والخدمات التي تهتم بها
                </Typography>
              </Grid>

              <Grid item xs={12} lg={12} >
                <TableContainer component={Paper}>
                  <hr />
                  <Table>
                    <TableHead>
                      {/* <TableRow>
                      <TableCell > #</TableCell>
                      <TableCell align="left">English Title</TableCell>
                    </TableRow> */}

                    </TableHead>
                    <TableBody>

                      {
                        productsServicesData.map((row, index) => {
                          return (
                            <TableRow key={index + 1}>
                              <TableCell key={index + 1} >
                                <FormControlLabel required control={<Checkbox color="success" />}
                                  label={row.title}
                                  color="success"
                                  name={row.title} onChange={handleChangeProduct} value={row.id} />
                              </TableCell>
                            </TableRow>
                          )

                        })
                      }

                      {/* <TableCell lg={12}  >
                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Art الفن "
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="2" />
                        </TableCell> */}


                      {/* <TableRow key={2} >
                        <TableCell xs={12}>
                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Fit-out Services
                            خدمات التشطيبات الداخلية"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="3" />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Bed & Bedding
                            الأسرَّة ومفروشاتها"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="4" />
                        </TableCell>
                      </TableRow>
                      <TableRow key={3}>
                        <TableCell xs={12}>
                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Furniture & Furnishing
                            الأثاث والديكور"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="5" />

                        </TableCell>

                        <TableCell>
                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Kitchen & Bathrooms
                            المطابخ والحمامات"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="6" />
                        </TableCell>
                      </TableRow>
                      <TableRow key={4}>
                        <TableCell xs={12}>

                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Surfaces & Finishes
                            الأسطح والتشطيبات"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="7" />
                        </TableCell>
                        <TableCell>

                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Textiles
                            المنسوجات"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="8" />
                        </TableCell>
                      </TableRow>
                      <TableRow key={5}>
                        <TableCell xs={12}>

                          <FormControlLabel required control={<Checkbox color="success" />}
                            label="Workspace
                           مكان العمل"
                            color="success"
                            name="productServices" onChange={handleChangeProduct} value="9" />
                        </TableCell>

                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>


              <Grid item xs={12} lg={12} >
                <hr />
                <hr />
                <FormGroup>
                  <FormControlLabel required control={<Checkbox color="success" />}
                    label="By ticking this box, you confirm that you agree to sharing your information with this event’s sponsors and partners."
                    color="success"
                    name="sharingInformation" onChange={handleCheckboxChange} />
                  <FormControlLabel required control={<Checkbox color="success" />}
                    label="By ticking this box, you confirm that you agree to sharing your information with this event’s sponsors and partners."
                    color="success"
                    name=" agreeTerms" onChange={handleCheckboxChange} />

                </FormGroup>

              </Grid>
              <Grid item xs={12} lg={12} >
                <hr />
                <Typography component="h4" variant="subtitle1">
                  You have the right to withdraw consent to marketing at any time by emailing privacy@dmgevents.com or via opt-out links on any marketing messages sent to you. </Typography>
                يحق لك سحب موافقتك على التسويق في أي وقت عن طريق مراسلتنا بالبريد الإلكتروني على privacy@dmgevents.com أو من خلال روابط إلغاء الاشتراك في أي رسائل تسويقية يتم إرسالها إليك
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} >
              <Button
                type="submit"

                variant="contained"
                sx={{ mt: 3, mb: 2 }} >
                Sign Up
              </Button>
            </Grid>
            <Grid container justifyContent="flex-end" item>
              <Grid item >
                <Link to="/login" variant="body2" style={{ color: '#1976d2' }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default Form;
