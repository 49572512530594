import React from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const EventIDPage = () => {
  
  const location = useLocation();
    const URL = window.location.href;
  
   const urlEventId =  document.URL.split("/").slice(-1)[0];
  
  localStorage.setItem('eventid', urlEventId);
  const history = useHistory();
  useEffect(() => {

    if (URL !== "") {
    //  history.push('/abstract-register');
    if(urlEventId  == 63)
    {
      history.push('/author/login');
    }
    else
    history.push('/abstract-login');
    }
  });



}

export default EventIDPage





