import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import swal from "sweetalert";
import ListItemIcon from '@mui/material/ListItemIcon';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
const approve = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}>
    <FactCheckIcon />
  </Box>
);
const pending = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}
  >
    <SwipeLeftIcon />
  </Box>
);
const reject = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}
  >
    <SwipeLeftIcon />
  </Box>
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))
const abstract_url = localStorage.getItem('abstract_url');
function Dashbord() {
  const [abstractArticle, setAbstractArticle] = React.useState([]);
  const eventid = localStorage.getItem('eventid');
  const role = localStorage.getItem('role');
  const eventName = localStorage.getItem('eventname');
  React.useEffect(() => {
    axios.get(`api/abstractApprovleArticle?id=${eventid}`).then(res => {
      if (res.status === 200) {
        const abstractArticleData = res.data.totalcount;
        setAbstractArticle(abstractArticleData)
      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);


  //const Hidden = 'hidden';


  function createData(
    ids,
    name,
    calories,
    fat,
    carbs,
    protein,

  ) {
    return { ids, name, calories, fat, carbs, protein };
  }
  const rows = [
    createData(1, 'Frozen yoghurt', "Frozen@test.com", 123456,),
  ]

  let backGroundButtonByEvent = "";
  if (eventid == 63) {
    backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
  }
  else {
    backGroundButtonByEvent = '#145348'; // Default background color
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Item sx={{ mt: 8 }}><h2>Abstract Dashboard</h2></Item>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={5}>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }} >
            <CardContent>
              <Typography variant="h5" component="div">
                Approved Abstract
              </Typography>
              <Typography variant="body2" variant="h2" color="success.main" key={1}>
                {approve}. {abstractArticle.totalApprovearticle}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"  component={Link}
                            to={`/admin/abstract/approve-article`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Pending/Issue Abstract
              </Typography>
              <Typography variant="body2" variant="h2" color="secondary" key={2}>
                {pending}. {abstractArticle.totalpending}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/admin/abstract/pending-article`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Rejected Abstract
              </Typography>
              <Typography variant="body2" variant="h2" color="error.main" key={3}>
                {reject}. {abstractArticle.totalreject}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/admin/abstract/reject-article`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={10} style={{
          color: "purple",
          marginTop: "2%"
        }}> {
          role === "Author" ?
          <Item><Button  variant="contained"
          component={Link} to={`/admin/abstract/add-approve-article`} sx={{backgroundColor: backGroundButtonByEvent,}}>Submit Abstract</Button></Item> : ""
        }
         
          {/* <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '7%' }}>
            <h4 sx={{ m: 2, color: '#000000' }}> Event Management
            </h4>
            <Card sx={{ width: '60vw', float: 'center' }} >
              <CardContent >
                <TableContainer >
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow sx={{ color: '#FFFFFF' }}>
                        <TableCell >#</TableCell>
                        <TableCell > Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell  >Contact</TableCell>
                        <TableCell  >Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow
                          key={row.ids}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {i + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell >{row.calories}</TableCell>
                          <TableCell >{row.fat}</TableCell>
                          <TableCell >
                            <Button sx={{
                              marginRight: '11%',
                              marginTop: '2%',
                              ":hover": {
                                color: "white"
                              }
                            }}
                              color='primary'
                              size='small'
                              variant='contained'
                              component={Link}
                              to={`/admin/edituser/${row.id}`}>
                              <VisibilityIcon />
                            </Button>

                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box> */}
        </Grid>
      </Grid>
      <hr></hr>
    </Box>
  )
}

export default Dashbord
