import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

const EidtUser = (props) => {
    const history =   useHistory();
    const[loading , setLoading]=useState(true)
  

  
   const [role, setRole] = React.useState('');
   const handleChange = (e) => {
       setRole(e.target.value);
   };
   
   const[edituser ,setEdituser]=useState({
    name:"",
    email:"",
    password:"",
    error_list:[],
});
 useEffect(() => {
       const user_id = props.match.params.id;   
     axios.get(`api/useredit/${user_id}`).then(res=>{
       if(res.data.status ===200){
                
           setEdituser(res.data.editUser);  
           setLoading(false);
    }
    else if(res.data.status === 404){

      swal('Error',res.data.message,'error');
      history.push('/admin/user');

    }
    setLoading(false);

     });

   }, [props.match.params.id]);


   const [checkmodules, setCheckedmodules] = useState([]);
   const handlechnage = (e) => {

       const value = e.target.value;
       const checked = e.target.checked;

       if (checked) {
           setCheckedmodules([...checkmodules, value])
       }
       else {
           setCheckedmodules(checkmodules.filter((e) => (e !== value)));

       }

   }
    const [assignModule, setassignModules] = useState([]);
    useEffect(() => {

        //eventId 
        const eventid = localStorage.getItem('eventid')
        axios.get(`api/modules-access?id=${eventid}`).then(res => {

            if (res.status === 200) {
               //  console.log(res.data.mod);
                setassignModules(res.data.mod);
            }
           


        });


    }, []);
   
   
   const handleInput = (e)=>{
       e.persist();
       setEdituser({...edituser,[e.target.name]:e.target.value});
    } 
    const updateUser =(e) =>{
       e.preventDefault();
       const user_id = props.match.params.id; 
       const data = 
       {
           name: edituser.name,
           email:edituser.email,
           password:edituser.password,
           role_as: edituser.role_as,//this for status
           role, //this for permission
           checkmodules,
       }
       
      
       axios.post(`api/updateuser/${user_id}` ,data).then(res=>{
         
           if(res.status === 200){

               swal("Success",res.data.message,"success");
               history.push('/admin/user/');
           }
           else{
     
               setEdituser({...edituser,error_list: res.data.validate_error})
         
             }

       });

    }
    
    const [allroles, setallroles] = useState([]);
     useEffect(() => {

       axios.get(`api/allroles`).then(res => {
   
         if (res.status === 200) {
             setallroles(res.data.roles);
             setLoading(false);
          
         }
         
       });
   
   
     }, []);
     
    
    
    if(loading){
       return <h3>User Edit Loading....</h3>
   }

 
  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                component={Link}
                to="/admin/user">
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">
                    Update User
                </Typography>
                <Box component="form" onSubmit={updateUser} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                name='name' onChange={handleInput} value={edituser.name || ""}
                                 />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                disabled
                                id="email"
                                label="Email Address"
                                autoComplete="email"
                                name='email' onChange={handleInput} value={edituser.email  || ""}
                                 />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                name='password' onChange={handleInput} value={edituser.password  || ""}
                                 />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel >Role</InputLabel>
                                <Select 
                                    name='role'  onChange={handleChange}  defaultValue ={edituser.role ? edituser.role: ""}
                                    label="Role">

                                    {  allroles === "" ? 
                                    
                                        <h4>loading Role...</h4>
                                  
                                    : allroles.map((item => {
                                            return (
                                                <MenuItem value={item.id } key={item.id} >{item.name ? item.name : ""}</MenuItem>
                                            )

                                        }))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup row>  
                                {
                                    assignModule.map((item) => {
                                        return (
                                             
                                            <FormControlLabel required control={<Checkbox />}
                                                label={item.module}
                                                onChange={handlechnage}
                                                value={item.module_id || ""}
                                                name="checkmodules" key={item.id} />


                                        )
                                    })
                                }
                            </FormGroup>
                        </Grid>

                    </Grid>
                   
                    <Grid container justifyContent="flex-end">
                    <Button
                        type="submit"
                        // fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }} >
                        Add User
                    </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    </Box>
</div>
  );
}

export default EidtUser;
