import React, { useState, useEffect } from "react";
import QrReader from "modern-react-qr-reader";
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import swal from "sweetalert";
const QrScanReader = () => {
    // const g_id = 7;
    const [result, setResult] = useState("");
    const handleScan = (data) => {
        if (data) {
            setResult(data);
        }
    };
    

    const handleError = (error) => {
        console.error(error);
    };
    useEffect(() => {
        if (result) {
            // Send the QR code data to the API
            sendDataToAPI(result);
        }
    }, [result]);

    const resetResult = () => {
        setResult('');
    };
    const sendDataToAPI = (data) => {
        // Replace with your API endpoint
        //const apiUrl = 'https://example.com/api';
        // Replace with the desired payload structure
        //const payload = { qrScanReaderData: data };
        // axios.post(apiUrl, payload)
        //   .then((response) => {
        //     console.log('Data sent to the API:', response.data);
        //   })
        //   .catch((error) => {
        //     console.error('Error sending data to the API:', error);
        //   });



        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            const sendPayload = { qrScanReaderData: data };
            axios.post(`/api/store-exhibition-qrcode-scan-data`, sendPayload,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    console.log('Data sent to the API:', res.data);
                }

            });
        });
    };


    return (

        <>
            <Grid
                container
                spacing={24}
                justify="center"
                style={{ minHeight: '100vh', maxWidth: '100%' }}>
                <Grid item xs={3} align="center">
                </Grid>
                <Grid item xs={6} align="center">
                    <Typography variant="h4" sx={{ m: 2, }}>QR Code Reader</Typography>
                    <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '350px' }} />
                    {result && (
                        <div>
                            <Typography variant="h6" sx={{ m: 2, }}>Result:</Typography>
                            <Typography>ID: {result}</Typography>
                            <Button variant="contained" color="primary" onClick={resetResult}>
                                Clear Result
                            </Button>
                        </div>
                    )}

                </Grid>
                <Grid item xs={3} align="center">
                </Grid>
            </Grid>
        </>
    );
}

export default QrScanReader;
