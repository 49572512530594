import { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from "axios";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom'
import swal from "sweetalert";
import FormControlLabel from '@mui/material/FormControlLabel';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Permissions = (props) => {
  const [value, setValue] = useState(0); // This state variable will keep track of the currently selected tab.
  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel({ children, value, index }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }


  const history = useHistory();
  const [addPermissionToRole, setAddPermissionToRole] = useState([]);
  const roleId = props.match.params.id;
  const [widgets, setWidgets] = useState('');
  useEffect(() => {
    axios.get(`api/getpermission/?roleId=` + roleId).then(res => {
      if (res.status === 200) {
        const datad = res.data.data;
        setWidgets(datad);
      }
    })

  }, []);

  const hasChecked = (id) => {
    const pId = 'p_' + id;
    return (widgets.hasOwnProperty('permissions') && widgets.permissions.hasOwnProperty(pId)) ? widgets.permissions[pId] : false;
  }

  const handlechnage = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    widgets.permissions['p_' + value] = checked;
    if (checked) {
      setAddPermissionToRole([...addPermissionToRole, value]);
    }
    else {
      setAddPermissionToRole(addPermissionToRole.filter((e) => (e !== value)));
    }

  }
  const submitRole = (e) => {
    e.preventDefault();
    console.clear()
    //console.log(widgets.permissions);
    const data = [addPermissionToRole, widgets];
    const permissions = widgets.permissions;
    const selectedPermissions = [];
    for (var permission in permissions) {
      if (permissions.hasOwnProperty(permission)) {
        if (permissions[permission] === true) {
          selectedPermissions.push(permission.replace('p_', ''));
        }
      }
    }
    const obj = {
      "permissions": selectedPermissions,
      "roleId": roleId
    }
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`api/addpermissiontoroleapi/1`, obj).then(res => {
        if (res.data.status === 200) {
          swal("Success", res.data.message, "success");
          //history.push('/admin/role')
        }
        else {
          swal("Field Are Requried !!", "", "error");
          //setError(res.data.error);
        }

      });
    });

  }

  return (
    <div style={{ margin: '1%', width: '80vw', }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            component={Link}
            to="/admin/role">
            BAck
          </Button>
        </h4>

        <Card sx={{ width: '80vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Add Role
            </Typography>
            <Box component="form" onSubmit={submitRole} noValidate sx={{ mt: 3 }}>

              <Box sx={{ color: '#000000', }} style={{ marginTop: '7%' }}>

                <Card sx={{ width: '80vw', float: 'center' }} >
                  <CardContent >
                    <TableContainer >
                      <div>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          centered
                        >
                          <Tab label="Abstract permissions" />
                          <Tab label="Exhibition permissions" />
                          {/* <Tab label="Tab 3" />
                          <Tab label="Tab 4" /> */}
                        </Tabs>

                        {/* Content for each tab */}
                        {value === 0 && <TabPanel>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow sx={{ color: '#FFFFFF' }}>
                                <TableCell >#</TableCell>
                                <TableCell style={{ width: "20%", height: 100 }}> Module Name</TableCell>

                                <TableCell style={{ width: "60%", height: 100 }}>Permissions</TableCell>

                              </TableRow>

                            </TableHead>
                            <TableHead>
                            </TableHead>
                            <TableBody>

                              <TableRow
                                key={1}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell component="th" scope="row">
                                  1
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  User Setup
                                </TableCell>
                                <TableCell >
                                  {/* <TableCell >
                                                {(() => {if(widgets.p_1 == true) } 
                                                
                                                
                                                )()} */}
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="1" control={<Checkbox checked={hasChecked(1)} />} />


                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="2" control={<Checkbox checked={hasChecked(2)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="3" control={<Checkbox checked={hasChecked(3)} />} />
                                  <FormControlLabel required control={<Checkbox
                                    sx={{
                                      '&.Mui-checked': {
                                        color: pink[600],
                                      },
                                    }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="4" control={<Checkbox checked={hasChecked(4)} />} />
                                </TableCell>

                              </TableRow>
                              <TableRow
                                key={2}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell component="th" scope="row">
                                  2
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Role Setup
                                </TableCell>
                                <TableCell >

                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="5" control={<Checkbox checked={hasChecked(5)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="6" control={<Checkbox checked={hasChecked(6)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="7" control={<Checkbox checked={hasChecked(7)} />} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="8" control={<Checkbox checked={hasChecked(8)} />} />
                                </TableCell>

                              </TableRow>
                              <TableRow
                                key={3}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell component="th" scope="row">
                                  3
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Custom Field
                                </TableCell>
                                <TableCell >

                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="91" control={<Checkbox checked={hasChecked(91)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="92" control={<Checkbox checked={hasChecked(92)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="93" control={<Checkbox checked={hasChecked(93)} />} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="94" control={<Checkbox checked={hasChecked(94)} />} />
                                </TableCell>

                              </TableRow>
                              <TableRow
                                key={4}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell component="th" scope="row">
                                  4
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Category
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="13" control={<Checkbox checked={hasChecked(13)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="14" control={<Checkbox checked={hasChecked(14)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="15" control={<Checkbox checked={hasChecked(15)} />} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="16" control={<Checkbox checked={hasChecked(16)} />} />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={5}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  5
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Approve Article
                                </TableCell>
                                <TableCell >


                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="17"
                                    control={<Checkbox checked={hasChecked(17)} />}

                                  />

                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="18"
                                    control={<Checkbox checked={hasChecked(18)} />} />

                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="19" checked={hasChecked(19)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="20" checked={hasChecked(20)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={6}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  6
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Pending Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="21" checked={hasChecked(21)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="22" checked={hasChecked(22)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="23" checked={hasChecked(23)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="24" checked={hasChecked(24)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={7}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  7
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Reject Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="25" checked={hasChecked(25)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="26" checked={hasChecked(26)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="27" checked={hasChecked(27)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="28" checked={hasChecked(28)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={8}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  8
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Requirements Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="29" checked={hasChecked(29)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="30" checked={hasChecked(30)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="31" checked={hasChecked(31)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="32" checked={hasChecked(32)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={9}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  9
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Comments Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="33" checked={hasChecked(33)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="34" checked={hasChecked(34)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="35" checked={hasChecked(35)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="36" checked={hasChecked(36)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={10}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  10
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Reviewer Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="37" checked={hasChecked(37)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="38" checked={hasChecked(38)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="39" checked={hasChecked(39)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="40" checked={hasChecked(40)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={11}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  11
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Author Article
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="41" checked={hasChecked(41)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="42" checked={hasChecked(42)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="43" checked={hasChecked(43)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="44" checked={hasChecked(44)} />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={12}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  12
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Instruction
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="45" checked={hasChecked(45)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="46" checked={hasChecked(46)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="47" checked={hasChecked(47)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="48" checked={hasChecked(48)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={13}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  13
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Setting
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="49" checked={hasChecked(49)} />



                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={14}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  14
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Review Status
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="50" checked={hasChecked(50)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={15}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  15
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Event Smtp Setting
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="Control"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="51" checked={hasChecked(51)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={16}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  16
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Abstract Event Add Questions
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="Add / View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="52" checked={hasChecked(52)} />
                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TabPanel>}
                        {value === 1 && <TabPanel>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow sx={{ color: '#FFFFFF' }}>
                                <TableCell >#</TableCell>
                                <TableCell style={{ width: "20%", height: 100 }}> Module Name</TableCell>

                                <TableCell style={{ width: "60%", height: 100 }}>Permissions</TableCell>

                              </TableRow>

                            </TableHead>
                            <TableHead>
                            </TableHead>
                            <TableBody>

                              <TableRow
                                key={1}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  1
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Booth Categories
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="53" control={<Checkbox checked={hasChecked(53)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="54" control={<Checkbox checked={hasChecked(54)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="55" control={<Checkbox checked={hasChecked(55)} />} />
                                  <FormControlLabel required control={<Checkbox
                                    sx={{
                                      '&.Mui-checked': {
                                        color: pink[600],
                                      },
                                    }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="56" control={<Checkbox checked={hasChecked(56)} />} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={2}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  2
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Booths
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="57" control={<Checkbox checked={hasChecked(57)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="58" control={<Checkbox checked={hasChecked(58)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="59" control={<Checkbox checked={hasChecked(59)} />} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="60" control={<Checkbox checked={hasChecked(60)} />} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={3}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                <TableCell component="th" scope="row">
                                  3
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Booking
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="61" control={<Checkbox checked={hasChecked(61)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="62" control={<Checkbox checked={hasChecked(62)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="63" control={<Checkbox checked={hasChecked(63)} />} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="64" control={<Checkbox checked={hasChecked(64)} />} />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={4}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  4
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Exhibitior Categories
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="65"
                                    control={<Checkbox checked={hasChecked(65)} />} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="66"
                                    control={<Checkbox checked={hasChecked(66)} />} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="67" checked={hasChecked(67)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="68" checked={hasChecked(68)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={5}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  5
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Exhibitor
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="69" checked={hasChecked(69)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="70" checked={hasChecked(70)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="71" checked={hasChecked(71)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="72" checked={hasChecked(72)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={6}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  6
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Product And Services
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="73" checked={hasChecked(73)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="74" checked={hasChecked(74)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="75" checked={hasChecked(75)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="76" checked={hasChecked(76)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={7}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  7
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Nature Of Business
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="77" checked={hasChecked(77)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="78" checked={hasChecked(78)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="79" checked={hasChecked(79)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="80" checked={hasChecked(80)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={8}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  8
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  Guest Badge Design
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="81" checked={hasChecked(81)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="Add"
                                    name="permissions" onChange={handlechnage} value="82" checked={hasChecked(82)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="83" checked={hasChecked(83)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="84" checked={hasChecked(84)} />
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={9}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  9
                                </TableCell>
                                <TableCell component="th" scope="row">
                                 Visitor's 
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="85" checked={hasChecked(85)} />
                                 
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={10}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  10
                                </TableCell>
                                <TableCell component="th" scope="row">
                                Register Guest
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="86" checked={hasChecked(86)} />
                                  <FormControlLabel required control={<Checkbox />}
                                    label="print"
                                    name="permissions" onChange={handlechnage} value="87" checked={hasChecked(87)} />
                                  <FormControlLabel required control={<Checkbox color="secondary" />}
                                    label="Edit"
                                    name="permissions" onChange={handlechnage} value="88" checked={hasChecked(88)} />
                                  <FormControlLabel required control={<Checkbox sx={{

                                    '&.Mui-checked': {
                                      color: pink[600],
                                    },
                                  }} />}
                                    label="Delete"
                                    name="permissions" onChange={handlechnage} value="89" checked={hasChecked(89)} />
                                </TableCell>
                              </TableRow>
                              <TableRow
                                key={11}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  11
                                </TableCell>
                                <TableCell component="th" scope="row">
                                Self Scan Qr Code
                                </TableCell>
                                <TableCell >
                                  <FormControlLabel required control={<Checkbox color="success" />}
                                    label="View"
                                    color="success"
                                    name="permissions" onChange={handlechnage} value="90" checked={hasChecked(90)} />
                                 
                                </TableCell>
                              </TableRow>
                              {/* nxet value start from 94 becz 91 to 94 used for custom field on on the top */}




                              {/* <TableRow
                            key={9}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              9
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Reviewer Article
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="37" checked={hasChecked(37)} />
                              <FormControlLabel required control={<Checkbox />}
                                label="Add"
                                name="permissions" onChange={handlechnage} value="38" checked={hasChecked(38)} />
                              <FormControlLabel required control={<Checkbox color="secondary" />}
                                label="Edit"
                                name="permissions" onChange={handlechnage} value="39" checked={hasChecked(39)} />
                              <FormControlLabel required control={<Checkbox sx={{

                                '&.Mui-checked': {
                                  color: pink[600],
                                },
                              }} />}
                                label="Delete"
                                name="permissions" onChange={handlechnage} value="40" checked={hasChecked(40)} />
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={10}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              10
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Author Article
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="41" checked={hasChecked(41)} />
                              <FormControlLabel required control={<Checkbox />}
                                label="Add"
                                name="permissions" onChange={handlechnage} value="42" checked={hasChecked(42)} />
                              <FormControlLabel required control={<Checkbox color="secondary" />}
                                label="Edit"
                                name="permissions" onChange={handlechnage} value="43" checked={hasChecked(43)} />
                              <FormControlLabel required control={<Checkbox sx={{

                                '&.Mui-checked': {
                                  color: pink[600],
                                },
                              }} />}
                                label="Delete"
                                name="permissions" onChange={handlechnage} value="44" checked={hasChecked(44)} />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={11}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              11
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Instruction
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="45" checked={hasChecked(45)} />
                              <FormControlLabel required control={<Checkbox />}
                                label="Add"
                                name="permissions" onChange={handlechnage} value="46" checked={hasChecked(46)} />
                              <FormControlLabel required control={<Checkbox color="secondary" />}
                                label="Edit"
                                name="permissions" onChange={handlechnage} value="47" checked={hasChecked(47)} />
                              <FormControlLabel required control={<Checkbox sx={{

                                '&.Mui-checked': {
                                  color: pink[600],
                                },
                              }} />}
                                label="Delete"
                                name="permissions" onChange={handlechnage} value="48" checked={hasChecked(48)} />
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={12}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              12
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Setting
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="49" checked={hasChecked(49)} />



                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={13}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              13
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Review Status
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="50" checked={hasChecked(50)} />
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={14}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              14
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Event Smtp Setting
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="Control"
                                color="success"
                                name="permissions" onChange={handlechnage} value="51" checked={hasChecked(51)} />
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={15}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                              15
                            </TableCell>
                            <TableCell component="th" scope="row">
                              Abstract Event Add Questions
                            </TableCell>
                            <TableCell >
                              <FormControlLabel required control={<Checkbox color="success" />}
                                label="Add / View"
                                color="success"
                                name="permissions" onChange={handlechnage} value="52" checked={hasChecked(52)} />
                            </TableCell>
                          </TableRow> */}

                            </TableBody>
                          </Table>
                        </TabPanel>}

                        {value === 2 && <TabPanel>Content for Tab 3</TabPanel>}
                        {value === 3 && <TabPanel>Content for Tab 4</TabPanel>}
                      </div>

                    </TableContainer>
                  </CardContent>
                </Card>
              </Box>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Add Role
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default Permissions;
