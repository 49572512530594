import React from 'react';

import {  Box } from '@mui/material';
import NavbarForFront from './NavbarForFront';
import EmsNavbar from './EmsNavbar';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

function Home() {
    return(
        // <><NavbarForFront />
        <><EmsNavbar />
      <Box sx={{ color: '#000000', }} style={{ marginLeft: '39%', marginTop: '2%'  }}>
             
        
         
             
      <h1  style={{  marginTop: '17%'  }}>Home Page</h1>
           <h4>Event Management System</h4>
                
            </Box> 
            </>   
       
         )

}

export default Home
