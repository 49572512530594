import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LockIcon from '@mui/icons-material/Lock';
const AllRoles = () => {
    const [loading, setloading] = useState(false);
    const [allroles, setallroles] = useState([]);
    const [permissions, setrPermissions] = useState([]);
    const deleteRole = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        //thisClicked.innerText = "Deleting...";
        swal('Warning', "you don't Have Access to Delete any Role", 'warning');
        // axios.get(`api/role-delete/${id}`).then(res => {

        //     if (res.data.status === 200) {

        //         swal('Success', res.data.message, 'success');
        //         thisClicked.closest("tr").remove();

        //     }
        //     else if (res.data.status === 404) {

        //         swal('Success', res.data.message, 'success');
        //         thisClicked.innerText = "Delete";
        //     }

        // });


    }

    useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)
            } else {

            }
        })

    }, []);

    useEffect(() => {

        axios.get(`api/allroles`).then(res => {

            if (res.status === 200) {

                setallroles(res.data.roles);

            }
            else {
                setloading(true);
            }

        });
    }, []);
    var allRolesDataList = "";
    if (loading) {
        return <h1>Loading Roles...</h1>
    }
    else {

        allRolesDataList =
            allroles.map((item, i) => {
                return (

                    <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                        <TableCell component="th" scope="row">
                            {i + 1}
                        </TableCell>

                        <TableCell component="th" scope="row">
                            {item.name}
                        </TableCell>
                        <TableCell >
                            {(() => {
                                if (permissions['p_role-edit'] === true) {
                                    return (
                                        <>
                                            <Button sx={{

                                                marginRight: '11%',
                                                marginTop: '2%',

                                                ":hover": {
                                                    color: "white"
                                                }
                                            }}
                                                color='primary'
                                                size='small'
                                                variant='contained'
                                                component={Link}
                                                to={`/admin/role/permissions/${item.id}`}>
                                                <LockIcon />
                                            </Button>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <div></div>
                                    )
                                }

                            })
                                ()}


                            {(() => {
                                if (permissions['p_role-delete'] === true) {
                                    return (
                                        <>
                                            {/* <Button sx={{

                                                marginRight: '11%',
                                                marginTop: '2%',

                                                ":hover": {
                                                    color: "white"
                                                }
                                            }}
                                                color='primary'
                                                size='small'
                                                variant='contained'
                                                onClick={(e) => deleteRole(e, item.id)}>
                                                <DeleteIcon />
                                            </Button> */}
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <div></div>
                                    )
                                }

                            })
                                ()}

                        </TableCell>
                    </TableRow>
                )

            });
    }

    return (
        <div style={{ margin: '1%', }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '7%' }}>
                <h4 sx={{ m: 2, color: '#000000' }}> Role Management
                {
            permissions['p_role-create'] === true ?
                    <Button sx={{
                        float: 'right',

                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/create-role">
                        Add Role
                    </Button>:""}
                </h4>
                <Card sx={{ width: '80vw', float: 'center' }} >
                    <CardContent >
                        <TableContainer >
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow sx={{ color: '#FFFFFF' }}>
                                        <TableCell >#</TableCell>
                                        <TableCell > Name</TableCell>
                                        <TableCell  >Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allRolesDataList}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        </div>


    )
}

export default AllRoles
