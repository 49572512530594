
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import React, { useEffect, useState } from "react";


import axios from "axios";
import swal from "sweetalert";


const User = () => {
    const [loading, setloading] = useState(false);
    const [userlist, setUserlist] = useState([]);
    //const [userlistrole, setUserrole] = useState([]);
    //eventId 
 const eventid = localStorage.getItem('eventid')
 
 useEffect(() => {
   axios.get(`api/superadmin-alluser`).then(res => {
   
            if (res.status === 200) {

                setUserlist(res.data.users);
                //console.log(res.data.roles);
                //setUserrole(res.data.roles);
            }
            else {
                setloading(false);
            }


        });

    }, []);
    const deleteUser = (e, id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting...";

        axios.get(`api/user-delete/${id}`).then(res => {

            if (res.data.status === 200) {

                swal('Success', res.data.message, 'success');
                thisClicked.closest("tr").remove();

            }
            else if (res.data.status === 404) {

                swal('Success', res.data.message, 'success');
                thisClicked.innerText = "Delete";
            }

        });

    }

  return (
    <div style={{  margin: '1%', }}>
            <Box sx={{ color: '#000000', }} style={{  marginTop: '7%' }}>
                <h4 sx={{ m: 2, color: '#000000' }}> Organiser Management
                    <Button sx={{
                        float: 'right',
                      
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/master/add-user">
                        Add User
                    </Button>
                </h4>
                <Card sx={{ width: '80vw', float: 'center' }} >
                    <CardContent >
                        <TableContainer >
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow sx={{ color: '#FFFFFF' }}>
                                        <TableCell >#</TableCell>
                                        <TableCell > Name</TableCell>
                                        <TableCell >Email</TableCell>
                                        <TableCell  >User Role</TableCell>
                                        <TableCell  >Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userlist.map((user, i) => (
                                        <TableRow

                                            key={user.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                            <TableCell component="th" scope="row">
                                                {i + 1}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {user.name}
                                            </TableCell>
                                            <TableCell >{user.email}</TableCell>
                                            <TableCell >Organiser</TableCell>


                                            <TableCell >

                                                <Button sx={{

                                                    marginRight: '11%',
                                                    marginTop: '2%',

                                                    ":hover": {
                                                        color: "white"
                                                    }
                                                }}
                                                    color='primary'
                                                    size='small'
                                                    variant='contained'
                                                    component={Link}
                                                    to={`/admin/edituser/${user.id}`}>
                                                    <VisibilityIcon />
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        </div>
  );
}

export default User;
