import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Button, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NavbarForFront from '../frontend/NavbarForFront';
import "../App.css"
import EmsNavbar from "../frontend/EmsNavbar";

const ForgotPassword = () => {


  const history = useHistory();
  const [forgotInput, setForgotPassword] = useState({
    emailAddress: "",
    error_list: [],

  });

  const handleInput = (e) => {
    e.persist();
    setForgotPassword({ ...forgotInput, [e.target.name]: e.target.value });


  }
  const eventid = localStorage.getItem('eventid');
  
  const [errorlist, setError] = useState([]);
  const forgotSubmit = (e) => {

    e.preventDefault();


    const data = {
      eventid,
      emailAddress: forgotInput.emailAddress,
    }
    setForgotPassword({ emailAddress: '' });
    
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/forgot/password`, data).then(res => {
        if (res.data.status === true) {
          swal('Success', res.data.message, 'success');
          //history.push('/login');
        }
         if(res.data.status === 404)
         {
        swal('Warning',res.data.message, 'warning');
         }

        // else {
        //   //swal("Email Field is Required", "", "error");
        //   setError(res.data.validate_error);

        // }
      });


    });

  }
  return (
    <div>
      <><EmsNavbar />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
           {errorlist.eventid}
            <Box component="form" onSubmit={forgotSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="emailAddress"
                onChange={handleInput} value={forgotInput.emailAddress}
                autoFocus
                helperText={errorlist.emailAddress}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,backgroundColor: '#145348'}}>
                Submit
              </Button>

            </Box>
          </Box>

        </Container>
      </>
    </div>
  );
}

export default ForgotPassword;
