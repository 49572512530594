import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}




const Comments = () => {
  const [open, setOpen] = React.useState(false);


  const [singleData, setSingleData] = useState({
    id: '',
    userId: '',
    title: '',
    body: '',
  });
  const handleClickOpen = (id) => {

    axios.get(`https://jsonplaceholder.typicode.com/posts/${id}`).then(res => {

      if (res.status === 200) {
        const datad = res.data;
        setSingleData(datad)

      } else {
      }
    })
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
 


  const [showData, setShowData] = useState([]);


  useEffect(() => {
    axios.get(`https://jsonplaceholder.typicode.com/posts`).then(res => {


      if (res.status === 200) {
        const data = res.data;
        setShowData(data)

      } else {

      }


    })
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - showData.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>

        <Card sx={{ width: '82vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Comments
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="left"> Article Title</TableCell>
                      <TableCell align="left"> Status</TableCell>
                      <TableCell align="center" style={{ width: 150 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0
                      ? showData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : showData
                    ).map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 60 }}>
                          {row.id}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="left">
                          {row.title}
                        </TableCell>

                        <TableCell style={{ width: 60 }} align="left">
                          {row.userId}
                        </TableCell>
                        <TableCell style={{ width: 40 }} align="right">
                          <Button sx={{
                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                            onClick={(e) => handleClickOpen(row.id)}>
                            <VisibilityIcon />
                          </Button>
                          <Button sx={{
                            margin: 1,

                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained' >
                            <EditNoteIcon />
                          </Button>
                          <Button sx={{
                            margin: 1,

                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={showData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ID: {singleData.id}
          <br />
          UserID:{singleData.userId}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Title: {singleData.title}.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {singleData.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}

          {/* <Button sx={{
            margin: 1,

            ":hover": {
              color: "white"
            }
          }}
            onClick={handleClose} autoFocus
            color='secondary'
            size='small'
            variant='contained'>
            <CancelIcon />
          </Button> */}
          <IconButton onClick={handleClose} >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Comments;
