import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete from "@mui/material";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { Add } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { Container } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Chip } from "@mui/material";

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormHelperText from '@mui/material/FormHelperText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddReviewer = () => {
    const history = useHistory();
    const [disabled, setdisabled] = useState(false);
    const [selectedNames, setSelectedNames] = useState([]);
    const [abstractCategory, setAbstractCategory] = React.useState([]);
    const eventid = localStorage.getItem('eventid')
    React.useEffect(() => {
        axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const abstractCategoryData = res.data.articleCategory;
                setAbstractCategory(abstractCategoryData)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);
    const [abstractArticlePending, setAbstractArticlePending] = React.useState([]);
    React.useEffect(() => {
      axios.get(`api/abstractApprovleArticlePending?id=${eventid}`).then(res => {
  
        if (res.status === 200) {
          const abstractArticleData = res.data.article;
          setAbstractArticlePending(abstractArticleData)
  
        } else {
          swal('Error', "Check API Something Wrong", 'error');
  
        }
      })
  
    }, []);
    
    const [assignCategoye, setAssignCategoye] = useState([]);
    const [assignAbstract, setAssignAbstract] = useState([]);
    const handleChangeMulti = (event: SelectChangeEvent<typeof assignCategoye>) => {
        const {
            target: { value },
        } = event;

        if (value.includes("selectAll")) {
            // If "Select All" is selected, set assignCategoye to include all category IDs
            setAssignCategoye(abstractCategory.map((category) => category.id));
        } else {
            // Update assignCategoye based on individual selections
            setAssignCategoye(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        }
    };
    const handleChangeMultiAbstract = (event: SelectChangeEvent<typeof assignAbstract>) => {
        const {
            target: { value },
        } = event;

        if (value.includes("selectAll")) {
            // If "Select All" is selected, set assignCategoye to include all category IDs
            setAssignAbstract(abstractArticlePending.map((abstract) => abstract.id));
        } else {
            // Update assignCategoye based on individual selections
            setAssignAbstract(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        }
    };
    const [abstractReviewerImage, setAbstractReviewerImage] = useState("");
    const [allroles, setallroles] = useState([]);
    const [addAbstractReviewer, setAddAbstractReviewer] = React.useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        date: "",
        address: "",
        details: "",
        error_list: [],
    });


    // const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };


    const handleInput = (e) => {
        setAddAbstractReviewer({ ...addAbstractReviewer, [e.target.name]: e.target.value });

    }
    useEffect(() => {

        axios.get(`api/allroles`).then(res => {
            if (res.status === 200) {
                setallroles(res.data.roles);
            }

        });

    }, []);

    const [assignModule, setassignModules] = useState([]);
    useEffect(() => {
        const eventid = localStorage.getItem('eventid')
        axios.get(`api/modules-access?id=${eventid}`).then(res => {
            if (res.status === 200) {

                setassignModules(res.data.mod);
            }
        });

    }, []);



    const [checkmodules, setCheckedmodules] = useState([]);
    const handlechnage = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setCheckedmodules([...checkmodules, value])
        }
        else {
            setCheckedmodules(checkmodules.filter((e) => (e !== value)));
        }

    }

    const [role, setRole] = React.useState('');
    const handleChange = (e) => {
        setRole(e.target.value);
    };
    const AddAbsReviewer = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid');
        const data = {
            eventid,
            role,
            checkmodules,
            abstractReviewerImage,
            name: addAbstractReviewer.name,
            email: addAbstractReviewer.email,
            password: addAbstractReviewer.password,
            phone: addAbstractReviewer.phone,
            date: addAbstractReviewer.date,
            address: addAbstractReviewer.address,
            assignCategoye,
            assignAbstract,

        }
        const conditions = [
            data.checkmodules == "",
            data.role == "",
            data.name == "",
            data.email == "",
            data.password == "",
            data.phone == "",
            (data.assignCategoye == "" && data.assignAbstract == "")
        ];
        
        setdisabled(conditions.some(condition => condition) ? false : true);
       

        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/orgniserAddReviewer`, data, config).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/reviewer/reviewer-list');
                }
                else {
                    setAddAbstractReviewer({ ...addAbstractReviewer, error_list: res.data.validate_error })
                }
            });
        });
    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/reviewer/reviewer-list">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Reviewer
                        </Typography>
                        <Box component="form" onSubmit={AddAbsReviewer} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth

                                        label="Name"
                                        autoFocus
                                        name='name'
                                        onChange={handleInput} value={addAbstractReviewer.name}
                                        helperText={addAbstractReviewer.error_list.name} />
                                </Grid>


                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        name='email'
                                        onChange={handleInput} value={addAbstractReviewer.email}
                                        helperText={addAbstractReviewer.error_list.email} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        required
                                        fullWidth
                                        label="Password"
                                        id="password"
                                        autoComplete="new-password"
                                        name='password'
                                        onChange={handleInput}
                                        value={addAbstractReviewer.password}
                                        helperText={addAbstractReviewer.error_list.password}
                                        // onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePassword} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Phone"
                                        type="text"
                                        name='phone'
                                        onChange={handleInput} value={addAbstractReviewer.phone} />
                                </Grid>


                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Address"
                                        autoFocus
                                        name='address' onChange={handleInput} value={addAbstractReviewer.address} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        type="Date"
                                        required
                                        fullWidth
                                        autoFocus={true}
                                        label="Date Of Bith"
                                        name='date' onChange={handleInput} value={addAbstractReviewer.date} />
                                </Grid>

                                <Grid item xs={6} >
                                    <label htmlFor="icon-button-file">
                                        <TextField accept="image/*" id="icon-button-file" type="file" onChange={(e) => setAbstractReviewerImage(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel >Role</InputLabel>
                                        <Select defaultValue=""
                                            name='role' onChange={handleChange} value={role}
                                            label="Role">

                                            {
                                                allroles.map((item => {
                                                    return (
                                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                                    )

                                                }))
                                            }

                                        </Select>
                                        <FormHelperText>{addAbstractReviewer.error_list.role} </FormHelperText>
                                    </FormControl>
                                </Grid>

                                {/* <Grid item xs={12}>
                                    <FormControl sx={{ m: 1, width: 810 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Article Category</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={assignCategoye}
                                            onChange={handleChangeMulti}
                                            input={<OutlinedInput label="Article Category" />}
                                            renderValue={(selected) => selected.map(id => abstractCategory.find(category => category.id === id)?.title).join(" , ")}
                                        >
                                            <MenuItem value="selectAll">
                                                <Checkbox checked={assignCategoye.length === abstractCategory.length} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {abstractCategory.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Checkbox checked={assignCategoye.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{addAbstractReviewer.error_list.assignCategoye ? addAbstractReviewer.error_list.assignCategoye[0] : ''}</FormHelperText>
                                    </FormControl>
                                </Grid> */}

                                <Grid item xs={12}>
                                    <FormControl sx={{ m: 1, width: 810 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Abstracts</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={assignAbstract}
                                            onChange={handleChangeMultiAbstract}
                                            input={<OutlinedInput label="Abstracts " />}
                                            renderValue={(selected) => selected.map(id => abstractArticlePending.find(category => category.id === id)?.title).join(" , ")}
                                        >
                                            <MenuItem value="selectAll">
                                                <Checkbox checked={assignAbstract.length === abstractArticlePending.length} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {abstractArticlePending.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Checkbox checked={assignAbstract.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{addAbstractReviewer.error_list.assignAbstract ? addAbstractReviewer.error_list.assignAbstract[0] : ''}</FormHelperText>

                                    </FormControl>
                                </Grid>

                                
                                {/* <Grid item xs={12}>
                                    <FormControl sx={{ m: 1, width: 810 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Article Categoy</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={assignCategoye}
                                            onChange={handleChangeMulti}
                                            input={<OutlinedInput label="Article Categoy" />}
                                            // renderValue={(selected) => selected.join(" , ")}
                                            renderValue={(selected) => selected.map(id => abstractCategory.find(category => category.id === id)?.title).join(" , ")}
                                            MenuProps={MenuProps}>
                                            {abstractCategory.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Checkbox checked={assignCategoye.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{addAbstractReviewer.error_list.assignCategoye} </FormHelperText>
                                    </FormControl>
                                </Grid> */}

                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Details"
                                        autoFocus
                                        name='details'
                                        multiline
                                        rows={6} onChange={handleInput} value={addAbstractReviewer.details} />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormGroup row>
                                        {
                                            assignModule.map((item) => {
                                                return (

                                                    <FormControlLabel required control={<Checkbox />}
                                                        label={item.module}
                                                        onChange={handlechnage}
                                                        value={item.module_id}
                                                        name="checkmodules" key={item.id} />

                                                )
                                            })
                                        }
                                    </FormGroup>
                                    <FormHelperText>{addAbstractReviewer.error_list.checkmodules} </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    disabled={disabled}
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348', }} >
                                    Add Reviewer
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>

    );
}

export default AddReviewer;
