import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const EmsNavbar = () => {
    const history = useHistory();
    
    return (
        <div>
            <Box  xs={6} lg={12}>
                <AppBar position="static" >
                    <Toolbar sx={{ backgroundColor: '#145348' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                           MicroArt EMS 
                        </Typography>
                       
                        <Button onClick={() => history.push('/')} color="inherit">Home</Button>
                        
                        {/* <Button onClick={() => history.push('/exhibition/verification')} color="inherit">Exhibition Login</Button> */}
                        <Button onClick={() => history.push('/login')} color="inherit">Login</Button>
                        {/* <Button onClick={() => history.push('/register')} color="inherit">Register</Button> */}


                    </Toolbar>
                </AppBar>


            </Box>

        </div>
    );
}

export default EmsNavbar;
