import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { Add } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const EditReviewer = (props) => {
    const history = useHistory();
    const [abstractReviewerImage, setAbstractReviewerImage] = useState("");
    const [editReviewer, setEditReviewer] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        date: "",
        address: "",
        error_list: [],

    })
    // const [categories, setCategories] = useState([]);
    // const [selectedCategories, setSelectedCategories] = useState([]);
    // const [selectedAbstract, setSelectedAbstract] = useState([]);
    // useEffect(() => {
    //     const id = props.match.params.id;
    //     axios.get(`api/revieweredit/${id}`).then((res) => {
    //         if (res.status === 200) {
    //             const reviewerListEdit = res.data.reviewer;
    //             setEditReviewer(reviewerListEdit);
    //             setCategories(res.data.attached_categories); // Assuming you get categories in the response
    //             setSelectedCategories(res.data.attached_categories.map(category => category.id)); // Assuming you get category IDs in the response
    //         } else {
    //             swal('Error', 'Check API Something Wrong', 'error');
    //         }
    //     });
    // }, [props.match.params.id]);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedAbstract, setSelectedAbstract] = useState([]);

    useEffect(() => {
        const id = props.match.params.id;
        axios.get(`api/revieweredit/${id}`).then((res) => {
            if (res.status === 200) {
                const reviewerListEdit = res.data.reviewer;
                setEditReviewer(reviewerListEdit);

                // Set categories and selected categories
                setCategories(res.data.attached_categories); // Assuming you get categories in the response
                setSelectedCategories(res.data.attached_categories.map(category => category.id)); // Assuming you get category IDs in the response

                // Set selected abstracts
                setSelectedAbstract(res.data.assigned_abstracts.map(abstract => abstract.id)); // Assuming you want the IDs of the assigned abstracts
            } else {
                swal('Error', 'Check API Something Wrong', 'error');
            }
        });
    }, [props.match.params.id]);

    // Rest of your component code


    const [abstractCategory, setAbstractCategory] = React.useState([]);
    const eventid = localStorage.getItem('eventid')
    React.useEffect(() => {
        axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const abstractCategoryData = res.data.articleCategory;
                setAbstractCategory(abstractCategoryData)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    const [abstractArticlePending, setAbstractArticlePending] = React.useState([]);

    React.useEffect(() => {
        axios.get(`api/abstractApprovleArticlePending?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const abstractArticleData = res.data.article;
                setAbstractArticlePending(abstractArticleData)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    
    const handleCategoryChange = (event: SelectChangeEvent<typeof selectedCategories>) => {
        const {
            target: { value },
        } = event;

        if (value.includes("selectAll")) {
            // If "Select All" is selected, set assignCategoye to include all category IDs
            setSelectedCategories(abstractCategory.map((category) => category.id));
        } else {
            // Update assignCategoye based on individual selections
            setSelectedCategories(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        }
    };


    const handleAbstractChange = (event: SelectChangeEvent<typeof selectedAbstract>) => {
        const {
            target: { value },
        } = event;

        if (value.includes("selectAll")) {
            // If "Select All" is selected, set assignCategoye to include all category IDs
            setSelectedAbstract(abstractArticlePending.map((abstract) => abstract.id));
        } else {
            // Update assignCategoye based on individual selections
            setSelectedAbstract(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        }
    };

    const handleInput = (e) => {
        e.persist();
        setEditReviewer({ ...editReviewer, [e.target.name]: e.target.value })
    }
    const abstractReviewer = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            eventid,
            abstractReviewerImage,
            name: editReviewer.name,
            email: editReviewer.email,
            password: editReviewer.password,
            phone: editReviewer.phone,
            address: editReviewer.address,
            selectedCategories,
            selectedAbstract,
        }
        const id = props.match.params.id
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/reviewerupdate/${id}`, data, config).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/reviewer/reviewer-list');
                }
                else {
                    setEditReviewer({ ...editReviewer, error_list: res.data.validate_error })
                }

            });
        });
    }



    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/reviewer/reviewer-list">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Edit Reviewer
                        </Typography>
                        <Box component="form" onSubmit={abstractReviewer} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Name"
                                        autoFocus
                                        name='name'
                                        onChange={handleInput} value={editReviewer.name || ""} />
                                </Grid>


                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        disabled
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        name='email'
                                        onChange={handleInput} value={editReviewer.email || ""} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Phone"
                                        type="text"
                                        name='phone'
                                        onChange={handleInput} value={editReviewer.phone || ""}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Address"
                                        autoFocus
                                        name='address' onChange={handleInput} value={editReviewer.address || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <label htmlFor="icon-button-file">
                                        <TextField accept="image/*" id="icon-button-file" type="file" onChange={(e) => setAbstractReviewerImage(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                        </IconButton>
                                    </label>
                                </Grid>


                                {/* <Grid item xs={6} >
                                    <FormControl variant="outlined" sx={{ width: 395, }}>
                                        <InputLabel>Select Categories</InputLabel>
                                        
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedCategories}
                                            onChange={handleCategoryChange}
                                            input={<OutlinedInput label="Article Category" />}
                                            renderValue={(selected) => selected.map(id => abstractCategory.find(category => category.id === id)?.title).join(" , ")}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="selectAll">
                                                <Checkbox checked={selectedCategories.length === abstractCategory.length} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {abstractCategory.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>
                                                    <Checkbox checked={selectedCategories.indexOf(category.id) > -1} />
                                                    <ListItemText primary={category.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}

                                <Grid item xs={12} >
                                    <FormControl variant="outlined" sx={{ width: 850, }}>
                                        <InputLabel>Select Abstract</InputLabel>

                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={selectedAbstract}
                                            onChange={handleAbstractChange}
                                            input={<OutlinedInput label="Article Category" />}
                                            renderValue={(selected) => selected.map(id => abstractArticlePending.find(category => category.id === id)?.title).join(" , ")}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="selectAll">
                                                <Checkbox checked={selectedAbstract.length === abstractArticlePending.length} />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {abstractArticlePending.map((abstact) => (
                                                <MenuItem key={abstact.id} value={abstact.id}>
                                                    <Checkbox checked={selectedAbstract.indexOf(abstact.id) > -1} />
                                                    <ListItemText primary={abstact.title} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>


                                {
                                    editReviewer.image === "no_image.jpg" ? "" :
                                        <Grid item xs={6} >

                                            <img src={`${editReviewer.base_url}/abstract/${editReviewer.image}`} width="150" height="150" />
                                        </Grid>
                                }

                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348', }} >
                                    Update Reviewer
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default EditReviewer;
