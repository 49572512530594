import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';

const AddPendingList = () => {
    const [category, setCategory] = React.useState('');
    const handleChange = (e) => {
      setCategory(e.target.value);
    };
    return (
      <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
        <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
          <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
              float: 'right',
              marginTop: '2%',
              ":hover": {
                color: "white"
              }
            }}
              color='success'
              size='small'
              variant='contained'
              component={Link}
              to="/admin/abstract/pending-article">
              BAck
            </Button>
          </h4>
          <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Add Pending Abstract
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={4}>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Article Title"
                    autoFocus
                    name='name' />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel >Select Category</InputLabel>
                    <Select defaultValue=""
                      name='Category' onChange={handleChange} value={category}
                      label="Select Category">
                      <MenuItem value="Category1" >Category 1</MenuItem>
                      <MenuItem value="Category2" >Category 2</MenuItem>
                      <MenuItem value="Category3" >Category 3</MenuItem>
                      <MenuItem value="Category4" >Category 4</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  <label htmlFor="icon-button-file">
                    <TextField accept="image/*" id="icon-button-file" type="file" />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Grid>
                <Grid item xs={6} >
                  <label htmlFor="contained-button-file">
                    <TextField accept="image/*" id="contained-button-file" multiple type="file" />
                    <Button variant="contained" component="span">
                      File
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Youtube Video ID"
                    autoFocus
                    name='youtubelink' />
                </Grid>

                <Grid item xs={6} >
                  <TextField
                    type="date"
                    required
                    fullWidth
                    autoFocus
                    name='date' />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Article Details"
                    autoFocus
                    name='name'
                    multiline
                    rows={6} />
                </Grid>
                </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  sx={{ mt: 5, }} >
                  Add Pending Abstract
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
        </Box>
      </div>
  
    );
}

export default AddPendingList;
