import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';


const NewVersionAbstractFooter = () => {
    const hsscLogo = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/hssc.png`;
    const ssfmLogo = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/ssfm.png`;
    const SCFMSLOGO = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/SCFMS2024-logo.png`;

    const Footerbackground = 'linear-gradient(90deg, rgba(20, 65, 102, 1) 0%, rgba(20, 66, 103, 1) 36%, rgba(7, 25, 39, 1) 100%)';
    return (

        <Box
            sx={{
                width: '100%',
                background: 'linear-gradient(90deg, rgba(20, 65, 102, 1) 0%, rgba(20, 66, 103, 1) 36%, rgba(7, 25, 39, 1) 100%)',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                color: 'white',
            }}  >
            <Container maxWidth="lg" marginTop="80px">
                <Grid container spacing={2} justifyContent="center">

                    <Grid item xs={12} lg={12} sx={{
                        backgroundColor: '#274A72',
                        marginTop: { xs: '20px', sm: '30px', md: '40px' }, // Responsive margin top
                        borderRadius: { xs: '0px', sm: '5px', md: '10px' }, // Responsive border radius
                        padding: { xs: '10px', sm: '15px', md: '20px' }, // Responsive padding
                    }} >
                        <Grid container spacing={2} alignItems="center" >
                            {/* First Box */}
                            <Grid item xs={12} sm={4} lg={4}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <LocationOnIcon fontSize="large" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" color="white">
                                            Event Place
                                        </Typography>
                                        <Typography variant="body2" color="white">
                                            Crown Plaza, Riyadh
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Second Box */}
                            <Grid item xs={12} sm={4} lg={4}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <EmailIcon fontSize="large" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" color="white">
                                            Send Email
                                        </Typography>
                                        <Typography variant="body2" color="white">
                                            info@scfms2024.org
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Third Box */}
                            <Grid item xs={12} sm={4} lg={4}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <PhoneIcon fontSize="large" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" color="white">
                                            Call Emergency
                                        </Typography>
                                        <Typography variant="body2" color="white">
                                            +966 56 782 2836
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography
                                    variant="body1"
                                    color="white"
                                    sx={{
                                        lineHeight: 1.5,
                                        textAlign: 'center',
                                        fontSize: { xs: '1rem', sm: '1.25rem', lg: '1.25rem' }
                                    }}
                                >
                                    For any technical support kindly whatsapp no:{" "}
                                    <a
                                        href="https://wa.me/966565612058"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: 'white', textDecoration: 'none' }}
                                    >
                                        +966 56 561 2058
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} lg={4}>

                                <Grid item>
                                    <img src={SCFMSLOGO} alt="Logo" style={{ width: '200px', height: '100px', borderRadius: '50%' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="p" color="white" sx={{ lineHeight: 1.5 }}>
                                        Advancements in Forensic Science:<br />
                                        UNVEILING THE FUTURE
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={2} alignItems="center">
                            {/* Social Media Icons */}
                            <Grid item xs={12} sm={4} lg={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <FacebookIcon fontSize="large" style={{ color: 'white' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="white">
                                            |
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <InstagramIcon fontSize="large" style={{ color: 'white' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="white">
                                            |
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <LinkedInIcon fontSize="large" style={{ color: 'white' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="white">
                                            |
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TwitterIcon fontSize="large" style={{ color: 'white' }} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Additional Logos */}
                            <Grid item xs={12} sm={4} lg={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={6}> {/* Takes full width on mobile, half on medium screens */}
                                        <Box
                                            component="img"
                                            src={hsscLogo}
                                            alt="Logo"
                                            sx={{
                                                width: '100%', // Full width on mobile
                                                height: 'auto', // Maintain aspect ratio
                                                //borderRadius: '50%',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}> {/* Takes full width on mobile, half on medium screens */}
                                        <Box
                                            component="img"
                                            src={ssfmLogo}
                                            alt="Logo"
                                            sx={{
                                                width: '100%', // Full width on mobile
                                                height: 'auto', // Maintain aspect ratio
                                                //borderRadius: '50%',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} lg={12} marginTop="20px">
                        <hr style={{ borderColor: 'white', width: '100%' }} />
                    </Grid>
                    {/* Second Row */}
                    <Grid item xs={12} container justifyContent="center" >
                        <Typography color="white" variant="subtitle1">
                            {`© ${new Date().getFullYear()} ,SCFMS - All Rights Reserved`}
                        </Typography>
                    </Grid>

                    {/* Third Row (example of additional row) */}


                </Grid>
            </Container>
        </Box>
    );
};


export default NewVersionAbstractFooter;

