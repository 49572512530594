import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Button, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import EmsNavbar from "../../frontend/EmsNavbar";




const Verifiction = () => {
    const history = useHistory();
    const [emailexist, setemailexist] = useState({
        email: "",
        error_list: [],
    });


    const handleInput = (e) => {
        e.persist();
        setemailexist({ ...emailexist, [e.target.name]: e.target.value });
    }


    const [exhibitionEmailFind, setExhibitionEmailFind] = React.useState([]);
    const eventid = localStorage.getItem('eventid')
     //const eventid = 56;
    React.useEffect(() => {
      axios.post(`api/useremailfind?id=${eventid}`).then(res => {

        if (res.status === 200) {
          const searchingEmain = res.data.email;
          setExhibitionEmailFind(searchingEmain)

        } else {
          swal('Error', "Check API Something Wrong", 'error');

        }
      })

    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: emailexist.email,
            eventid,
        }

        if(emailexist.email != ""){
            localStorage.setItem('exhibition_email', emailexist.email);

        }
      

        axios.get('/sanctum/csrf-cookie').then(response => {

            axios.post(`api/useremailfind`, data).then(res => {
                if (res.data.redirect == 0) {

                    swal("Success", res.data.message, "success")
                    history.push('/exhibition/email-found');
                    return false;
                }
                if (res.data.redirect == 1) {

                    history.push('/exhibition/guest-form');
                    return false;
                }
                else {
                    setemailexist({ ...emailexist, error_list: res.data.validate_error });

                }
            })
        });


    };
    return (
        <div>
            <EmsNavbar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }} >   </Box>

                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }} >

                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Verify your Email
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="off"
                            id="email"
                            label="Email Address"
                            name="email"
                            autoFocus
                            onChange={handleInput} value={emailexist.email}
                            helperText={emailexist.error_list.email} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 ,backgroundColor: '#145348'}}
                        >
                            Verify
                        </Button>

                    </Box>
                </Box>

            </Container>
        </div>
    );
}

export default Verifiction;
