import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';

import FormHelperText from '@mui/material/FormHelperText';
import { FormatBold } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';


import FormLabel from '@mui/material/FormLabel';

const EditArticle = (props) => {
  const [disabled, setdisabled] = useState(false);
  const [adminAbstractCategory, setAdminAbstractCategory] = React.useState([]);
  const eventid = localStorage.getItem('eventid')
  React.useEffect(() => {
    axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractCategoryData = res.data.articleCategory;
        setAdminAbstractCategory(abstractCategoryData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);
  const [adminSubmissionType, setAdminSubmissionType] = React.useState([]);

  React.useEffect(() => {
    axios.get(`api/abstract-submisson-index?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractsubmissionTypeData = res.data.submissionType;
        setAdminSubmissionType(abstractsubmissionTypeData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);


  const [aproveArticleEdit, setAproveArticleEdit] = useState({
    id: '',
    title: '',
    description: '',
    image: '',
    attachment: '',
    video_link: '',
    reject: '',
    created_by: '',
    start_date: '',

    introduction_background: '',
    materials_and_methods: '',
    results: '',
    conclusion: '',
    custom: [],
  });


  const belongTo = 2;
  useEffect(() => {
    const id = props.match.params.id;
    axios.get(`api/abstractArticleEdit/${id}/${eventid}/${belongTo}`).then(res => {

      if (res.status === 200) {
        const data = res.data.abstractEdit;
        setAproveArticleEdit(data)


      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }

    });

  }, [props.match.params.id]);

  const handleInput = (e) => {
    e.persist();
    setAproveArticleEdit({ ...aproveArticleEdit, [e.target.name]: e.target.value });
  }
 
  const [status, setStatus] = React.useState(1);
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const [reStatus, setReStatus] = React.useState('');
  const handleChangeReStatus = (e) => {
    setReStatus(e.target.value);
  };

  const cateid = aproveArticleEdit.category
  const [articleCategoryId, setAprovedArticle] = useState("");

  useEffect((e) => {
    setAprovedArticle(cateid);
  }, [cateid]);

  const type = aproveArticleEdit.submission_id;
  const [selectedType, setSelectedType] = React.useState("");
  useEffect((e) => {
    setSelectedType(type);

  }, [type]);


  const [abstractImage, setAbstractImage] = useState("");
  const [abstractFile, setAbstractFile] = useState("");


  const history = useHistory();





  

  const [customFieldLoad, setCustomFieldLoad] = React.useState([]);

  React.useEffect(() => {
    axios.get(`api/load-customfield/${belongTo}/${eventid}`).then(res => {

      if (res.status === 200) {
        const FieldLoad = res.data.customFieldLoad;
        setCustomFieldLoad(FieldLoad)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);


  const [customFieldInput, setCustomFieldInput] = useState({});

 
  const [customFieldTextArea, setCustomFieldTextArea] = useState({});

  const [customFieldNumber, setCustomFieldNumber] = useState('');


  const [customFieldselectedRadio, setSelectedGender] = useState({});
  const handleChangeGender = (item) => (event) => {
    setSelectedGender({
      ...customFieldselectedRadio,
      [item]: event.target.value,
    });

  };
  const [customFieldselectedLoadDropDwon, setSelectedLoadDropDwon] = React.useState('');
  const handleTypeCustomLoad = (item) => (event) => {
    setSelectedLoadDropDwon({ ...customFieldselectedLoadDropDwon, [item]: event.target.value, });

  };

  const [customFieldmultipleSeleteValue, setCustomFieldmultipleSeleteValue] = React.useState(['']);

  const handleChangeMulti = (fieldId) => (event) => {
    setCustomFieldmultipleSeleteValue((prevValues) => {
      const selectedValues = event.target.value;
      return {
        ...prevValues,
        [fieldId]: selectedValues,
      };
    });
  };

  
  const [customFieldcheckbox, setCustomFieldCheckbox] = useState({});
 
  
  const handleCheckboxChange = (fieldId, optionName) => {
    setCustomFieldCheckbox((prevValues) => {
      const newState = { ...prevValues };
  
      if (newState[fieldId]) {
        // If the fieldId exists, toggle the optionName
        newState[fieldId] = newState[fieldId].includes(optionName)
          ? newState[fieldId].filter((name) => name !== optionName)
          : [...newState[fieldId], optionName];
      } else {
        // If the fieldId doesn't exist, create a new array with optionName
        newState[fieldId] = [optionName];
      }
  
     
      return newState;
    });
  };
  

  const [customImage, setCustomtImage] = useState({});
  // const [imageSources, setImageSources] = useState({});

  const handleImageChange = (item) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setCustomtImage((prevSources) => ({
        ...prevSources,
        [item]: e.target.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };



  const [customFile, setCustomtFile] = useState('')
  const handleImageChangeFile = (item) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setCustomtFile((prevSources) => ({
        ...prevSources,
        [item]: e.target.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const filteredCustomFieldMultipleSelectValues = Object.fromEntries(
    Object.entries(customFieldmultipleSeleteValue).filter(([key, value]) => value !== "")
  );


  
  const abstractAdminArticleupdate = (e) => {

    e.preventDefault();

    const eventid = localStorage.getItem('eventid')
    const reStatus = 2;
    const data = {
      eventid,
      belongTo,
      title: aproveArticleEdit.title,
      description: aproveArticleEdit.description,
      video_link: aproveArticleEdit.video_link,
      additionalauthors: aproveArticleEdit.additionalauthors,
      nameofpresenter: aproveArticleEdit.nameofpresenter,

      introduction_background: aproveArticleEdit.introduction_background,
      materials_and_methods: aproveArticleEdit.materials_and_methods,
      results: aproveArticleEdit.results,
      conclusion: aproveArticleEdit.conclusion,

      reStatus,
      //abstractAdminCategory,
      articleCategoryId,
      abstractImage,
      abstractFile,
      selectedType,


      customFieldInput,
      customFieldselectedRadio,
      customFieldselectedLoadDropDwon,
      customFieldTextArea,
      customFieldNumber,
      customFieldcheckbox,
      customImage,
      customFile,
      filteredCustomFieldMultipleSelectValues,

    }
    console.log(data)
   

    setdisabled(false)
    const id = props.match.params.id
    axios.get('/sanctum/csrf-cookie').then(response => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(`/api/abstractArticleUpdate/${id}`, data, config).then(res => {

        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success');
          history.push('/admin/abstract/pending-article');
        }
        else {

          //setAbstractAdminArticle({ ...abstractAdminArticle, error_list: res.data.validate_error })

        }

      });


    });



  }

  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            onClick={() => history.goBack()} >
            BAck
          </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Edit  Abstract 
            </Typography>
            <Box component="form" onSubmit={abstractAdminArticleupdate} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={5}>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Article Title"
                    autoFocus
                    name='title' onChange={handleInput} value={aproveArticleEdit.title || ""} />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel >Select Category</InputLabel>
                    <Select
                      name='abstractAdminCategory' value={articleCategoryId || ""}
                      onChange={(e) => setAprovedArticle(e.target.value)} // we can articleCategoryId directly here
                      input={<OutlinedInput label="Category" />}>

                      {
                        adminAbstractCategory.map((item => {
                          return (

                            <MenuItem value={item.id || ""} key={item.id}> {item.title || ""}</MenuItem>
                          )

                        }))
                      }
                    </Select>

                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Selete Submission Type</InputLabel>
                     <Select
                        name='selete_type'
                        value={selectedType || ""}
                        label="Select Type" onChange={(e)=> setSelectedType(e.target.value)} 
                        input={<OutlinedInput label="Category" />}>
                      <MenuItem value={1}>Podium</MenuItem>
                      <MenuItem value={2}>Poster</MenuItem>
                      <MenuItem value={1}>Abstract</MenuItem>
                      <MenuItem value={2}>conf presentation</MenuItem>
                      <MenuItem value={3}> Poster</MenuItem>
                      <MenuItem value={4}>Workshop </MenuItem>
                      <MenuItem value={5}>Full Research Paper</MenuItem>
                      <MenuItem value={6}>Competition </MenuItem>
                      <MenuItem value={7}>Research Award </MenuItem>
                      </Select>
                   
                  </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel >Submission Type</InputLabel>
                    <Select
                      name='selete_type' value={selectedType || ""}
                      label="Select Type" onChange={(e) => setSelectedType(e.target.value)}
                      input={<OutlinedInput label="Category" />}>

                      {
                        adminSubmissionType.map((item => {
                          return (

                            <MenuItem value={item.id || ""} key={item.id}> {item.title || ""}</MenuItem>
                          )

                        }))
                      }
                    </Select>

                  </FormControl>

                </Grid>
                <Grid item xs={6} >
                  <label htmlFor="icon-button-file">
                    <TextField id="icon-button-file" type="file" onChange={(e) => setAbstractImage(e.target.files[0])} name="[licenseFile]" />
                    <IconButton color="primary" aria-label="upload picture" component="span" >
                      <PhotoCamera />
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span" >
                      {
                        aproveArticleEdit.image == "no_image.jpg" ? "no image upload" :
                          <img src={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.image}`} width="50" height="60" />
                      }
                    </IconButton>

                  </label>
                </Grid>


                <Grid item xs={6} >
                  <label htmlFor="contained-button-file">
                    <TextField id="contained-button-file" multiple type="file" onChange={(e) => setAbstractFile(e.target.files[0])} />

                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <AttachmentIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="upload picture" component="span">
                     
                      {aproveArticleEdit.attachment ?
                <a href={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.attachment}`}
                  download={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.attachment}`}
                  target="_blank" rel="noreferrer">View Attachment</a> : <Button>No Attachment</Button>}
                    </IconButton>
                  </label>
                </Grid>

                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Youtube Video ID"
                    autoFocus
                    name='video_link' onChange={handleInput} value={aproveArticleEdit.video_link || ""} />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Name Of Presenter"
                    autoFocus
                    name='nameofpresenter'
                    multiline
                    rows={2} onChange={handleInput} value={aproveArticleEdit.nameofpresenter || ""} />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Additional Authors"
                    autoFocus
                    name='additionalauthors'
                    multiline
                    rows={2} onChange={handleInput} value={aproveArticleEdit.additionalauthors || ""}
                    helperText="Add Name With ," />
                </Grid>
                {/* <Grid item xs={6} >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={aproveArticleEdit.type}
                      name='type' onChange={handleType} 
                      label="Action" >
                      <MenuItem value={1}>Abstract</MenuItem>
                      <MenuItem value={2}>conf presentation</MenuItem>
                      <MenuItem value={3}> Poster</MenuItem>
                      <MenuItem value={4}>Workshop </MenuItem>
                      <MenuItem value={5}>Full Research Paper</MenuItem>
                      <MenuItem value={6}>Competition </MenuItem>
                      <MenuItem value={7}>Research Award </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                {/* <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Article Details"
                    autoFocus
                    name='description'
                    multiline
                    rows={6} onChange={handleInput} value={aproveArticleEdit.description} />
                </Grid> */}

                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    label="Introduction/background"
                    autoFocus
                    name='introduction_background'
                    multiline
                    rows={4} onChange={handleInput} value={aproveArticleEdit.introduction_background}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    inputProps={{ maxLength: 100 }}

                    label="Materials and Methods"
                    autoFocus
                    name='materials_and_methods'
                    multiline
                    rows={4} onChange={handleInput} value={aproveArticleEdit.materials_and_methods}
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    inputProps={{ maxLength: 400 }}

                    label="Abstract Summary"
                    autoFocus
                    name='description'
                    multiline
                    rows={6} onChange={handleInput} value={aproveArticleEdit.description}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    inputProps={{ maxLength: 400 }}

                    label="Results"
                    autoFocus
                    name='results'
                    multiline
                    rows={4} onChange={handleInput} value={aproveArticleEdit.results}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    inputProps={{ maxLength: 400 }}

                    label="Conclusion"
                    autoFocus
                    name='conclusion'
                    multiline
                    rows={4} onChange={handleInput} value={aproveArticleEdit.conclusion}
                  />
                </Grid>


                {
                  aproveArticleEdit.customFieldLoad && aproveArticleEdit.customFieldLoad.map((item, index) => {
                    return (
                      <Grid item xs={6} key={index + 1}>
                        {item.type == 'input' ?
                          <TextField
                            autoComplete="given-name"
                            fullWidth
                         
                            label={item.name}
                            defaultValue={item.default_value}
                            autoFocus={true}
                            name={item.field_name}
                            onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} />
                          : ""}
                        {
                          item.type == 'radioBox' ?
                            <>
                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              {
                                item.options.map((radioOption, index) => {
                                  return (
                                    //<FormControl component="fieldset"></FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name={item.name}
                                      value={customFieldselectedRadio[item.id] || item.default_value || ""}
                                      onChange={handleChangeGender(item.id)}
                                      key={index + 1}>
                                      <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                    </RadioGroup>

                                  )
                                })

                              }  </> : ""}
                        {item.type == 'select' ?

                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={customFieldselectedLoadDropDwon[item.id] || item.default_value || ""}
                              onChange={handleTypeCustomLoad(item.id)}
                              label="Action" >
                              {item.options.map((dropDwonValue, index) => {
                                return (
                                  <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                                )

                              })
                              }
                            </Select>
                            <FormHelperText> </FormHelperText>
                          </FormControl>
                          : ""}
                        {
                          item.type == 'textarea' ?

                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              label={item.name}
                              autoFocus
                              multiline
                              rows={4}
                              defaultValue={item.default_value || ""}
                              name={item.field_name}
                              onChange={(e) => setCustomFieldTextArea({ ...customFieldTextArea, [item.id]: e.target.value })}
                            />
                            : ""
                        }
                        {
                          item.type == 'number' ?
                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              type="number"
                              label={item.name}
                              defaultValue={item.default_value || ""}
                              autoFocus
                              name={item.field_name}
                              onChange={(e) => setCustomFieldNumber({ ...customFieldNumber, [item.id]: e.target.value })} />
                            : ""
                        }
                        {
                          item.type == 'multiSelect' ?
                            <FormControl key={item.id} variant="outlined" sx={{ width: 395, }}>
                              <InputLabel id={`${item.id}-label`}>{item.name} </InputLabel>
                              <Select
                                label={item.id}
                                labelId={`${item.name}-label`}
                                multiple
                               value={customFieldmultipleSeleteValue[item.id] || item.default_value || []}
                                onChange={handleChangeMulti(item.id)}>
                                  
                                {item.options.map((multipleOption, index) => (
                                  <MenuItem key={index + 1} value={multipleOption.name}>
                                    {multipleOption.name}
                                  </MenuItem>
                                ))}
                              </Select>
                             
                            
                            </FormControl>
                            : ""
                        }

                        {
                          item.type == 'checkBox' ?
                            <>
                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                {item.options.map((multipleOption, index) => (

                                  <FormControlLabel
                                    key={index + 1}
                                    control={
                                   
                                      <Checkbox
                                        checked={customFieldcheckbox[multipleOption.id]?.includes(multipleOption.name) ?? multipleOption.isChecked}
                                        onChange={() => handleCheckboxChange(multipleOption.id, multipleOption.name)}
                                      />

                                    } label={multipleOption.name} />

                                ))}
                              </FormGroup>
                            </> : ""}



                        {
                          item.type == 'link' ?
                            <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""
                        }
                        {
                          item.type == 'image' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography>&nbsp;&nbsp;&nbsp;
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  {
                                    item.default_value == null ? "no image upload" :
                                      <img src={`${aproveArticleEdit.base_url}/abstract/${item.default_value || ""}`} width="50" height="50" />
                                  }

                                </IconButton>
                              </label> </> : ""

                        }
                        {
                          item.type == 'file' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChangeFile(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <AttachmentIcon />

                                </IconButton>

                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography> &nbsp;&nbsp;&nbsp;
                                <IconButton color="primary" aria-label="upload picture" component="span">

                                  {item.default_value == null ? "no file upload" :
                                    <a href={`${aproveArticleEdit.base_url}/abstract/${item.default_value || ""}`}
                                      download={`${aproveArticleEdit.base_url}/abstract/${item.default_value}`}
                                      target="_blank" rel="noreferrer">Download Attachment</a>
                                  }
                                </IconButton>
                              </label> </> : ""}
                      </Grid>
                    )
                  })
                }



              </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  disabled={disabled}
                  variant="contained"
                  sx={{ mt: 5, backgroundColor: '#145348', }} >
                  Update Abstract
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div >
  );
}

export default EditArticle;
