import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
const GenralSetting = () => {
    const history = useHistory();
    const [comapanyLogo, setCompanyLogo] = useState("");
    const [settings, setSettings] = useState({
        COMPANY_NAME: '',
        COMPANY_CONTAC: '',
        COMPANY_LOGO: '',
       
    });
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const eventid = localStorage.getItem('eventid');

        axios.get(`/api/get-email-setting/${eventid}`)
            .then(response => {
                const settingsData = response.data.settings;
                setSettings(settingsData);
            })
            .catch(error => {
                console.error('Error fetching email settings:', error);
            });
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    };
   

    const StoreSmtpSetting = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid');

        const data = {
            eventid,
            settings,
            comapanyLogo,


        };
        console.log(data);
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/email-setting`, data, config).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                  
                    history.push('/admin/email/setting');
                }
                // else {
                //     setAddSmtpSetting({ ...addSmtpSetting, error_list: res.data.validate_error })
                // }
            });
        });


    }

    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        // component={Link}
                        // to="#"
                        onClick={() => history.goBack()}>
                        BAck
                    </Button>

                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            General Setting
                            <Button sx={{ float: 'right', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/email/setting"  >
                                Email Setting
                            </Button>
                            <Button sx={{ marginRight: '2%', float: 'right', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/test/email"  >
                              Test  Email 
                            </Button>
                            

                        </Typography>
                        <Box component="form" onSubmit={StoreSmtpSetting} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>


                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Company Name"
                                        autoFocus
                                        name='COMPANY_NAME'
                                        value={settings.COMPANY_NAME || ""}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Company Contact"
                                        autoFocus
                                        placeholder="00966"
                                        name='COMPANY_CONTACT' 
                                        value={settings.COMPANY_CONTACT || ""}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                <span>Company Logo</span>
                                    <label htmlFor="icon-button-file">
                                        <TextField accept="image/*" id="icon-button-file" type="file"  onChange={(e) => setCompanyLogo(e.target.files[0])}/>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton><br/><br/>
                                       
                                        {
                                            settings.COMPANY_LOGO === "" ?
                                                <span>No Logo Uploaded</span> :
                                                <><img src={`${settings.BASE_URL}/settings/${settings.COMPANY_LOGO}`} width="150" height="150" />
                                                </>
                                        }
                                    </label>
                                   
                                </Grid>




                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card >
            </Box >
        </div >
    );
}

export default GenralSetting;
