import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const TestingEmail = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [attachImage, setAttachImage] = useState(false);
    const [valitionError, setValitionError] = useState(
        {
            eventid: "",
            email: "",
            subject: "",
            message: "",
            error_list: [],
        }
    );
    const eventid = localStorage.getItem('eventid');

    const handleSendEsssmail = async () => {

        try {
            const response = await axios.post(`/api/send-email/${eventid}`, {
                eventid,
                email,
                subject,
                message,
                attachImage,
            });

            swal("Email sent successfully!", "", "success");
        } catch (error) {
            // Handle errors, show an error message
            swal("Error sending email", error.message, "error");
        }
    };
    const handleSendEmail = (e) => {
        e.preventDefault();
        try {
            const data = {
                eventid,
                email,
                subject,
                message,
                attachImage,
            };

            axios.get('/sanctum/csrf-cookie').then(response => {
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                axios.post(`/api/send-email/${eventid}`, data, config).then(res => {
                    if (res.data.status === 200) {
                        setEmail('');
                        setSubject('');
                        setMessage('');
                        setAttachImage('')

                        swal("Email sent successfully!", res.data.message, "success");
                    }
                    else {

                        setValitionError({ ...valitionError, error_list: res.data.validate_error })
                    }
                });
            });

        } catch (error) {
            // Handle errors, show an error message
            swal("Error sending email", valitionError.valitionError, "error");
        }


    }
    return (
        <div style={{ margin: '5%', width: '60vw', marginLeft: '10%' }} >

            <Card style={{ marginTop: '5%' }}>
                <Button sx={{
                    float: 'right',
                    margin: '2%',
                    ":hover": {
                        color: "white"
                    }
                }}
                    color='success'
                    size='small'
                    variant='contained'

                    onClick={() => history.goBack()}>
                    BAck
                </Button>
                <CardContent>
                    <Typography variant="h5">Send Test Email</Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSendEmail}>
                        {
                            valitionError.error_list.eventid ? <p style={{ color: 'red' }}>* EventID Required</p> : ""

                        }

                        <Grid container spacing={4}>
                            <Grid item xs={6} >
                                <TextField
                                    autoComplete="given-name"
                                    required
                                    fullWidth
                                    autoFocus
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    helperText={valitionError.error_list.email} />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    autoComplete="given-name"
                                    required
                                    fullWidth
                                    autoFocus
                                    label="subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    helperText={valitionError.error_list.subject} /></Grid>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="given-name"
                                    required
                                    fullWidth
                                    multiline
                                    rows={6}
                                    autoFocus
                                    label="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    helperText={valitionError.error_list.message} />
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"

                                    sx={{ mt: 5, }} >
                                    Send Email
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}

export default TestingEmail;
