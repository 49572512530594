import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

const EditBooth = (props) => {
    const history = useHistory();
    const id = props.match.params.id;
    const eventid = localStorage.getItem('eventid');
    const [exhibitionEditBooth, setExhibitionEditBooth] = useState({
        code:"",
        type: '',
        price: "",
        length:"",
        width: "",
        exhibitor:"",
        boothstatus:"",
        boothCategory:"",
        error_list: [],
    });
    
   
    useEffect(() => {
        axios.get(`api/edit-exhibition-booth/${id}`).then(res => {
            if (res.status == 200) {
                const exhibitionBoothSingleData = res.data.ebt;
                setExhibitionEditBooth(exhibitionBoothSingleData);
            }
        });
    }, [props.match.params.id]);
    const handleInput = (e) => {
        e.persist();
        setExhibitionEditBooth({ ...exhibitionEditBooth, [e.target.name]: e.target.value });
    }
   
    const cateid = exhibitionEditBooth.exhibition_booth_category_id
    ;
    const [boothCategoryID, setboothCategoryID] = useState("");
    
    useEffect((e) => {
        setboothCategoryID(cateid);
    }, [cateid]);

    const booth_status = exhibitionEditBooth.booth_status;
    const [boothstatus, setBoothstatus] = React.useState('');
     useEffect((e) => {
     setBoothstatus(booth_status)
     }, [booth_status]);

    const [exhibitionCategory, setExhibitionCategory] = useState([]);
    useEffect(() => {
        axios.get(`api/index-exhibition-booth-category?id=${eventid}`).then(res => {
            if (res.status == 200) {
                const exhibitionBCate = res.data.exBoothCategory;
                setExhibitionCategory(exhibitionBCate)
            }

        });


    }, []);
   
    const exhibitionBoothUpdateSubmit = (e) => {
        e.preventDefault();
        const data = {
            code: exhibitionEditBooth.code,
            type: exhibitionEditBooth.type,
            price: exhibitionEditBooth.price,
            length: exhibitionEditBooth.length,
            width: exhibitionEditBooth.width,
            exhibitor: exhibitionEditBooth.exhibitor,
            boothstatus,
            eventid,
            boothCategoryID,
        }
        console.clear()
        console.log(data)
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/update-exhibition-booth/${id}`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/exhibition/booth');
                }
                else {
                    setExhibitionEditBooth({ ...exhibitionEditBooth, error_list: res.data.validate_error })
                }
            });
        });
       

    }
  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                onClick={() => history.goBack()}>
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">
                    Update Exhibition Booth
                </Typography>
                <Box component="form" onSubmit={exhibitionBoothUpdateSubmit} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">BoothCategory</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={boothCategoryID || ""}
                                    onChange={(e) => setboothCategoryID(e.target.value)} 
                                    label="Action" >
                                    {
                                        exhibitionCategory.map((item => {
                                            return (
                                                <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                                            )

                                        }))
                                    }

                                </Select>
                                
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Code"
                                autoFocus
                                name='code' onChange={handleInput}
                                value={exhibitionEditBooth.code || ""}
                                 />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Type"
                                autoFocus
                                name='type' onChange={handleInput}
                                value={exhibitionEditBooth.type || ""}/>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Price"
                                autoFocus
                                name='price' onChange={handleInput}
                                value={exhibitionEditBooth.price || ""}/>
                        </Grid>
                        <Grid item xs={12} >
                            Dimensions
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="L"
                                autoFocus
                                name='length' onChange={handleInput}
                                value={exhibitionEditBooth.length || ""}/>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="W"
                                autoFocus
                                name='width' onChange={handleInput}
                                value={exhibitionEditBooth.width || ""} />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Exhibitor"
                                autoFocus
                                name='exhibitor' onChange={handleInput}
                                value={exhibitionEditBooth.exhibitor || ""} />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={boothstatus || ""}
                                    onChange={(e) =>setBoothstatus(e.target.value)}
                                    label="Action" >
                                    <MenuItem value={1}>open</MenuItem>
                                    <MenuItem value={2}>Reserve</MenuItem>
                                    <MenuItem value={3}>Booked</MenuItem>

                                </Select>
                              
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Button
                            type="submit"
                            // fullWidth
                            variant="contained"
                            sx={{ mt: 5,backgroundColor: '#145348' }} >
                            Submit
                        </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    </Box>
</div>
  );
}

export default EditBooth;
