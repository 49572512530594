import React, { useState, useEffect } from "react";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import swal from "sweetalert";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';

import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid';
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function createData(
  ids,
  name,
  email,
  totalSubmit,
  phone,


) {
  return { ids, name, email, totalSubmit, phone, };
}


const Author = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [permissions, setrPermissions] = useState([]);
  useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)

      } else {

      }


    })

  }, []);
  const [abstractAuthor, setAbstractAuthor] = React.useState([]);

  const eventid = localStorage.getItem('eventid')
  React.useEffect(() => {
    axios.get(`api/authorlist?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const setAbstractAuthorData = res.data.authors;
        setAbstractAuthor(setAbstractAuthorData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredPosts = abstractAuthor.filter((row) =>
    (row.phone && row.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()))
    // Add more fields with null checks as needed
  );

  const totalRecords = abstractAuthor.length;
  const filteredRecordsCount = filteredPosts.length;

  
  //for Single Record  show
  const [open, setOpen] = useState(false);
  const [singleAuthor, setSingleAuthor] = useState({
    id: '',
    name: '',
    email: '',
    address: '',
    image: '',
  });
 
  const singleRewierShow = (id) => {
    const eventid = localStorage.getItem('eventid')
    axios.get(`api/authorsingle/${id},${eventid}`).then(res => {

      if (res.status === 200) {
        const author = res.data.author;
        setSingleAuthor(author)


      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredPosts.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const countryCodeMapping = {
  //   "SA": "Saudi Arabia (+966)",
  //   "EG": "Egypt (+20)",
  //   "AE": "United Arab Emirates (+971)",
  //   "BH": "Bahrain (+973)",
  //   "OM": "Oman (+968)",
  //   "QA": "Qatar (+974)",
  //   "KW": "Kuwait (+965)",
  //   "JO": "Jordan (+962)",
  //   "LB": "Lebanon (+961)",
  //   "SY": "Syria (+963)",
  //   "IQ": "Iraq (+964)",
  //   "PS": "Palestine (+970)",
  //   "YE": "Yemen (+967)",
  //   "IR": "Iran (+98)",
  //   "TR": "Turkey (+90)",
  //   "PK": "Pakistan (+92)",
  //   "IN": "India (+91)",
  //   "US": "United States (+1)",
  //   "DE": "Germany (+49)",
  //   "FR": "France (+33)",
  //   "IT": "Italy (+39)",
  //   "ES": "Spain (+34)",
  //   "GB": "United Kingdom (+44)",
  //   "NL": "Netherlands (+31)",
  //   "BE": "Belgium (+32)",
  //   "SE": "Sweden (+46)",
  //   "CN": "China (+86)"
  // };

  const countryCodeMapping = {
    "AF": "Afghanistan (+93)",
    "AL": "Albania (+355)",
    "DZ": "Algeria (+213)",
    "AS": "American Samoa (+1684)",
    "AD": "Andorra (+376)",
    "AO": "Angola (+244)",
    "AI": "Anguilla (+1264)",
    "AG": "Antigua and Barbuda (+1268)",
    "AR": "Argentina (+54)",
    "AM": "Armenia (+374)",
    "AW": "Aruba (+297)",
    "AU": "Australia (+61)",
    "AT": "Austria (+43)",
    "AZ": "Azerbaijan (+994)",
    "BS": "Bahamas (+1242)",
    "BH": "Bahrain (+973)",
    "BD": "Bangladesh (+880)",
    "BB": "Barbados (+1246)",
    "BY": "Belarus (+375)",
    "BE": "Belgium (+32)",
    "BZ": "Belize (+501)",
    "BJ": "Benin (+229)",
    "BM": "Bermuda (+1441)",
    "BT": "Bhutan (+975)",
    "BO": "Bolivia (+591)",
    "BA": "Bosnia and Herzegovina (+387)",
    "BW": "Botswana (+267)",
    "BR": "Brazil (+55)",
    "IO": "British Indian Ocean Territory (+246)",
    "VG": "British Virgin Islands (+1284)",
    "BN": "Brunei (+673)",
    "BG": "Bulgaria (+359)",
    "BF": "Burkina Faso (+226)",
    "BI": "Burundi (+257)",
    "KH": "Cambodia (+855)",
    "CM": "Cameroon (+237)",
    "CA": "Canada (+1)",
    "CV": "Cape Verde (+238)",
    "KY": "Cayman Islands (+1345)",
    "CF": "Central African Republic (+236)",
    "TD": "Chad (+235)",
    "CL": "Chile (+56)",
    "CN": "China (+86)",
    "CO": "Colombia (+57)",
    "KM": "Comoros (+269)",
    "CK": "Cook Islands (+682)",
    "CR": "Costa Rica (+506)",
    "HR": "Croatia (+385)",
    "CU": "Cuba (+53)",
    "CW": "Curaçao (+599)",
    "CY": "Cyprus (+357)",
    "CZ": "Czech Republic (+420)",
    "CD": "Democratic Republic of the Congo (+243)",
    "DK": "Denmark (+45)",
    "DJ": "Djibouti (+253)",
    "DM": "Dominica (+1767)",
    "DO": "Dominican Republic (+1-809)",
    "EC": "Ecuador (+593)",
    "EG": "Egypt (+20)",
    "SV": "El Salvador (+503)",
    "GQ": "Equatorial Guinea (+240)",
    "ER": "Eritrea (+291)",
    "EE": "Estonia (+372)",
    "ET": "Ethiopia (+251)",
    "FJ": "Fiji (+679)",
    "FI": "Finland (+358)",
    "FR": "France (+33)",
    "GA": "Gabon (+241)",
    "GM": "Gambia (+220)",
    "GE": "Georgia (+995)",
    "DE": "Germany (+49)",
    "GH": "Ghana (+233)",
    "GI": "Gibraltar (+350)",
    "GR": "Greece (+30)",
    "GL": "Greenland (+299)",
    "GD": "Grenada (+1473)",
    "GU": "Guam (+1671)",
    "GT": "Guatemala (+502)",
    "GN": "Guinea (+224)",
    "GW": "Guinea-Bissau (+245)",
    "GY": "Guyana (+592)",
    "HT": "Haiti (+509)",
    "HN": "Honduras (+504)",
    "HK": "Hong Kong (+852)",
    "HU": "Hungary (+36)",
    "IS": "Iceland (+354)",
    "IN": "India (+91)",
    "ID": "Indonesia (+62)",
    "IR": "Iran (+98)",
    "IQ": "Iraq (+964)",
    "IE": "Ireland (+353)",
    "IL": "Israel (+972)",
    "IT": "Italy (+39)",
    "CI": "Ivory Coast (+225)",
    "JM": "Jamaica (+1876)",
    "JP": "Japan (+81)",
    "JO": "Jordan (+962)",
    "KZ": "Kazakhstan (+7)",
    "KE": "Kenya (+254)",
    "KI": "Kiribati (+686)",
    "XK": "Kosovo (+383)",
    "KW": "Kuwait (+965)",
    "KG": "Kyrgyzstan (+996)",
    "LA": "Laos (+856)",
    "LV": "Latvia (+371)",
    "LB": "Lebanon (+961)",
    "LS": "Lesotho (+266)",
    "LR": "Liberia (+231)",
    "LY": "Libya (+218)",
    "LI": "Liechtenstein (+423)",
    "LT": "Lithuania (+370)",
    "LU": "Luxembourg (+352)",
    "MO": "Macau (+853)",
    "MK": "Macedonia (+389)",
    "MG": "Madagascar (+261)",
    "MW": "Malawi (+265)",
    "MY": "Malaysia (+60)",
    "MV": "Maldives (+960)",
    "ML": "Mali (+223)",
    "MT": "Malta (+356)",
    "MH": "Marshall Islands (+692)",
    "MR": "Mauritania (+222)",
    "MU": "Mauritius (+230)",
    "YT": "Mayotte (+262)",
    "MX": "Mexico (+52)",
    "FM": "Micronesia (+691)",
    "MD": "Moldova (+373)",
    "MC": "Monaco (+377)",
    "MN": "Mongolia (+976)",
    "ME": "Montenegro (+382)",
    "MS": "Montserrat (+1664)",
    "MA": "Morocco (+212)",
    "MZ": "Mozambique (+258)",
    "MM": "Myanmar (+95)",
    "NA": "Namibia (+264)",
    "NR": "Nauru (+674)",
    "NP": "Nepal (+977)",
    "NL": "Netherlands (+31)",
    "NZ": "New Zealand (+64)",
    "NI": "Nicaragua (+505)",
    "NE": "Niger (+227)",
    "NG": "Nigeria (+234)",
    "NU": "Niue (+683)",
    "NF": "Norfolk Island (+672)",
    "KP": "North Korea (+850)",
    "MP": "Northern Mariana Islands (+1670)",
    "NO": "Norway (+47)",
    "OM": "Oman (+968)",
    "PK": "Pakistan (+92)",
    "PW": "Palau (+680)",
    "PS": "Palestine (+970)",
    "PA": "Panama (+507)",
    "PG": "Papua New Guinea (+675)",
    "PY": "Paraguay (+595)",
    "PE": "Peru (+51)",
    "PH": "Philippines (+63)",
    "PL": "Poland (+48)",
    "PT": "Portugal (+351)",
    "PR": "Puerto Rico (+1-787)",
    "QA": "Qatar (+974)",
    "CG": "Republic of the Congo (+242)",
    "RO": "Romania (+40)",
    "RU": "Russia (+7)",
    "RW": "Rwanda (+250)",
    "BL": "Saint Barthélemy (+590)",
    "SH": "Saint Helena (+290)",
    "KN": "Saint Kitts and Nevis (+1869)",
    "LC": "Saint Lucia (+1758)",
    "MF": "Saint Martin (+590)",
    "PM": "Saint Pierre and Miquelon (+508)",
    "VC": "Saint Vincent and the Grenadines (+1784)",
    "WS": "Samoa (+685)",
    "SM": "San Marino (+378)",
    "ST": "São Tomé and Príncipe (+239)",
    "SA": "Saudi Arabia (+966)",
    "SN": "Senegal (+221)",
    "RS": "Serbia (+381)",
    "SC": "Seychelles (+248)",
    "SL": "Sierra Leone (+232)",
    "SG": "Singapore (+65)",
    "SX": "Sint Maarten (+1-721)",
    "SK": "Slovakia (+421)",
    "SI": "Slovenia (+386)",
    "SB": "Solomon Islands (+677)",
    "SO": "Somalia (+252)",
    "ZA": "South Africa (+27)",
    "KR": "South Korea (+82)",
    "SS": "South Sudan (+211)",
    "ES": "Spain (+34)",
    "LK": "Sri Lanka (+94)",
    "SD": "Sudan (+249)",
    "SR": "Suriname (+597)",
    "SZ": "Swaziland (+268)",
    "SE": "Sweden (+46)",
    "CH": "Switzerland (+41)",
    "SY": "Syria (+963)",
    "TW": "Taiwan (+886)",
    "TJ": "Tajikistan (+992)",
    "TZ": "Tanzania (+255)",
    "TH": "Thailand (+66)",
    "TL": "Timor-Leste (+670)",
    "TG": "Togo (+228)",
    "TK": "Tokelau (+690)",
    "TO": "Tonga (+676)",
    "TT": "Trinidad and Tobago (+1868)",
    "TN": "Tunisia (+216)",
    "TR": "Turkey (+90)",
    "TM": "Turkmenistan (+993)",
    "TC": "Turks and Caicos Islands (+1649)",
    "TV": "Tuvalu (+688)",
    "UG": "Uganda (+256)",
    "UA": "Ukraine (+380)",
    "AE": "United Arab Emirates (+971)",
    "GB": "United Kingdom (+44)",
    "US": "United States (+1)",
    "UY": "Uruguay (+598)",
    "UZ": "Uzbekistan (+998)",
    "VU": "Vanuatu (+678)",
    "VA": "Vatican (+39)",
    "VE": "Venezuela (+58)",
    "VN": "Vietnam (+84)",
    "VI": "Virgin Islands (+1340)",
    "EH": "Western Sahara (+212)",
    "YE": "Yemen (+967)",
    "ZM": "Zambia (+260)",
    "ZW": "Zimbabwe (+263)"
  };
  

  return (
    <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
        </h4>
        <Card sx={{ width: '82vw', float: 'center' }} >
          <CardContent >

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography component="h1" variant="h5">
                Author List
              </Typography>
              Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
              <Grid item xs={8} >
                <TextField
                  fullWidth
                  label="Filter By: Name , Email , Phone"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
            </div>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="center"> Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Total Submit</TableCell>
                      <TableCell align="right">Phone</TableCell>
                      <TableCell align="center" style={{ width: 260 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0
                      ? filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : filteredPosts
                    ).map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 60 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                        {row.name_title ? `${row.name_title} : ${row.name}` : row.name}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                          {row.email}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                          <Badge badgeContent={row.author_all_abs_count || "0"} color="success">
                          </Badge>
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                          {/* {row.phone} */}
                          {`${countryCodeMapping[row.country_code] || ""} - ${row.phone || "No Phone"}`}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">

                          <Button sx={{
                            ":hover": {
                              color: "white"
                            },
                            backgroundColor: '#145348',
                          }}

                            size='small'
                            variant='contained'
                            onClick={(e) => singleRewierShow(row.id)}>
                            <VisibilityIcon />
                          </Button>
                          {/* {permissions['p_abstract-article-author-edit'] === true ?
                             <Button sx={{
                           margin:1,
                         
                             ":hover": {
                                 color: "white"
                             },
                             backgroundColor: '#145348',
                         }}
                            
                             size='small'
                             variant='contained' > 
                             <EditNoteIcon/>
                         </Button>  : ""
                          } */}
                          {permissions['p_abstract-article-author-delete'] === true ?
                            <Button sx={{
                              margin: 1,

                              ":hover": {
                                color: "white"
                              },
                              backgroundColor: '#145348',
                            }}

                              size='small'
                              variant='contained' >
                              <DeleteIcon />
                            </Button> : ""
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredPosts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
          Event:
          <Badge badgeContent={singleAuthor.eventname} color="success" style={{ width: 150, pl: 120 }}>
          </Badge>
        </DialogTitle>
        <DialogTitle id="alert-dialog-title">
          Name: {singleAuthor.name}
          <br />

        </DialogTitle>

        <DialogTitle id="alert-dialog-title">
          Email: {singleAuthor.email}
        </DialogTitle>

        <DialogTitle id="alert-dialog-title">
          contact:{singleAuthor.phone}
        </DialogTitle>

        {
          singleAuthor.image == null || 'no_image.jpg' ? "" :
            <DialogTitle id="alert-dialog-title">
              <img src={`${singleAuthor.base_url}/abstract/${singleAuthor.image}`} width="150" height="150" />
            </DialogTitle>
        }


        {
          singleAuthor.custom && singleAuthor.custom.map((item, index) => {
            return (

              <Grid item xs={6} key={index + 1}>
                {item.label_type == 'input' ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == 'radioBox' ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "select" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "textarea" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "number" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "multiSelect" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "checkBox" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "link" ?
                  <DialogTitle>{item.label_name}: {item.value}</DialogTitle>
                  : ""}
                {item.label_type == "image" ?
                  <React.Fragment>
                    <DialogTitle>{item.label_name}
                      <img src={`${singleAuthor.base_url}/abstract/${item.value}`} width="500" height="400" />
                    </DialogTitle>
                  </React.Fragment>
                  : ""}
                {item.label_type == "file" ?
                  <DialogTitle>
                    <React.Fragment>
                      <a href={`${singleAuthor.base_url}/abstract/${item.value}`}
                        download={`${singleAuthor.base_url}/abstract/${singleAuthor.attachment}`}
                        target="_blank" rel="noreferrer">Download {item.label_name}</a>
                    </React.Fragment>
                  </DialogTitle>
                  : ""}

              </Grid>
            )
          })
        }


        <DialogActions>
          <IconButton onClick={handleClose} >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Author;
