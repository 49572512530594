import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import EmsNavbar from "../../frontend/EmsNavbar";
import { Padding } from "@mui/icons-material";
const theme = createTheme();

const EmailFound = () => {
  const history = useHistory();
   const eventid = localStorage.getItem('eventid');
  const [emailexist, setemailexist] = useState({
      email: "",
      error_list: [],
  });
  const [urlLink, setUrlLink] = useState('');

  const handleInput = (e) => {
      e.persist();
      setemailexist({ ...emailexist, [e.target.name]: e.target.value });
  }
console.log(urlLink)

  const [exhibitionEmailFind, setExhibitionEmailFind] = React.useState([]);
 
   //const eventid = 56;
  React.useEffect(() => {
    axios.post(`api/useremailfind?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const searchingEmain = res.data.email;
        setExhibitionEmailFind(searchingEmain)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);
 
const email = localStorage.getItem('exhibition_email');

const data = {
    email,
  eventid,
}
React.useEffect(() => {
axios.get('/sanctum/csrf-cookie').then(response => {

    axios.post(`api/useremailfind`, data).then(res => {
        if (res.data.redirect == 0) {
          setUrlLink(res.data.badge_link)
            // swal("Success", res.data.message, "success")
            // history.push('/exhibition/email-found');
            // console.log( res.data.badge_link);
            return false;
        }
        if (res.data.redirect == 1) {

            history.push('/exhibition/guest-form');
            return false;
        }
        else {
            setemailexist({ ...emailexist, error_list: res.data.validate_error });

        }
    })
});
}, []);

  const handleSubmit = (e) => {
      e.preventDefault();
     

  };
    return (
        <>
            <EmsNavbar />
            <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="auto'">
            <CssBaseline />
            <Box
              sx={{
                width: 'auto',
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ mt: 12, bgcolor: 'secondary.main', }}>
                <EmailIcon />
              </Avatar>
              <Typography align="center" style={{paddingTop:'2%',}}>Please check your registration email for more information;
              <br/> 
               Thank You  !!!  <br/> 
               <a href={urlLink} target="_blank" rel="noopener noreferrer">
          Click The Link To view The Badge
        </a>
      </Typography>
             
            
            </Box>
           
          </Container>
        </ThemeProvider>

        </>
    );
}

export default EmailFound;
