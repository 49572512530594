import React, { useState, useEffect } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from '@mui/icons-material';
import _debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';





import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Grid from '@mui/material/Grid';




const BoothCategory = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const eventid = localStorage.getItem('eventid');
    const [permissions, setrPermissions] = useState([]);
    useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)

            } else {

            }


        })

    }, []);
    const [exhibitionCategory, setExhibitionCategory] = useState([]);
    useEffect(() => {
        axios.get(`api/index-exhibition-booth-category?id=${eventid}`).then(res => {
            if (res.status == 200) {
                const exhibitionBCate = res.data.exBoothCategory;
                setExhibitionCategory(exhibitionBCate)
            }

        });


    }, []);


    const inActiveBoothCategory = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."
        axios.get(`api/status-exhibition-booth-category/${id}`).then(res => {
            if (res.data.status === 200) {
                //swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();
            }
            else if (res.data.status === 404) {
                this.innText = "delete";
            }
        })


    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = exhibitionCategory.filter((row) =>
    (row.size &&  row.size.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.title && row.title.toLowerCase().includes(searchTerm.toLowerCase()))
       
        // Add more fields as needed
    );
    const totalRecords = exhibitionCategory.length;
    const filteredRecordsCount = filteredPosts.length;

    
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;



    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    {
                        permissions['p_exhibition-booth-categoy-create'] === true ?
                            <Button sx={{
                                float: 'right',
                                marginRight: '1%',
                                marginTop: '2%',
                                ":hover": {
                                    color: "white"
                                }
                            }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/exhibition/addboothcategory">
                                Add New
                            </Button> : ""
                    }


                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                     
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography component="h1" variant="h5">
                            Booth Categoy
                            </Typography>
                            Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                            <Grid item xs={8} >
                                <TextField
                                    fullWidth
                                    label="Filter By: Title , Size "
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </div>
                        <Box component="form" noValidate sx={{ mt: 3 }}>

                          
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell > #</TableCell>
                                            <TableCell align="left"> Title</TableCell>
                                            <TableCell align="left">Size</TableCell>
                                            <TableCell align="left">Descriptions</TableCell>
                                            {
                                             permissions['p_exhibition-booth-categoy-edit'] === true ?
                                            <TableCell align="left">Action</TableCell>:
                                            permissions['p_exhibition-booth-categoy-delete'] === true ?
                                            <TableCell align="left">Action</TableCell>:<TableCell align="left"></TableCell>}


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPosts.slice(startIndex, endIndex).map((row, index) => (
                                            <TableRow key={index}>
                                                <>
                                                    <TableCell key={row.id}>{index + 1}</TableCell>
                                                    <TableCell >{row.title}</TableCell>
                                                    <TableCell >{row.size}</TableCell>
                                                    <TableCell >{row.description || "No Description Added"}</TableCell>
                                                    <TableCell >
                                                        {
                                                            permissions['p_exhibition-booth-categoy-edit'] === true ?
                                                        <Button sx={{ marginBottom: 1, ":hover": { color: "white" }, backgroundColor: '#145348' }}
                                                            color='primary'
                                                            size='small'
                                                            variant='contained'
                                                            component={Link}
                                                            to={`/admin/exhibition/exhibitorcategory-edit/${row.id}`}>
                                                            <EditNoteIcon />
                                                        </Button>:""}
                                                        {
                                                            permissions['p_exhibition-booth-categoy-delete'] === true ?
                                                        <Button sx={{ backgroundColor: '#145348',":hover": { color: "white"}}}
                                                            color='primary'
                                                            size='small'
                                                            variant='contained'
                                                            onClick={(e) => inActiveBoothCategory(e, row.id) }>
                                                            <DeleteIcon />
                                                        </Button>:""}
                                                    </TableCell>

                                                </>
                                            </TableRow>
                                        ))}
                                    </TableBody>


                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                                    component="div"
                                    count={filteredPosts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>

                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default BoothCategory;

