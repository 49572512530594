import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Badge from '@mui/material/Badge';

const AddIssuePending = (props) => {
    const history = useHistory();
    const id = props.match.params.id;
    const abstractId = props.match.params.id;
    const [errorlist , setError] =useState([]);
    const [disabled, setdisabled] = useState(false);
    localStorage.setItem('abstractId', abstractId);
    const [status, setStatus] = React.useState('');
    const [issuePost, setIssuePost] = React.useState({
        title: "",
        description: "",
        error_list: [],
    });
    const [aproveArticleEdit, setAproveArticleEdit] = useState({
        id: '',
        writer_id: '',
        title:'',
    });
    const [getabstractTitle, setGetabstractTitle] = React.useState('');


    useEffect(() => {
        axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
          if (res.status === 200) {
            const getAbstTitle = res.data.abstractTitle;
            const getAbstTotal = res.data;
            setGetabstractTitle(getAbstTitle)
         
          }
          else {
            swal('Error', "Check API Something Wrong", 'error');
          }
        });
    
      }, []);

    const handleInput = (e) => {
        e.persist();
        setAproveArticleEdit({ ...aproveArticleEdit, [e.target.name]: e.target.value });
      };
    
    const handleChange = (e) => {
        setStatus(e.target.value);

     };
   
    const handleInputIssue = (e) => {
        e.persist();
        setIssuePost({ ...issuePost, [e.target.name]: e.target.value });
    }

    const abstractAdminArticleIssue = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            eventid,
            title: issuePost.title,
            article_id: getabstractTitle.id,
            description: issuePost.description,
            writer_id: getabstractTitle.writer_id,
            abstractArticle: getabstractTitle.title,
            
        }

        data.title == "" ? setdisabled(false) : data.description == "" ? setdisabled(false) : setdisabled(true);



        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/abstractIssueStore`, data, ).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/abstract/pending-article');
                }
                else {

                    setError(res.data.validate_error);

                }

            });

        });

    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/abstract/issue-pending-article">
                            
                        All Issue 
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                           Abstract Title Is:  {getabstractTitle.title}
                               
                        </Typography>
                        <Box component="form" onSubmit={abstractAdminArticleIssue} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={5}>
                                
                                     
                                <Grid item xs={12} sx={{ mt: 4, }}>
                                    <TextField
                                        autoComplete="true"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Issue Title"
                                        autoFocus
                                        name='title' onChange={handleInputIssue} value={issuePost.title} 
                                        helperText={errorlist.title}/>
                                </Grid>
                               
                                <Grid item xs={6} >
                                    <input
                                        name='article_id'
                                        value={aproveArticleEdit.id}
                                        onChange={handleInput}
                                        type="hidden"
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <input
                                        name='writer_id'
                                        value={aproveArticleEdit.writer_id}
                                        onChange={handleInput}
                                        type="hidden"
                                    />
                                    
                                </Grid>
                                    <input
                                       type="hidden"
                                        name='abstractArticle'
                                         onChange={handleInput} value={aproveArticleEdit.title} />

                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="true"
                                        required
                                        fullWidth
                                        id="description"
                                        label="Issue Details"
                                        autoFocus
                                        name='description'
                                        multiline
                                        rows={6} onChange={handleInputIssue} value={issuePost.description} 
                                        helperText={errorlist.description}/>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                  disabled={disabled}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5,  backgroundColor: '#145348',}} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>

    );
}

export default AddIssuePending;
