import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import swal from "sweetalert";
import ListItemIcon from '@mui/material/ListItemIcon';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import QrScanReader from './QrCode/QrScanReader';

const approve = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}>
    <FactCheckIcon />
  </Box>
);
const pending = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}
  >
    <SwipeLeftIcon />
  </Box>
);
const reject = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}
  >
    <SwipeLeftIcon />
  </Box>
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))


const ExhibitionDashbord = () => {
  const abstract_url = localStorage.getItem('abstract_url');

  const [abstractArticle, setAbstractArticle] = React.useState([]);
  const eventid = localStorage.getItem('eventid');
  const role = localStorage.getItem('role');
  const eventName = localStorage.getItem('eventname');
  React.useEffect(() => {
    axios.get(`api/abstractApprovleArticle?id=${eventid}`).then(res => {
      if (res.status === 200) {
        const abstractArticleData = res.data.totalcount;
        setAbstractArticle(abstractArticleData)
      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);
  const exhibitionURL = window.location.origin + ('/#/exhibition/guest/') + eventid;
  const [exhibitionURLQrCode, setExhibitionURLQrCode] = useState(exhibitionURL);

  const downloadExhibitionQRCode = () => {
    const qrCodeURL = document.getElementById('exhibitionqrCodeEl')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "Exhibition_QrCode.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }
  const qrRef = useRef('');
  const [webcamResult, setWebcamResult] = useState('')
  const webcamError = (error) => {
    if (error) {
      console.log(error);
    }
  };
  const webcameScan = (result) => {
    if (result) {
      setWebcamResult(result)
    }
  }



  return (

    <Box sx={{ width: '100%' }}>
      <Item sx={{ mt: 8 }}><h2>Exhibition Dashboard</h2></Item>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={5}>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }} >
            <CardContent>
              <Typography variant="h5" component="div">
                Total Exhibitors
              </Typography>
              <Typography variant="body2" variant="h2" color="success.main" key={1}>
                {/* {approve}. {abstractArticle.totalApprovearticle} */}
                {approve}. 123
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link}
                to={`/admin/exhibition/exhibitor`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Total Booths
              </Typography>
              <Typography variant="body2" variant="h2" color="secondary" key={2}>
                {/* {pending}. {abstractArticle.totalpending} */}
                {pending}. 321
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/admin/exhibition/booth`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Categories
              </Typography>
              <Typography variant="body2" variant="h2" color="error.main" key={3}>
                {/* {reject}. {abstractArticle.totalreject} */}
                {reject}.  654
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to={`/admin/exhibition/boothcategory`}>Learn More</Button>
            </CardActions>
          </Card>
        </Grid>


        <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '7%' }}>
          <h4 sx={{ m: 2, color: '#000000' }}>
          </h4>
          <Card sx={{ width: '60vw', float: 'center' }} >

          </Card>
        </Box>

      </Grid>

      <Grid item xs={4}
        style={{ color: "purple" }}>
        {role === "Author" ? "" :
          <div>
            <QrScanReader /> 
            </div>}
      </Grid>
      <hr></hr>

      {/* <Grid item xs={6} style={{
        color: "purple",//backgroundColor: "green",marginTop: "2%"
      }}>
        {
          role === "Author" ? ""
            : <> <Item>{exhibitionURL}
              <div>
                <h1>Exhibition QRCode</h1>
                <QRCode id="exhibitionqrCodeEl" size={150} value={exhibitionURLQrCode} />
                <div style={{ marginTop: "2em" }}>
                  <input
                    value={exhibitionURLQrCode}
                    style={{ width: "300px" }}
                    onChange={(e) => { setExhibitionURLQrCode(e.target.value) }}
                    disabled hidden />
                  <Button sx={{ marginTop: '2%', ":hover": { color: "white" } }}
                    color='success'
                    size='small'
                    variant='contained'
                    value="Download"
                    value="Download"
                    onClick={downloadExhibitionQRCode}>
                    Download
                  </Button>
                </div>
              </div>
            </Item> <br /></>
        }
      </Grid> */}
    </Box>

  );
}

export default ExhibitionDashbord;
