import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavbarForFront from '../frontend/NavbarForFront';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';

import EmsNavbar from "../frontend/EmsNavbar";

const theme = createTheme();

function Register() {
  const history =useHistory();
  const[registerInput,setregister] = useState({

     name:"",
     email:"",
     password:"",
     error_list:[],
  }); 


  const handleInput = (e)=>{
     e.persist();
     setregister({...registerInput,[e.target.name]:e.target.value});
  } 
  const registerSubmit =(e)=>{
e.preventDefault();

const data ={
 name: registerInput.name,
 email:registerInput.email,
 password:registerInput.password,
 
}
axios.get('/sanctum/csrf-cookie').then(response => {

axios.post(`/api/register`,data).then( res=> {

 if(res.data.status ===200){

        localStorage.setItem('auth_token',res.data.token);
        localStorage.setItem('auth_name',res.data.username);
        swal('Success', res.data.message,'success');
        history.push('/login');
 }
 else{

     setregister({...registerInput,error_list: res.data.validate_error})

 }

});


});

  }
  return (
   <>
<EmsNavbar/>
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={registerSubmit} noValidate  sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleInput} value={registerInput.name}
                  helperText={registerInput.error_list.name}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleInput} value={registerInput.email}
                  helperText={registerInput.error_list.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleInput} value={registerInput.password}
                  helperText={registerInput.error_list.password}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2" style={{color:'#1976d2'}}>
                  Already have an account? Sign in
                </Link>
               
              </Grid>
            </Grid>
          </Box>
        </Box>
       
      </Container>
    </ThemeProvider>
    </>
  )
}

export default Register
