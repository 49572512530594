import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CardActions from '@mui/material/CardActions';
import SpeedIcon from '@mui/icons-material/Speed';
import { Padding } from "@mui/icons-material";
const approve = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}>
        <SpeedIcon />
    </Box>
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>

    );
}
function createData(
    ids,
    title,
    date


) {
    return { ids, title, date };
}
const rows = [
    createData(1, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const ShowSingleAbstractSummary = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const eventid = localStorage.getItem('eventid')
    const abstractId = props.match.params.id;
    const history = useHistory();
    const [getabstractTitle, setGetabstractTitle] = React.useState('');
    const [getabstractTotal, setGetabstractTotal] = React.useState('');
    useEffect(() => {
        axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
            if (res.status === 200) {
                const getAbstTitle = res.data.abstractTitle;
                const getAbstTotal = res.data;
                setGetabstractTitle(getAbstTitle)
                setGetabstractTotal(getAbstTotal)
            }
            else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        });

    }, []);
    console.log("getabstractTitle" ,getabstractTitle)
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box sx={{ width: '100%' }}>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center" sx={{ mt: 10, }}>
                <Grid item xs={3}>
                    <h1> Abstract Title: {getabstractTitle.title}</h1>
                    <hr></hr>
                </Grid>


            </Grid>


            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={5}>
                <Grid item xs={10} style={{

                    color: "purple",
                    //backgroundColor: "green",
                    marginTop: "2%"
                }}>

                    <Box sx={{ color: '#000000', }} style={{ marginLeft: '2%', }}>

                        <Card sx={{ width: '80vw', float: 'center' }} >

                            <CardContent >
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                   
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <h3> Abstract Summary: {getabstractTitle.description}</h3>
                                    </Grid>
                                </Grid>
                                
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                   
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <h3>   Introduction Background: {getabstractTitle.introduction_background}</h3>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                   
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <h3>  Materials And Methods: {getabstractTitle.materials_methods}</h3>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                 
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <h3>  Result: {getabstractTitle.results}</h3>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        Review Status
                                        {(() => {
                                            if (getabstractTitle.review_status === 1) {
                                                return (

                                                    <Badge badgeContent={'Approve'} color="success" style={{ paddingRight: 35 }}>
                                                    </Badge>

                                                )
                                            }
                                            else if (getabstractTitle.review_status === 2) {
                                                return (
                                                    <> <Badge badgeContent={'Pending'} color="primary" style={{ paddingRight: 35 }}>
                                                    </Badge>
                                                    </>
                                                )
                                            }
                                            else {
                                                return (
                                                    <> <Badge badgeContent={'Reject'} color="secondary" style={{ paddingRight: 35 }}>
                                                    </Badge>
                                                    </>
                                                )
                                            }

                                        })
                                            ()}
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                  
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <h3>  Result: {getabstractTitle.results}</h3>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        {getabstractTitle.attachment ?
                                            <a href={`${getabstractTitle.base_url}/abstract/${getabstractTitle.attachment}`}
                                                download={`${getabstractTitle.base_url}/abstract/${getabstractTitle.attachment}`}
                                                target="_blank" rel="noreferrer">Download Attachment</a> : <Button>No Attachment</Button>}
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                   
                                    justifyContent="center" >
                                    <Grid item xs={10} >
                                        <img src={`${getabstractTitle.base_url}/abstract/${getabstractTitle.image}`} width="500" height="400" />
                                    </Grid>
                                </Grid>
                                <hr />
                                


                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={6} style={{
                    color: "purple",
                    //backgroundColor: "green",
                    marginTop: "2%"
                }}>

                </Grid>
            </Grid>
        </Box>
    );
}

export default ShowSingleAbstractSummary;

