import React, { useState } from "react";
import  {Link, useHistory} from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import {Button, TextField}  from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NavbarForFront from '../frontend/NavbarForFront';
import "../App.css"

const MasterLogin = () => {
    const history = useHistory();
    const [loginInput ,setLogin]= useState({
      email:"",
      password:"" ,
      error_list:[],  
    });
  
    const handleInput =(e)=>{
      e.persist();
      setLogin({...loginInput,[e.target.name]:e.target.value});
    }
  
    const loginSubmit = (e)=> {
  
         e.preventDefault();
  
         const data = {
            email:loginInput.email,
            password:loginInput.password,
         }
         axios.get('/sanctum/csrf-cookie').then(response => {
  
         axios.post(`api/superadminroute`,data).then(res =>{
         if(res.data.status === 200){
           
             localStorage.setItem('auth_token',res.data.token);
             localStorage.setItem('auth_name',res.data.username);
             localStorage.setItem('role',res.data.role);
             swal('Success',res.data.message,'success');
             
             history.push('/master/dashboard');
             
          }else if(res.data.status === 401){
              swal('Warning',res.data.message,'warning');
          }else{
              setLogin({...loginInput, error_list: res.data.validate_error});
          }
            
         });
      });
    }
  
  return (
    <><NavbarForFront/>
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Master Sign in
          </Typography>
          <Box component="form" onSubmit={loginSubmit}  noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={handleInput} value={loginInput.email} 
              autoFocus
              helperText={loginInput.error_list.email}
            />
           
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={handleInput} value={loginInput.password} 
              helperText={loginInput.error_list.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="#" variant="body2" style={{color:'#1976d2'}}>
                 Forgot password? 
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register" variant="body2" style={{color:'#1976d2'}}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
      </Container>
      </>
  );
}

export default MasterLogin;
