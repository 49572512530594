import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
const AddBooth = () => {
    const history = useHistory();
    const eventid = localStorage.getItem('eventid');
    const [disabled, setdisabled] = useState(false);
    const [exhibitionAddBooth, setExhibitionAddBooth] = useState({
        title: "",
        size: "",
        descriptions: "",
        error_list: [],
    });
    const [boothCategory, setBoothCategory] = React.useState('');
    const handleType = (event) => {
        setBoothCategory(event.target.value);
    };

    const [exhibitionCategory, setExhibitionCategory] = useState([]);
    useEffect(() => {
        axios.get(`api/index-exhibition-booth-category?id=${eventid}`).then(res => {
            if (res.status == 200) {
                const exhibitionBCate = res.data.exBoothCategory;
                setExhibitionCategory(exhibitionBCate)
            }

        });


    }, []);


    const [boothstatus, setBoothstatus] = React.useState('');
    const handleBooth = (event) => {
        setBoothstatus(event.target.value);
    };

    const handleInput = (e) => {
        e.persist();
        setExhibitionAddBooth({ ...exhibitionAddBooth, [e.target.name]: e.target.value });
    }


    const exhibitionBoothSubmit = (e) => {
        e.preventDefault();
        const data = {
            code: exhibitionAddBooth.code,
            type: exhibitionAddBooth.type,
            price: exhibitionAddBooth.price,
            length: exhibitionAddBooth.length,
            width: exhibitionAddBooth.width,
            exhibitor: exhibitionAddBooth.exhibitor,
            boothstatus,
            eventid,
            boothCategory,
        };
        if(data.code == undefined){
            setdisabled(false);
        }
        else if(data.type == undefined){
            setdisabled(false);
        }
        else if(data.price == undefined){
            setdisabled(false);
        }
        else if(data.length == undefined){
            setdisabled(false);
        }
        else if(data.width == undefined){
            setdisabled(false);
        }
        else if(data.exhibitor == undefined){
            setdisabled(false);
        }
        else if(data.boothstatus == ''){
            setdisabled(false);
        }
        else if(data.eventid == ''){
            setdisabled(false);
        }
        else if(data.boothCategory == ''){
            setdisabled(false);
        }
        else{
            setdisabled(true);

        }
        console.log(data)
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/store-exhibition-booth`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/exhibition/booth');

                }
                else {
                    setExhibitionAddBooth({ ...exhibitionAddBooth, error_list: res.data.validate_error })
                }
            });
        });


    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        onClick={() => history.goBack()}>
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Exhibition Booth
                        </Typography>
                        <Box component="form" onSubmit={exhibitionBoothSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">BoothCategory</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={boothCategory}
                                            onChange={handleType}
                                            label="Action" >
                                            {
                                                exhibitionCategory.map((item => {
                                                    return (
                                                        <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                                                    )

                                                }))
                                            }

                                        </Select>
                                        <FormHelperText>{exhibitionAddBooth.error_list.boothCategory} </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Booth Number"
                                        autoFocus
                                        name='code' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.code} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Type"
                                        autoFocus
                                        name='type' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.type} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Price"
                                        autoFocus
                                        name='price' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.price} />
                                </Grid>
                                <Grid item xs={12} >
                                    Dimensions
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="L"
                                        autoFocus
                                        name='length' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.length || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="W"
                                        autoFocus
                                        name='width' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.width} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Exhibitor"
                                        autoFocus
                                        name='exhibitor' onChange={handleInput}
                                        helperText={exhibitionAddBooth.error_list.exhibitor || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={boothstatus}
                                            onChange={handleBooth}
                                            label="Action" >
                                            <MenuItem value={1}>open</MenuItem>
                                            <MenuItem value={2}>Reserve</MenuItem>
                                            <MenuItem value={3}>Booked</MenuItem>
                                        </Select>
                                        <FormHelperText>{exhibitionAddBooth.error_list.boothstatus}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                               <Button
                                        disabled={disabled}
                                            type="submit"
                                            // fullWidth
                                            variant="contained"
                                            sx={{ mt: 5, backgroundColor: '#145348'}} >
                                            Submit
                                        </Button>

                                


                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default AddBooth;
