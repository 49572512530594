import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";  
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}



const IndexSubmissionType = () => {
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [permissions, setrPermissions] = useState([]);
    useEffect(() => {
      axios.get(`api/getpermissionByRole`).then(res => {
  
        if (res.status === 200) {
          const datad = res.data.data.permissions;
          setrPermissions(datad)
         
        } else {
  
        }
  
  
      })
  
    }, []);
  
    const [abstractSubmission, setAbstractSubmisssion] = React.useState([]);
    const eventid = localStorage.getItem('eventid')
    React.useEffect(() => {
      axios.get(`api/abstract-submisson-index?id=${eventid}`).then(res => {
  
        if (res.status === 200) {
          const abstractSubmissionData = res.data.submissionType;
          setAbstractSubmisssion(abstractSubmissionData)
  
        } else {
          swal('Error', "Check API Something Wrong", 'error');
  
        }
      })
  
    }, []);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = abstractSubmission.filter((row) =>
      
    (row.title &&  row.title.toLowerCase().includes(searchTerm.toLowerCase()))
       
        // Add more fields as needed
    );

    const totalRecords = abstractSubmission.length;
    const filteredRecordsCount = filteredPosts.length;
 
    //for model open'
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenForEdit] = useState(false);
  
    //for Single Record  show
    const [singleData, setSingleData] = useState({
      id: '',
      title: '',
      description: '',
     
      status: "",
      updated_at: "",
      created_by: "",
      created_at: "",
  
  
    });
  
    const handleClickShowSingleRecord = (id) => {
 
      axios.get(`api/abstract-submisson-show/${id}`).then(res => {
  
        if (res.status === 200) {
          const data = res.data;
          setSingleData(data)
  
  
        } else {
          swal('Error', "Check API Something Wrong", 'error');
        }
      })
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
   
    // Edit
    const [submissionEdit, setsubmissionEdit] = useState({
  
      title: '',
      description: '',
      event_id: "",
      status: "",
      updated_at: "",
      created_by: "",
      created_at: "",
  
    });
    const handleClickEditRecord = (id) => {
  
  
      axios.get(`api/abstract-submisson-show/${id}`).then(res => {
  
        if (res.status === 200) {
          const data = res.data;
          setsubmissionEdit(data)
  
  
        } else {
          swal('Error', "Check API Something Wrong", 'error');
        }
      })
      setOpenForEdit(true);
    };
    const handleCloseEdit = () => {
      setOpenForEdit(false);
    };
  
    const handleInput = (e) => {
      e.persist();
      setsubmissionEdit({ ...submissionEdit, [e.target.name]: e.target.value });
    }
     const categoryStatus = submissionEdit.status;
    const [status, setStatus] = React.useState('');
    // const handleChange = (e) => {
    //   setStatus(e.target.value);
    // };
    useEffect((e) => {
      setStatus(categoryStatus)
    }, [categoryStatus]);
  
    // Update
    const absUpadteCateSubmit = (e, id) => {
      e.preventDefault();
      const data =
      {
        id: submissionEdit.id,
        title: submissionEdit.title,
        description: submissionEdit.description,
        status, //this for permission
      }
      axios.post(`api/abstract-submisson-update/${data.id}`, data).then(res => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success');
          //history.push('/admin/abstract/add-category');
          history.push('/admin/abstract/submission');
          window.location.reload(false);
  
        }
        else {
  
            setsubmissionEdit({ ...submissionEdit, error_list: res.data.validate_error })
  
        }
  
      });
    }
    const submissionTypeDelete = (e, id) => {
    
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."
    
        axios.get(`api/abstract-submisson-status/${id}`).then(res => {
    
          if (res.data.status === 200) {
            //swal("Success", res.data.message, "success");
            thisClicked.closest("tr").remove();
    
          }
          else if (res.data.status === 404) {
            this.innText = "delete";
          }
        });
    
      }
  
  
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - abstractSubmission.length) : 0;
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number,
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    return (
      <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
        <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
          <h4 sx={{ m: 2, color: '#000000' }} >
  
            {
              permissions['p_abstract-article-categoy-create'] === true ?
              <Button sx={{
                float: 'right',
                marginRight: '1%',
                marginTop: '2%',
                ":hover": {
                  color: "white"
                },
                backgroundColor: '#145348',
              }}
                color='success'
                size='small'
                variant='contained'
                component={Link}
                to="/admin/abstract/add-submission">
                Add New
              </Button> : ""
            }
           
            
          </h4>
          <Card sx={{ width: '82vw', float: 'center' }} >
            <CardContent >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography component="h1" variant="h5">
                            Abstract Submission Types 
                            </Typography>
                            Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                            <Grid item xs={8} >
                                <TextField
                                    fullWidth
                                    label="Filter By: title "
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </div>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow sx={{ color: '#FFFFFF' }}>
                        <TableCell > #</TableCell>
                        <TableCell align="center"> Title</TableCell>
                        <TableCell align="right">Show to Home</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="center" style={{ width: 260 }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  
                      {(rowsPerPage > 0
                        ? filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredPosts
                      ).map((row , i) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row" style={{ width: 20 }}>
                            {i + 1}
                          </TableCell>
                          <TableCell style={{ width: 60 }} align="center">
                            {row.title}
                          </TableCell>
                          <TableCell style={{ width: 60 }} align="right">
  
                            {/* <Badge badgeContent={row.show} color="success">
                         </Badge> */}
                            {(() => {
                              if (row.status === 1) {
                                return (
                                  <> <Badge badgeContent={'Yes'} color="success">
                                  </Badge>
                                  </>
                                )
                              }
                              else {
                                return (
                                  <> <Badge badgeContent={'No'} color="secondary">
                                  </Badge>
                                  </>
                                )
                              }
  
                            })
                              ()}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {(() => {
                              if (row.status === 1) {
                                return (
                                  <> <Badge badgeContent={'Active'} color="success">
                                  </Badge>
                                  </>
                                )
                              }
                              else {
                                return (
                                  <> <Badge badgeContent={'Inactive'} color="secondary">
                                  </Badge>
                                  </>
                                )
                              }
  
                            })
                              ()}
  
  
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                          
                              <Button sx={{
                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: '#145348',
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                onClick={(e) => handleClickShowSingleRecord(row.id)}>
                                <VisibilityIcon />
                              </Button> 
                            
  
                            {permissions['p_abstract-article-categoy-edit'] === true ?
                              <Button sx={{
                                margin: 1,
  
                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: '#145348',
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                onClick={(e) => handleClickEditRecord(row.id)}>
  
                                <EditNoteIcon />
                              </Button> : ""
                            }
  
                            {permissions['p_abstract-article-categoy-delete'] === true ?
                              <Button sx={{
                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: '#145348',
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                onClick={(e) => submissionTypeDelete(e, row.id)} >
                                <DeleteIcon />
                              </Button> : ""
                            }
  
  
                           
                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={filteredPosts.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ID: {singleData.id}
            <br />
          </DialogTitle>
          <DialogTitle id="alert-dialog-title">
            Title: {singleData.title}.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
  
              Descriptions: {singleData.description}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton onClick={handleClose} >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
  
        {/* for Edit */}
  
        <Dialog
          open={openEdit}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Edit Record of ID {submissionEdit.id}
          </DialogTitle>
          <DialogTitle id="alert-dialog-title">
            Title: {submissionEdit.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please, edit the AbstractCategory  of your Requrments.
            </DialogContentText>
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              label="Add Category"
              type="text"
              fullWidth
              name='title'
              onChange={handleInput} value={submissionEdit.title}
            />
            <TextField
              margin="dense"
              id="descriptions"
              label="Add Descriptions"
              name='description'
              onChange={handleInput} value={submissionEdit.description}
              multiline
              fullWidth
              rows={6}
              required
            />
  
            <FormControl
              margin="dense"
              fullWidth>
              <InputLabel >Status</InputLabel>
              <Select
                name='status'
                label="Status"
                onChange={(e)=> setStatus(e.target.val)} value={status} >
  
                <MenuItem value={1} >Active</MenuItem>
                <MenuItem value={0}>InActive</MenuItem>
              </Select>
            </FormControl>
  
          </DialogContent>
          <DialogActions >
            <Button onClick={handleCloseEdit} color="primary">
              Cancel
            </Button>
            <Button onClick={absUpadteCateSubmit} color="primary">
              Submit
            </Button>
  
          </DialogActions>
          <DialogActions>
            {/* <IconButton onClick={handleCloseEdit} >
              <CancelIcon />
            </IconButton> */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default IndexSubmissionType;
