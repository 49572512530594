import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';

import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function createData(
  ids,
  title,
  Category,
  Issue,
  SubmitStatus,
  RewiveStatus,
  Status,


) {
  return { ids, title, Category, Issue, SubmitStatus, RewiveStatus, Status };
}



const rows = [
  createData(1, 'Frozen yoghurt', "Sample", "Issue", "New Uploaded", "Approve", "InActive",),
  createData(2, 'Ice cream sandwich', "Sample", "Issue", "New Uploaded", "Approve", "Active",),
  createData(3, 'Eclair', "Sample", "Issue", "New Uploaded", "Approve", "InActive",),
  createData(4, 'Cupcake', "Sample", "Issue", "New Uploaded", "Approve", "Active",),
  createData(5, 'Gingerbread5', "Sample", "Issue", "New Uploaded", "Approve", "Active",),
  createData(6, 'Gingerbread6', "Sample", "Issue", "New Uploaded", "Approve", "InActive",),
  createData(7, 'Gingerbread7', "Sample", "Issue", "New Uploaded", "Approve", "Active",),
  createData(8, 'Gingerbread8', "Sample", "Issue", "New Uploaded", "Approve", "InActive",),
  createData(9, 'Gingerbread9', "Sample", "Issue", "New Uploaded", "Approve", "Active",),
].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const Requirements = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [permissions, setrPermissions] = useState([]);
  useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)
       
      } else {

      }


    })

  }, []);
  const [abstractRequirements, setAbstractRequirements] = React.useState([]);
  const eventid = localStorage.getItem('eventid')
  React.useEffect(() => {
    axios.get(`api/abstractrequirement?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractCategoryData = res.data.abstractReq;
        setAbstractRequirements(abstractCategoryData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const abstracRequirementDelete = (e, id) => {

    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = " Deleting..."

    axios.get(`api/abstractrequirementStatus/${id}`).then(res => {

      if (res.data.status === 200) {
        //swal("Success", res.data.message, "success");
        thisClicked.closest("tr").remove();

      }
      else if (res.data.status === 404) {
        this.innText = "delete";
      }
    });

  }
  const [open, setOpen] = useState(false);
  const [singleData, setSingleData] = useState({
    id: '',
    title: '',
    description: '',

  });

  const handleClickShowSingleRecord = (id) => {


    axios.get(`api/abstractrequirementSingleShow/${id}`).then(res => {

      if (res.status === 200) {
        const data = res.data;
        setSingleData(data)


      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
    setOpen(true);

  };  //console.log(singleData);

  const handleClose = () => {
    setOpen(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - abstractRequirements.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
        {
                    permissions['p_abstract-article-requirements-create'] === true ?
          <Button sx={{
            float: 'right',
            marginRight: '1%',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            component={Link}
            to="/admin/requirements/add-requirements">
            Add New
          </Button> :""}
        </h4>
        <Card sx={{ width: '82vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Requirements
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left"> Status</TableCell>
                      <TableCell align="center" style={{ width: 150 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0
                      ? abstractRequirements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : abstractRequirements
                    ).map((row , i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 60 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ width: 60 }} align="left">
                          {row.title}
                        </TableCell>

                        <TableCell style={{ width: 60 }} align="left">

                          {(() => {
                            if (row.status === 1) {
                              return (
                                <> <Badge badgeContent={'Active'} color="success">
                                </Badge>
                                </>
                              )
                            }
                            else {
                              return (
                                <> <Badge badgeContent={'Inactive'} color="secondary">
                                </Badge>
                                </>
                              )
                            }

                          })
                            ()}

                        </TableCell>
                        <TableCell style={{ width: 40 }} align="right">
                          <Button sx={{
                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                            onClick={(e) => handleClickShowSingleRecord(row.id)}>
                            <VisibilityIcon />
                          </Button>
                          {
                    permissions['p_abstract-article-requirements-edit'] === true ?
                          <Button sx={{
                            margin: 1,

                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                            component={Link}
                            to={`/admin/requirements/eidt-requirements/${row.id}`}>


                            <EditNoteIcon />
                          </Button> :""}
                          {
                    permissions['p_abstract-article-requirements-delete'] === true ?
                          <Button sx={{
                            margin: 1,

                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'
                            onClick={(e) => abstracRequirementDelete(e, row.id)}>
                            <DeleteIcon />
                          </Button>:"" }
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={abstractRequirements.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Title: {singleData.title}
          <br />
        </DialogTitle>
        <p style={{ padding: 25 }}>
          Descriptions: {singleData.description}
        </p>
        <DialogTitle id="alert-dialog-title">
          Status  {(() => {
            if (singleData.status === 1) {
              return (
                <> <Badge badgeContent={'Active'} color="success" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }
            else {
              return (
                <> <Badge badgeContent={'Inactive'} color="secondary" style={{ paddingRight: 35 }}>
                </Badge>
                </>
              )
            }

          })
            ()}
        </DialogTitle>
        <DialogActions>
          <IconButton onClick={handleClose} >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Requirements;
