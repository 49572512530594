import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
function createData(name, color, capacity, fuel, price) {
    return { name, color, capacity, fuel, price };
}

const rows = [
    createData('TATA HARRIER', 'BLACK', 6, 'DIESEL', '14 LACS'),

];

const QustionsAnswer = () => {
    const [val, setVal] = useState([]);
    const history = useHistory();
    const [viewQuestion, setViewquestion] = useState([]);
    const eventid = localStorage.getItem('eventid')
    useEffect(() => {
        axios.get(`api/allEventQuestions?id=${eventid}`).then(res => {
            if (res.status === 200) {
                const allquestions = res.data.allQuestions;
                setViewquestion(allquestions);
            }
            else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        })

    }, []);


    const [question, setQuestion] = useState({
        question: "",
    });

    const handleInput = (e) => {
        e.persist();
        setQuestion({ ...question, [e.target.name]: e.target.value });
    }

    const handleAdd = () => {
        const filedAdd = [...val, []]
        setVal(filedAdd)
    }

    const handleChange = (onChangeValue, i) => {
        const inputdata = [...val]
        inputdata[i] = onChangeValue.target.value;
        setVal(inputdata)
    }
    const handleDelete = (i) => {
        const deleteVale = [...val]
        deleteVale.splice(i, 1)
        setVal(deleteVale);

    }

    const abstracEventQuestionDelete = (e, id) => {

        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."
        //swal("Success", "no permisssion", "success");
        axios.get(`api/eventquestiondelete/${id}/${eventid}`).then(res => {

            if (res.data.status === 200) {
                swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();

            }
            else if (res.data.status === 401) {
                swal("Error", res.data.message, "error");
                thisClicked.innerText = " DELETE"
            }
        });

    }

    const addScore = (e) => {
        e.preventDefault();
        const data = {
            question: question.question,
            val,
            eventid,
        }
       
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/AbstractAdminAddQuestion`, data).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/questions/answers');
                    window.location.reload(false);
                }
            });
        });
    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} onSubmit={addScore} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        },
                        
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/profile/profile">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Abstract Score
                        </Typography>
                        <Box component="form" encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Question"
                                        name='question' onChange={handleInput} value={question.question} />
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        onClick={handleAdd}
                                        variant="contained"
                                        sx={{backgroundColor: '#145348',}}
                                    >
                                        Add Options
                                    </Button>
                                </Grid>
                                <Grid container item xs={6} >
                                    {
                                        val.map((data, i) => {
                                            return (
                                                <div key={i + 1}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        required
                                                        fullWidth
                                                        value={data}
                                                        label="option"
                                                        onChange={e => handleChange(e, i)}
                                                        sx={{ mt: 3 }} />
                                                    <Button variant="contained" onClick={() => handleDelete(i)} sx={{backgroundColor: '#145348',}}>Remove Field</Button>
                                                </div>
                                            )
                                        })}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348',}} >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Questions
                            </TableCell>
                            <TableCell align="right">
                                Action
                            </TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewQuestion.map((row, i) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:last-child td, &:last-child th':
                                        { border: 0 }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {i + 1} <br></br>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.label} <br></br>
                                    {/* <h3>options</h3>
                                    {row.options.map((option, i) => (

                                        <Grid key={option.value}>
                                            <h5 >{i + 1}. {option.label}</h5>
                                        </Grid>
                                    ))} */}

                                </TableCell>
                                <TableCell align="right">
                                    {/*                                     
                                <Button sx={{
                                ":hover": {
                                  color: "white"
                                }
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                variant='contained'
                                component={Link}
                                to={`/admin/abstract/edit-question/${row.id}`}>Edit</Button>  ||  */}
                                    <Button sx={{
                                        ":hover": {
                                            color: "white"
                                        },
                                        backgroundColor: '#145348',
                                    }}
                                        color='primary'
                                        size='small'
                                        variant='contained'

                                        onClick={(e) => abstracEventQuestionDelete(e, row.id)} >Delete</Button>
                                </TableCell>



                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
}


export default QustionsAnswer;
