import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route , Redirect, useHistory } from "react-router-dom";
//ipmimport swal from "sweetalert";
import Masterlayout from "../Layouts/Admin/Masterlayout";

const AdminPrivateRoute=({...rest})=>{

    const history = useHistory();
    const [Authtenticated ,setAuthtenticated] = useState(false);
    const [loading ,setloading] = useState(true);
    {/*For Authtentication taken from route*/}
    
    useEffect(()=>{

    axios.get(`/api/checkingAuthtenticated`).then(res => {

       
        if(res.status ===200)
        {
            setAuthtenticated(true);
            setloading(false);

        }
    });
        return () =>{
            setAuthtenticated(false);
        };
    },[]);

    {/*for error handling if user not logedIn*/}
    axios.interceptors.response.use(undefined,function axiosRetryIntercetor(err){

        if(err.response.status === 401)
        {
            //swal('Unauthorized',err.response.data.message,'warning');
            history.push('/login');
        
        }
        return Promise.reject(err);
    });

    axios.interceptors.response.use( function(response){
            return response;
    }, function(error){

        if(error.response.status === 403) //Access Denied ! As You Are Not an Admin
        {
            //swal('Forbedden',error.response.data.message,'warning');
            history.push('/login');
        }else if(error.response.data === 404) //Page not found
        {
            //swal('404 Error','URl/Page NotFound','warning');
            history.push('/login');
        }
        return Promise.reject(error);
    });
    if(loading)
    {
        return <h1>Loading...</h1>
    }
 return(

    <Route {...rest}
    render={ ({props,location})=>
    Authtenticated ?
    (<Masterlayout {...props}/> ) :
     (<Redirect to={{pathname:"/",state:{from: location}}} />)
        
    }       
    
    />
 )

}
export default AdminPrivateRoute;