import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';


const AddSubmissionType = () => {
    const history = useHistory();
    const [disabled, setdisabled] = useState(false);
    const [addSubmission, setAddSubmissionType] = useState({
        title: "",
        descriptions: "",
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setAddSubmissionType({ ...addSubmission, [e.target.name]: e.target.value });
    }
    const absSubmissionSubmit = (e) => {

        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            title: addSubmission.title,
            descriptions: addSubmission.descriptions,
            eventid,

        }
        data.title == "" ? setdisabled(false) :
        data.descriptions == ""?  setdisabled(false): 
        setdisabled(false)

        axios.get('/sanctum/csrf-cookie').then(response => {

            axios.post(`/api/abstract-submisson-store`, data).then(res => {

                if (res.data.status === 200) {
                         

                    swal('Success', res.data.message, 'success');
                     //console.log(res.data.message);
                    history.push('/admin/abstract/submission');
                }
                else {

                    setAddSubmissionType({ ...addSubmission, error_list: res.data.validate_error })

                }

            });


        });


    }
    return (

        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/abstract/submission">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Submission Type
                        </Typography>
                        <Box component="form" onSubmit={absSubmissionSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Add Submission Type"
                                        autoFocus
                                        name='title' onChange={handleInput} value={addSubmission.title} 
                                        helperText={addSubmission.error_list.title}/>
                                </Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >

                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                      
                                        label="Add Descriptions"
                                        autoFocus
                                        name='descriptions' onChange={handleInput} value={addSubmission.descriptions}
                                        multiline
                                        rows={6}
                                        helperText={addSubmission.error_list.descriptions} />

                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                disabled={disabled}
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348',}} >
                                    Add Submission Type
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>

    );
}

export default AddSubmissionType;
