import React, { useState } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from '@mui/icons-material';
import _debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';





import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Grid from '@mui/material/Grid';

function createData(
    ids,
    title,
    code, type, dimensions, price, exhibitor, status,


) {
    return { ids, title, code, type, dimensions, price, exhibitor, status };
}



const rows = [
    createData(1, "ssss ipsum dolor sit amet, ", '01', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(2, "ssss ipsum dolor sit amet, ", '09', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(3, "ssss ipsum dolor sit amet, ", '07', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(4, "ssss ipsum dolor sit amet, ", '08', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(5, "ssss ipsum dolor sit amet, ", '05', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(6, "ssss ipsum dolor sit amet, ", '09', 'squir', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(7, "ssss ipsum dolor sit amet, ", '07', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(8, "ssss ipsum dolor sit amet, ", '11', 'circle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(9, "ssss ipsum dolor sit amet, ", '41', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(10, "ssss ipsum dolor sit amet, ", '51', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(11, "ssss ipsum dolor sit amet, ", '21', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(12, "ssss ipsum dolor sit amet, ", '31', 'Round', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(13, "ssss ipsum dolor sit amet, ", '41', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),


].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));


const ListCustomFiels = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [customFieldList, setCustomFieldList] = React.useState([]);
    const [permissions, setrPermissions] = useState([]);
    const eventid = localStorage.getItem('eventid')
    React.useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)
            } else {

            }
        })

    }, []);

    React.useEffect(() => {
        axios.get(`api/list-customfield?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const FieldList = res.data.customFieldList;
                setCustomFieldList(FieldList)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);


    const CustomFieldDelete = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."
        axios.get(`api/status-list-customfield/${id}`).then(res => {
            if (res.data.status === 200) {
                //swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();
            }
            else if (res.data.status === 404) {
                this.innText = "delete";
            }
        });
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filteredRows = rows.filter((row) =>
        Object.values(row).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
        )

    );
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = customFieldList.filter((row) =>
        //(row.phone && row.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.role && row.role.toLowerCase().includes(searchTerm.toLowerCase()))
        // Add more fields with null checks as needed
    );
    const totalRecords = customFieldList.length;
    const filteredRecordsCount = filteredPosts.length;

    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    {
                        permissions['p_custom-field-add'] === true ?

                            <Button sx={{
                                float: 'right',
                                marginRight: '1%',
                                marginTop: '2%',
                                ":hover": {
                                    color: "white"
                                }
                            }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/from/custom-field">
                                Add New
                            </Button> : ""}

                    <Button sx={{
                        float: 'right',
                        marginRight: '1%',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/from/inactive-custom-list">
                        InActive
                    </Button>


                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                        {/* <Typography component="h1" variant="h5">
                            List Of CustomField (Active)
                        </Typography> */}
                        <Box component="form" noValidate sx={{ mt: 3 }}>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography component="h1" variant="h5">
                                List Of CustomField (Active)
                                </Typography>
                                Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                                <Grid item xs={8} >
                                    <TextField
                                        fullWidth
                                        label="Filter By: Name"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </Grid>
                            </div>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell > #</TableCell>
                                            <TableCell align="left">Belongs To </TableCell>
                                            <TableCell align="left">Label</TableCell>
                                            <TableCell align="left">Field Name</TableCell>
                                            <TableCell align="left">Type</TableCell>
                                            <TableCell align="left">Action</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPosts && filteredPosts.slice(startIndex, endIndex).map((row, index) => (
                                            <TableRow key={index}>
                                                <>
                                                    <TableCell key={row.id}>{row.id}</TableCell>
                                                    <TableCell >{row.field_to == 1 ? "Abstract Category  " : row.field_to == 2 ? "Abstract Submit " :
                                                        row.field_to == 3 ? "Abstract Requrments " : row.field_to == 4 ? "Abstract Reviewer " :
                                                            row.field_to == 5 ? "Exhibition BoothCategory" : row.field_to == 6 ? "Exhibition Booth" :
                                                                row.field_to == 7 ? "Exhibition Exhibitor Category" : row.field_to == 8 ? "Exhibition Exhibitor" :
                                                                    row.field_to == 9 ? "Exhibition Guest" : row.field_to == 10 ? "Abstract Profile" : ""}</TableCell>
                                                    <TableCell >{row.name}</TableCell>
                                                    <TableCell >{row.field_name}</TableCell>
                                                    <TableCell >{row.type}</TableCell>
                                                    <TableCell >

                                                        {
                                                            permissions['p_custom-field-edit'] === true ?

                                                                <Button sx={{
                                                                    margin: 1,

                                                                    ":hover": {
                                                                        color: "white"
                                                                    },
                                                                    backgroundColor: '#145348',
                                                                }}
                                                                    color='primary'
                                                                    size='small'
                                                                    variant='contained'
                                                                    variant='contained'
                                                                    // onClick={(e) => handleClickEditRecord(row.id)}
                                                                    component={Link}
                                                                    to={`/admin/from/edit/custom-list/${row.id}`}>

                                                                    <EditNoteIcon />
                                                                </Button> : ""}


                                                        {
                                                            permissions['p_custom-field-delete'] === true ?
                                                                <Button sx={{
                                                                    ":hover": {
                                                                        color: "white"
                                                                    },
                                                                    backgroundColor: '#145348',
                                                                }}
                                                                    color='primary'
                                                                    size='small'
                                                                    variant='contained'
                                                                    onClick={(e) => CustomFieldDelete(e, row.id)}>

                                                                    <DeleteIcon />
                                                                </Button> : ""}
                                                    </TableCell>

                                                </>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 10000, 100000,]}
                                    component="div"
                                    count={filteredRows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>

                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default ListCustomFiels;
