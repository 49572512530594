import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';


import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FormHelperText from '@mui/material/FormHelperText';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles



const ExhibitionBadge = () => {

    const history = useHistory();
    const [exhibitionBadgeDesgin, setExhibitionBadgeDesgin] = useState({
        editorBagdeData: "",
        exhibitionBadgeImage: "",
        error_list: [],
    });
    const [exhibitionBadgeImage, setExhibitionBadgeImage] = useState("");
    const [disabled, setdisabled] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setExhibitionBadgeDesgin({ ...exhibitionBadgeDesgin, [e.target.name]: e.target.value });
    }

    //badge
    const [editorBagdeData, setEditorBagdeData] = useState('<p>Edit this text...</p>');
    const [sourceView, setSourceView] = useState(false);

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['blockquote', 'code-block'],
            ['link', 'image'],
            [{ 'align': [] }],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'link', 'image', 'align',
        'blockquote', 'code-block',
    ];

    const handleChange = (content) => {
        setEditorBagdeData(content);
    };

    const toggleSourceView = () => {

        setSourceView(!sourceView);
    };

    const editorStyle = {
        border: '1px solid #ccc',
        minHeight: '300px',
        padding: '10px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '16px',
    };

    console.log(exhibitionBadgeImage);

    const eventid = localStorage.getItem('eventid');
    const exhibitionbadgeSubmit = (e) => {
        e.preventDefault();

        const data = {
            order_number: exhibitionBadgeDesgin.order_number,
            eventid,
            exhibitionBadgeImage,
            editorBagdeData

        }





        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/store-exhibition-badge`, data, config).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    //history.push('');
                }
                else {
                    setExhibitionBadgeDesgin({ ...exhibitionBadgeDesgin, error_list: res.data.validate_error })
                }
            });
        });
        //console.clear()
        console.log(data)
    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        onClick={() => history.goBack()}>
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Exhibition Badge
                        </Typography>
                        <Box component="form" onSubmit={exhibitionbadgeSubmit} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Order Number"
                                        autoFocus
                                        name='order_number' onChange={handleInput}
                                        helperText={exhibitionBadgeDesgin.error_list.order_number} />
                                </Grid>
                                <Grid item xs={6} >
                                    <label htmlFor="icon-button-file">
                                        <TextField id="icon-button-file" type="file" onChange={(e) => setExhibitionBadgeImage(e.target.files[0])} name="[licenseFile]" />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                    <FormHelperText>{exhibitionBadgeDesgin.error_list.exhibitionBadgeImage}</FormHelperText>
                                    {
                                    exhibitionBadgeDesgin.error_list.exhibitionBadgeImage ? ""
                                        : <Typography
                                            component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                            *  {`BackGround Image`}
                                        </Typography>

                                }
                                </Grid>
                                
                                {/* <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth

                                        label="Add Descriptions"
                                        autoFocus
                                        name='descriptions' onChange={handleInput}
                                        multiline
                                        rows={6}
                                        helperText={exhibitionBadgeDesgin.error_list.descriptions} />

                                </Grid> */}
                                <Grid item xs={12} >
                                    <h2>Rich Text Editor with Source View</h2>
                                    {/* <button onClick={toggleSourceView}>Toggle Source View</button> */}
                                    <Button sx={{

                                        marginTop: '2%',
                                        ":hover": {
                                            color: "white"
                                        }
                                    }}
                                        color='success'
                                        size='small'
                                        variant='contained'
                                        onClick={toggleSourceView}>
                                        Toggle Source View
                                    </Button>
                                    {sourceView ? (
                                        <textarea
                                            value={editorBagdeData}
                                            onChange={(e) => setEditorBagdeData(e.target.value)}
                                            rows={10}
                                            style={{ width: '100%', padding: '10px' }}
                                        />
                                    ) : (
                                        <ReactQuill
                                            value={editorBagdeData}
                                            onChange={handleChange}
                                            modules={modules}
                                            formats={formats}
                                            style={editorStyle} />
                                    )}
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        sx={{ mt: 5, }} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default ExhibitionBadge;
