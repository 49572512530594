import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Navbar from './Navbar';
const Home = () => {

    return (
        <>
            <Navbar />
            <Box sx={{ color: '#000000', }} style={{ marginLeft: '39%', marginTop: '2%' }}>
                <h1 style={{ marginTop: '17%' }}>Abstract Management System</h1>
                
            </Box>
        </>
    );
}

export default Home;
