import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import swal from "sweetalert";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RestoreIcon from '@mui/icons-material/Restore';
import { Button, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const ArchiveEvent = () => {
    const history = useHistory();
    const [loading, setloading] = useState(false);
    const [alleventlist, setAlleventlist] = useState([]);

    useEffect(() => {

        axios.get(`api/superadmin-inactiveevent`).then(res => {

            if (res.status === 200) {


                setAlleventlist(res.data.allInactiveEventForSuperAdmin);


            }
            else {
                setloading(false);
            }
        })


    }, []);
    {/*superadmin-activeInactiveEvent*/ }

    const eventToArchive = (e, id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Event Restore...";

        axios.get(`api/superadmin-activeInactiveEvent/${id}`).then(res => {

            if (res.data.status === 200) {

                swal('Success', "Event Restore Successfull !!!", 'success');
                thisClicked.closest("tr").remove();
                history.push('/master/events')

            }
            else if (res.data.status === 404) {

                swal('Success', res.data.message, 'success');
                thisClicked.innerText = "Delete";
            }

        });

    }
    return (
        <div style={{ margin: '1%', }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '7%' }}>
                <h4 sx={{ m: 2, color: '#000000' }}> Archiv Events
                    {/* <Button sx={{
                        float: 'right',

                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="#">
                        Add Role
                    </Button> */}
                </h4>
                <Card sx={{ width: '80vw', float: 'center' }} >
                    <CardContent >
                        <TableContainer >
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow sx={{ color: '#FFFFFF' }}>
                                        <TableCell >#</TableCell>
                                        <TableCell > Name</TableCell>

                                        <TableCell  >Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {alleventlist.map((item, i) => (
                                        <TableRow

                                            key={item.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                            <TableCell component="th" scope="row">
                                                {item.id}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {item.eventname}
                                            </TableCell>



                                            <TableCell >

                                                <Button sx={{

                                                    marginRight: '11%',
                                                    marginTop: '2%',

                                                    ":hover": {
                                                        color: "white"
                                                    }
                                                }}
                                                    color='primary'
                                                    size='small'
                                                    variant='contained'
                                                    component={Link}
                                                    to={`/admin/dashbord/${item.id}`}>
                                                    <VisibilityIcon />
                                                </Button>

                                                <Button sx={{

                                                    marginRight: '11%',
                                                    marginTop: '2%',

                                                    ":hover": {
                                                        color: "white"
                                                    }
                                                }}
                                                    color='primary'
                                                    size='small'
                                                    variant='contained'
                                                    onClick={(e) => eventToArchive(e, item.id)}>
                                                    <RestoreIcon />
                                                </Button>
                                               

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default ArchiveEvent;
