import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const EditCustomField = (props) => {
    const id = props.match.params.id;
    const history = useHistory();


    const [editcustom, setEditcustomField] = React.useState({
        id:"",
        name: "",
        order_by: "",
        options: "",
        type: "",
        default_value: "",
        field_to: "",
        error_list: [],
         }); 
    

    useEffect(() => {
        const id = props.match.params.id;
        axios.get(`api/customfield-edit/${id}`).then(res => {
    
          if (res.status === 200) {
            const data = res.data.cfedt;
            
            setEditcustomField(data)
          } else {
            swal('Error', "Check API Something Wrong", 'error');
          }
    
        });
    
      }, [props.match.params.id]);

    const handleInput = (e) => {
        setEditcustomField({ ...editcustom, [e.target.name]: e.target.value });
 
    }
    
    const [belongTo, setNameOfField] = useState('');

    const handleNameOfField = (e) => {
        setNameOfField(e.target.value);
    }
    const [fieldType, setTag] = useState('');
    const handleNameTag = (e) => {
        setTag(e.target.value);
    }
  
    const field_to = editcustom.field_to;
    useEffect((e) => {
        setNameOfField(field_to);
    }, [field_to]);

    const type = editcustom.type;
    useEffect((e) => {
        setTag(type);
    }, [type]);
   
    
    const UpdateCustomfield = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid');
        const data = {
            eventid,
            name: editcustom.name,
            order_by: editcustom.order_by,
            type: editcustom.type,
            options: editcustom.options,
            default_value: editcustom.default_value,
            belongTo,
            fieldType,
           
          

        }
       
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/update-customfield/${id}`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/from/custom-field');
                }
                else {
                    setEditcustomField({ ...editcustom, error_list: res.data.validate_error })
                }
            });
        });
    }
  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                onClick={history.goBack}>
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">
                    Edit custom field 
                </Typography>
                <Box component="form" onSubmit={UpdateCustomfield} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Field Belongs to</InputLabel>
                                <Select
                                    value={belongTo}
                                    onChange={handleNameOfField}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Field Belongs to" >
                                    <MenuItem value={1}>Abstract Category</MenuItem>
                                    <MenuItem value={2}>Abstract Add New</MenuItem>
                                    <MenuItem value={3}>Abstract Requirements</MenuItem>
                                    <MenuItem value={4}>Abstract Reviewer </MenuItem>
                                    <MenuItem value={5}>Exhibition BoothCategory</MenuItem>
                                    <MenuItem value={6}>Exhibition Booth</MenuItem>
                                    <MenuItem value={7}>Exhibition ExhibitorCategory</MenuItem>
                                    <MenuItem value={8}>Exhibition Exhibitor</MenuItem>
                                    <MenuItem value={9}>Exhibition Guest</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Field Name"
                                autoFocus
                                name='name'
                                onChange={handleInput}  value={editcustom.name || ""}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    value={fieldType}
                                    onChange={handleNameTag}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Type" >
                                    <MenuItem value={'input'}>Input</MenuItem>
                                    <MenuItem value={'image'}>Image</MenuItem>
                                    <MenuItem value={'file'}>File</MenuItem>
                                    <MenuItem value={'number'}>Number</MenuItem>
                                    <MenuItem value={'textarea'}>Textarea</MenuItem>
                                    <MenuItem value={'select'}>Select</MenuItem>
                                    <MenuItem value={'multiSelect'}>multiSelect</MenuItem>
                                    <MenuItem value={'checkBox'}>CheckBox</MenuItem>
                                    <MenuItem value={'radioBox'}>RadioBox</MenuItem>
                                    <MenuItem value={'datetime'}>DateTime</MenuItem>
                                    <MenuItem value={'link'}>Link</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Order"
                                autoFocus
                                name='order_by' onChange={handleInput} value={editcustom.order_by || ""}/>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Default Value"
                                autoFocus
                                name='default_value' onChange={handleInput} value={editcustom.default_value || ""}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Option"
                                rows={6}
                                multiline
                                name='options' onChange={handleInput}  value={editcustom.options || ""}/>
                        </Grid>
                      
                   
                       

                        {/* <Grid item xs={6} >
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">MAIL CRYPTO</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="MAIL_CRYPTO" >
                    <MenuItem value={"SSL"}>SSL</MenuItem>
                    <MenuItem value={"TLS"}>TLS</MenuItem>
                   
                </Select>
            </FormControl>
        </Grid> */}


                    </Grid>

                    <Grid container justifyContent="flex-end">
                        <Button
                            type="submit"
                            // fullWidth
                            variant="contained"
                            sx={{ mt: 5, }} >
                            Submit
                        </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card >
    </Box >
</div >
  );
}

export default EditCustomField;
