import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Button, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NavbarForFront from '../frontend/NavbarForFront';
import "../App.css"
import EmsNavbar from "../frontend/EmsNavbar";
import { useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ResetPassword = () => {
  const [loading, setloading] = useState();
  const history = useHistory();
  const location = useLocation();
  const URL = window.location.href;
  const keyId = document.URL.split("/").slice(-1)[0];
  //localStorage.setItem('eventid', urlEventId);
  //console.log(keyId)


  const [resetPassword, setResetPassword] = useState({
    newPassword: "",
    confirmPassword: "",
    error_list: [],

  });

  const handleInput = (e) => {
    e.persist();
    setResetPassword({ ...resetPassword, [e.target.name]: e.target.value });


  }
  const [getId, setGetID] = React.useState([]);

  const [showForm, setIsShowForm] = React.useState(false);


  React.useEffect(() => {
    axios.get(`api/forgot/password/VerifyEmail?id=${keyId}`).then(res => {

      if (res.status === 200) {

        //console.log(res.data)
        const getKey = res.data;
        if (res.data.status == true) {
          setIsShowForm(true);
        }
        setGetID(getKey);


      } else {
        setIsShowForm(false);
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);
  //console.log(getId)
  //console.log(showForm)
  const conformPassword = (e) => {
    e.preventDefault();
    const data = {
      newPassword: resetPassword.newPassword,
      confirmPassword: resetPassword.confirmPassword,
      keyId,
    }

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/update/password`, data).then(res => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success');
          history.push('/login');
        }
        else if (res.data.status === 401) {
          swal('Error', res.data.message, 'error');
        }
        else if (res.data.status === 402) {
          swal('Error', res.data.message, 'error');
        }
        else if (res.data.status === 403) {
          swal('Error', res.data.message, 'error');
        }
        else if (res.data.status === 404) {
          swal('Error', res.data.message, 'error');
        }
        else if (res.data.status === 405) {
          swal('Error', res.data.message, 'error');
        }
        else {
          setResetPassword({ ...resetPassword, error_list: res.data.validate_error })
        }

      });


    });

  }

  return (
    <div>
      <><EmsNavbar />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {
            showForm === true ?
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>
                <Box component="form" onSubmit={conformPassword} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handleInput} value={resetPassword.newPassword}
                    helperText={resetPassword.error_list.newPassword} />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Conform Password"
                    type="password"
                    id="confirmPassword"
                    onChange={handleInput} value={resetPassword.confirmPassword}
                    helperText={resetPassword.error_list.confirmPassword}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>

                </Box>
              </Box> :

              <Stack sx={{ width: '100%', mt: 20 }} spacing={2}>
                <Alert severity="error">Invalid Email Adress!</Alert>

              </Stack>
          }


        </Container>
      </>
    </div>
  );
}
export default ResetPassword;
