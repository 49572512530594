import React from 'react';
import Box from '@mui/material/Box';
import Navbar from './NavBar';
// import Sidebare from './Sidebare';


import {Switch ,Route ,Redirect} from 'react-router-dom';
import Routes from '../../Routes/Routes';

const Masterlayout = ()=>{
    
    return(
        <Box sx={{ display: 'flex' }}>
              <Navbar />
             <Switch>
            {Routes.map((route ,idx)=>{
                return (
                    route.component && (
                        <Route  
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props)=>(
                            <route.component {...props}/>

                        )}
                        />
                    )
                )
            })
            }
            <Redirect from="/admin" to="/admin/events" />
          {/* <Redirect from="/admin" to="/admin/dashbord" /> */}
            
        </Switch>
        </Box>
       
    
    );
}
export default Masterlayout;