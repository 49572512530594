import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ListItemText from "@mui/material/ListItemText";

import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AttachmentIcon from '@mui/icons-material/Attachment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const names = [
    'Booth One',
    'Booth Two',
    'Booth Three',
    'Booth Four',
    'Booth Five',
    'Booth Six',
    'Booth Seven',

];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const AddNewExhibitor = () => {
    const theme = useTheme();
    const history = useHistory();
    const eventid = localStorage.getItem('eventid');
    const [assignBooth, setAssignBooth] = useState([]);
    const [assignModule, setassignModules] = useState([]);
    const [checkmodules, setCheckedmodules] = useState([]);
    const [exhibitionBooth, setExhibitionBooth] = useState([]);
    const [customFieldLoad, setCustomFieldLoad] = useState([]);
    const [exhibitorCategory, setExhibitorCategory] = useState('');
    const [exhibitiorCategory, setExhibitiorCategory] = useState([]);
    const [exhibitionBoothIndex, setExhibitionBoothIndex] = useState([]);
    const [exhibitionAddExhibitor, setExhibitionAddExhibitor] = useState({
        title: "",
        size: "",
        descriptions: "",
        error_list: [],
    });
    //custom states
    const [customFile, setCustomtFile] = useState('');
    const [customImage, setCustomtImage] = useState({});
    const [customFieldInput, setCustomFieldInput] = useState('');
    const [customFieldNumber, setCustomFieldNumber] = useState('');
    const [customFieldselectedRadio, setSelectedGender] = useState({});
    const [customFieldTextArea, setCustomFieldTextArea] = useState('');
    const [customFieldcheckbox, setCumstomFieldCheckbox] = useState({});
    const [customFieldselectedLoadDropDwon, setSelectedLoadDropDwon] = useState('');
    const [customFieldmultipleSeleteValue, setMultipleSeleteValue] = useState([]);

    React.useEffect(() => {
        axios.get(`api/index-exhibitor-category?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const exCate = res.data.exhibitorcategories;
                setExhibitiorCategory(exCate)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    React.useEffect(() => {
        axios.get(`api/index-exhibition-booth?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const exbooth = res.data.eb;
                setExhibitionBoothIndex(exbooth)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);
    console.log(exhibitorCategory)

    const handleChangeMulti = (event: SelectChangeEvent<typeof assignBooth>) => {
        const {
            target: { value },
        } = event;
        setAssignBooth(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setExhibitionBooth(

            typeof value === 'string' ? value.split(',') : value,
        );
    };
  

    const handleBooth = (event) => {
        setExhibitorCategory(event.target.value);
    };

    const handleInput = (e) => {
        e.persist();
        setExhibitionAddExhibitor({ ...exhibitionAddExhibitor, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        const eventid = localStorage.getItem('eventid')
        axios.get(`api/modules-access?id=${eventid}`).then(res => {
            if (res.status === 200) {
                setassignModules(res.data.mod);
            }
        });

    }, []);

    const handlechnage = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
            setCheckedmodules([...checkmodules, value])
        }
        else {
            setCheckedmodules(checkmodules.filter((e) => (e !== value)));
        }

    }

//custom fields 

    const belongTo = 8;

    React.useEffect(() => {
        axios.get(`api/load-customfield/${belongTo}/${eventid}`).then(res => {

            if (res.status === 200) {
                const FieldLoad = res.data.customFieldLoad;
                setCustomFieldLoad(FieldLoad)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);
 
    
    
    const handleChangeGender = (item) => (event) => {
      setSelectedGender({
        ...customFieldselectedRadio,
        [item]: event.target.value,
      });
  
    };
 
    const handleTypeCustomLoad = (item) => (event) => {
      setSelectedLoadDropDwon({ ...customFieldselectedLoadDropDwon, [item]: event.target.value, });
  
    };
  
  
   
    const customhandleChangeMulti = (item) => (event) => {
      setMultipleSeleteValue((prevValues) => ({
        ...prevValues,
        [item]: event.target.value,
      }));
    };
  
  
    const handleCheckboxChange = (id, value) => (event) => {
      setCumstomFieldCheckbox((prevValues) => ({
        ...prevValues,
        [id]: (prevValues[id] || []).concat(event.target.checked ? value : []),
      }));
    };
    
  
 
    
  
    const handleImageChange = (item) => (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        setCustomtImage((prevSources) => ({
          ...prevSources,
          [item]: e.target.result,
        }));
      };
  
      if (file) {
        reader.readAsDataURL(file);
      }
    };
   
    const handleImageChangeFile = (item) => (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        setCustomtFile((prevSources) => ({
          ...prevSources,
          [item]: e.target.result,
        }));
      };
  
      if (file) {
        reader.readAsDataURL(file);
      }
    };
  



    const exhibitionBoothSubmit = (e) => {
        e.preventDefault();

        const data = {
            companyname: exhibitionAddExhibitor.companyname,
            type: exhibitionAddExhibitor.type,
            contactname: exhibitionAddExhibitor.contactname,
            contactphone: exhibitionAddExhibitor.contactphone,
            contactemail: exhibitionAddExhibitor.contactemail,
            password: exhibitionAddExhibitor.password,
            description: exhibitionAddExhibitor.description,
            exhibitorCategory,
            eventid,
            assignBooth,
            checkmodules,
            //custom
            belongTo,
            customFieldInput,
            customFieldselectedRadio,
            customFieldselectedLoadDropDwon,
            customFieldmultipleSeleteValue,
            customFieldcheckbox,
            //checkboxValues,
            customFieldTextArea,
            customFieldNumber,
            customFile,
            customImage,

        }
        
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/orgniserAddExhibitor`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', "", 'success');
                    history.push('/admin/exhibition/allexhibitor');
                }
                else {
                    setExhibitionAddExhibitor({ ...exhibitionAddExhibitor, error_list: res.data.validate_error })
                }
            });
        });
    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        onClick={() => history.goBack()}>
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Exhibitor
                        </Typography>
                        <Box component="form" onSubmit={exhibitionBoothSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Company Name"
                                        autoFocus
                                        name='companyname' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.companyname || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Exhibitor Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={exhibitorCategory}
                                            onChange={handleBooth}
                                            label="Action" >
                                            {exhibitiorCategory.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{exhibitionAddExhibitor.error_list.exhibitorCategory || ""} </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Type"
                                        autoFocus
                                        name='type' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.type} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ m: 1, width: 400 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Booths</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={assignBooth}
                                            onChange={handleChangeMulti}
                                            input={<OutlinedInput label="Article Categoy" />}
                                            renderValue={(selected) => selected.join(" , ")}
                                            MenuProps={MenuProps}
                                        >
                                            {exhibitionBoothIndex.map((item) => (
                                                <MenuItem key={item.id} value={item.id} >
                                                    <Checkbox checked={assignBooth.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.code} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{exhibitionAddExhibitor.error_list.assignCategoye} </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} >
                                    <Typography variant="h6" sx={{ color: '#2196f3', fontWeight: 'bold' }}>Contact</Typography>
                                    <Typography variant="subtitle1" sx={{ color: '#2196f3', fontWeight: 'bold' }}>For event-related communication. Will not be shown on the floor plan.</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Name"
                                        autoFocus
                                        name='contactname' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.contactname || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Phone"
                                        autoFocus
                                        name='contactphone' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.contactphone || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Email"
                                        type="email"
                                        autoFocus
                                        name='contactemail' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.contactemail || ""} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        name='password'
                                        onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.password || ""} />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        rows={6}
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        multiline
                                        label="  Company description:"
                                        autoFocus
                                        name='description' onChange={handleInput}
                                        helperText={exhibitionAddExhibitor.error_list.description || ""} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup row>
                                        {
                                            assignModule.map((item) => {
                                                return (

                                                    <FormControlLabel required control={<Checkbox />}
                                                        label={item.module}
                                                        onChange={handlechnage}
                                                        value={item.module_id}
                                                        name="checkmodules" key={item.id} />

                                                )
                                            })
                                        }
                                    </FormGroup>
                                    <FormHelperText>{exhibitionAddExhibitor.error_list.checkmodules} </FormHelperText>
                                </Grid>
                                {
                  customFieldLoad && customFieldLoad.map((item, index) => {

                    return (
                      <Grid item xs={6} key={index + 1}>
                        {
                          item.type == 'input' ?
                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={item.name}
                              autoFocus
                              name={item.field_name}
                              onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} />
                            : ""
                              }
                        {/* helperText={item.required == "required" ? "item.name field is required" : "" } */}


                        {
                          item.type == 'radioBox' ?
                            <>
                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              {
                                item.options.map((radioOption, index) => {
                                  return (
                                    //<FormControl component="fieldset"></FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name={item.name}
                                      value={customFieldselectedRadio[item.id] || ''}
                                      onChange={handleChangeGender(item.id)}
                                      key={index + 1}>
                                      <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                    </RadioGroup>

                                  )
                                })

                              }  </> : ""}

                        {item.type == 'select' ?

                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={customFieldselectedLoadDropDwon[item.id] || ""}
                              onChange={handleTypeCustomLoad(item.id)}
                              label="Action" >
                              {item.options.map((dropDwonValue, index) => {
                                return (
                                  <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                                )

                              })
                              }
                            </Select>
                            <FormHelperText> </FormHelperText>
                          </FormControl>
                          : ""}


                        {
                          item.type == 'textarea' ?

                            <TextField
                              autoComplete="given-name"
                              fullWidth
                           
                              label={item.name}
                              autoFocus
                              multiline
                              rows={4}
                              name={item.field_name}
                              onChange={(e) => setCustomFieldTextArea({ ...customFieldTextArea, [item.id]: e.target.value })}
                            />
                            : ""
                           }
                        {
                          item.type == 'number' ?
                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              type="number"
                              inputProps={{ maxLength: 50 }}
                              label={item.name}
                              autoFocus
                              name={item.field_name}
                              onChange={(e) => setCustomFieldNumber({ ...customFieldNumber, [item.id]: e.target.value })} />
                            : ""
                           }
                        {
                          item.type == 'multiSelect' ?
                            <FormControl key={item.id} variant="outlined" sx={{ width: 395, }}>
                              <InputLabel id={`${item.id}-label`}>{item.name}</InputLabel>
                              <Select
                                label={item.id}
                                labelId={`${item.name}-label`}
                                multiple
                                value={customFieldmultipleSeleteValue[item.id] || []}
                                onChange={customhandleChangeMulti(item.id)}>
                                {item.options.map((multipleOption, index) => (
                                  <MenuItem key={index + 1} value={multipleOption.name}>{multipleOption.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            : ""
                           }
                        {
                          item.type == 'checkBox' ?
                            <>

                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                {item.options.map((multipleOption, index) => (
                                  <FormControlLabel
                                    key={index + 1}
                                    control={
                                      <Checkbox
                                        checked={(customFieldcheckbox[multipleOption.id] || []).includes(multipleOption.name)}
                                        onChange={handleCheckboxChange(multipleOption.id, multipleOption.name)}
                                      />} label={multipleOption.name} />
                                ))}
                              </FormGroup>
                            </> : ""

                           }
 
                        {
                          item.type == 'link' ?
                            <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""
                           }
                         {
                          item.type == 'image' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography>
                              </label> </> : ""

                           }
                        {
                          item.type == 'file' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChangeFile(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <AttachmentIcon />
                                </IconButton>
                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography>
                              </label> </> : ""

                        }
                      </Grid>
                    )
                  })
                }

                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348' }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default AddNewExhibitor;
