import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Formbuilder = () => {
    const [fields, setFields] = useState([]);

    const addTextField = () => {
      const newField = {
        type: 'text',
        label: 'Text Field',
      };
      setFields([...fields, newField]);
    };
  return (
    <div>
    {fields.map((field, index) => (
      <TextField key={index} label={field.label} fullWidth />
    ))}
    <Button onClick={addTextField} variant="outlined">
      Add Text Field
    </Button>
  </div>
  );
}

export default Formbuilder;
