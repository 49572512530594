import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams , Link } from 'react-router-dom';
import axios from 'axios';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))



function Dashbord() {
  const { id } = useParams();
const [loading, setloading] = useState(true);
const [eventsingle, setEventsingle] = useState([]);
//const [evenbooth, setEventbooth] = useState([]);
useEffect(() => {
  axios.get(`api/event/${id}`).then(res => {
    if (res.status === 200) {
     setEventsingle(res.data.event);
      //setEventbooth(res.data.booth);
      localStorage.setItem('eventid', res.data.eventid);
      localStorage.setItem('user_id', res.data.user_id);


    }
    else {
      setloading(false);
    }


  });

}, []);
//const Hidden = 'hidden';


function createData(
  ids,
  name,
  calories,
  fat,
  carbs,
  protein,

) {
  return { ids, name, calories, fat, carbs, protein };
}


const rows = [
  createData(1,'Frozen yoghurt', "Frozen@test.com", 123456, ),
  createData(2,'Ice cream sandwich', "cream@test.com", 123456, ),
  createData(3,'Eclair', "Eclair@test.com", 123456, ),
  createData(4,'Cupcake', "Cupcake@test.com", 123456, ),
  createData(5,'Gingerbread', "Gingerbread@test.com", 123456, )
]



  return (
    
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={15}>
        <Grid item xs={4}>
          
          <Card sx={{ minWidth: 275 }}>
          <Item>1</Item>
          <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      ID   {eventsingle.id}
        </Typography>
        <Typography variant="h5" component="div">
        {eventsingle.eventname}
         
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        object
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
        </Grid>
        <Grid item xs={4}>
        <Card sx={{ minWidth: 275 }}>
          <Item>2</Item>
          <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
        </Grid>
        <Grid item xs={4}>
        <Card sx={{ minWidth: 275 }}>
          <Item>3</Item>
          <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
        </Grid>
        {/* <Grid 
        item md={2} implementation="css" smDown component={Hidden}
         /> */}
        <Grid item xs={10}  style={{
            
            color: "purple",
            //backgroundColor: "green",
            marginTop:"2%"
          }}>
          <Item>4</Item>
          <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '7%' }}>
                    <h4 sx={{ m: 2, color: '#000000' }}> Event Management
                       
                    </h4>
                    <Card sx={{ width: '60vw', float: 'center' }} >
                        <CardContent >
                            <TableContainer >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow sx={{ color: '#FFFFFF' }}>
                                            <TableCell >#</TableCell>
                                            <TableCell > Name</TableCell>
                                            <TableCell >Email</TableCell>
                                            <TableCell  >Contact</TableCell>
                                            <TableCell  >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, i) => (
                                            <TableRow

                                                key={row.ids}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    
                                                <TableCell component="th" scope="row">
                                                    {i + 1}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell >{row.calories}</TableCell>
                                                <TableCell >{row.fat}</TableCell>
                                             
                                               
                                                <TableCell >
                                                        
                                                        <Button sx={{
                                                       
                                                        marginRight: '11%',
                                                        marginTop: '2%',
                                                        
                                                        ":hover": {
                                                            color: "white"
                                                        }
                                                    }}
                                                        color='primary'
                                                        size='small'
                                                        variant='contained'
                                                        component={Link}
                                                        to={`/admin/edituser/${row.id}`}> 
                                                        <VisibilityIcon/>
                                                    </Button>
                                                   
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashbord
