import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';


const AddUser = () => {
    const history =useHistory();
    const[adduser ,setAdduser]=useState({

        name:"",
        email:"",
        password:"",
        error_list:[],
    });
    const handleInput = (e)=>{
        e.persist();
        setAdduser({...adduser,[e.target.name]:e.target.value});
     } 
     const registerSubmit =(e)=>{
        e.preventDefault();
      
        const data ={
          name: adduser.name,
          email:adduser.email,
          password:adduser.password,
          
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
      
        axios.post(`/api/superadmin-storeorganiser`,data).then( res=> {
      
          if(res.data.status ===200){
                
            
                 swal('Success', "User Added SuccessFully !!",'success');
                 history.push('/master/user/');
          }
          else{
      
            setAdduser({...adduser,error_list: res.data.validate_error})
      
          }
         
        });
        
        
      });
      
           }
    

  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                component={Link}
                to="/master/user">
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">
                    Add User
                </Typography>
                <Box component="form" onSubmit={registerSubmit} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                name='name'onChange={handleInput} value={adduser.name}
                                helperText={adduser.error_list.name} />
                        </Grid> 

                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                autoComplete="email"
                                name='email' onChange={handleInput} value={adduser.email}
                                helperText={adduser.error_list.email} />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                name='password' onChange={handleInput} value={adduser.password}
                                helperText={adduser.error_list.password} />
                        </Grid>
                       
                      
                    </Grid>
                   
                    <Grid container justifyContent="flex-end">
                    <Button
                        type="submit"
                        // fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }} >
                        Add User
                    </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    </Box>
</div>
  );
}

export default AddUser;
