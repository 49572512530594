import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
import { FormatBold } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormLabel from '@mui/material/FormLabel';
import { InputAdornment } from '@mui/material';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const AddApproveArticle = () => {

  const theme = useTheme();
  const history = useHistory();
  const eventid = localStorage.getItem('eventid');
  const [disabled, setdisabled] = useState(false);
  const [abstractFile, setAbstractFile] = useState("");
  const [abstractImage, setAbstractImage] = useState("");
  const [selectedType, setSelectedType] = React.useState('');
  const [abstractAdminArticle, setAbstractAdminArticle] = useState({
    title: "",
    youtubelink: "",
    description: "",
    date: "",
    error_list: [],
  });
  const [abstractResults, setAbstractResults] = useState('');
  const [abstractConclusion, setAbstractConclusion] = useState('');
  const [abstractDescription, setAbstractDescription] = useState('');
  const [abstractMaterialsMethods, setMaterialsMethods] = useState('');
  const [abstractCategory, setAbstractAdminCategory] = React.useState('');
  const [adminSubmissionType, setAdminSubmissionType] = React.useState([]);
  const [adminAbstractCategory, setAdminAbstractCategory] = React.useState([]);
  const [abstractIntroductionBackground, setIntroductionBackground] = useState('');
  //customField States

  const belongTo = 2;
  const [customFile, setCustomtFile] = useState('');
  const [customImage, setCustomtImage] = useState({});
  const [customFieldInput, setCustomFieldInput] = useState('');
  const [customFieldNumber, setCustomFieldNumber] = useState('');
  const [customFieldLoad, setCustomFieldLoad] = React.useState([]);
  const [customFieldselectedRadio, setSelectedGender] = useState({});
  const [customFieldTextArea, setCustomFieldTextArea] = useState('');
  const [customFieldcheckbox, setCumstomFieldCheckbox] = useState({});
  const [customFieldmultipleSeleteValue, setMultipleSeleteValue] = React.useState([]);
  const [customFieldselectedLoadDropDwon, setSelectedLoadDropDwon] = React.useState('');


  const handleChange = (e) => {
    setAbstractAdminCategory(e.target.value);
  };

  React.useEffect(() => {
    axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractCategoryData = res.data.articleCategory;
        setAdminAbstractCategory(abstractCategoryData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const handleType = (event) => {
    setSelectedType(event.target.value);
  };


  React.useEffect(() => {
    axios.get(`api/abstract-submisson-index?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractsubmissionTypeData = res.data.submissionType;
        setAdminSubmissionType(abstractsubmissionTypeData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);


  const handleInput = (e) => {
    e.persist();
    setAbstractAdminArticle({ ...abstractAdminArticle, [e.target.name]: e.target.value });
  }

  const abstractArticleDescription = 400;
  const handleInputAbstractDescription = (event) => {
    const inputText = event.target.value;
    const words = inputText.trim().split(/\s+/).filter(Boolean);
    if (words.length <= abstractArticleDescription) {
      setAbstractDescription(inputText);
    }
  };
  const remainingArticleDescription = abstractArticleDescription - abstractDescription.trim().split(/\s+/).filter(Boolean).length;


  const IntroductionBackground = 100; // Change this to your desired word limit
  const handleInputBackground = (event) => {
    const inputTextBackground = event.target.value;
    const words = inputTextBackground.trim().split(/\s+/).filter(Boolean);
    if (words.length <= IntroductionBackground) {
      setIntroductionBackground(inputTextBackground);
    }
  };
  const remainingIntroductionBackground = IntroductionBackground - abstractIntroductionBackground.trim().split(/\s+/).filter(Boolean).length;


  const MaterialsMethods = 100; // Change this to your desired word limit
  const handleInputMaterialsMethods = (event) => {
    const inputTextMaterialsMethods = event.target.value;
    const words = inputTextMaterialsMethods.trim().split(/\s+/).filter(Boolean);
    if (words.length <= MaterialsMethods) {
      setMaterialsMethods(inputTextMaterialsMethods);
    }
  };
  const remainingMaterialsMethods = MaterialsMethods - abstractMaterialsMethods.trim().split(/\s+/).filter(Boolean).length;

  const Results = 100; // Change this to your desired word limit
  const handleInputResults = (event) => {
    const inputTextResults = event.target.value;
    const words = inputTextResults.trim().split(/\s+/).filter(Boolean);
    if (words.length <= Results) {
      setAbstractResults(inputTextResults);
    }
  };
  const remainingResults = Results - abstractResults.trim().split(/\s+/).filter(Boolean).length;

  const Conclusion = 50; // Change this to your desired word limit
  const handleInputConclusion = (event) => {
    const inputTextConclusion = event.target.value;
    const words = inputTextConclusion.trim().split(/\s+/).filter(Boolean);
    if (words.length <= Conclusion) {
      setAbstractConclusion(inputTextConclusion);
    }
  };
  const remainingConclusion = Conclusion - abstractConclusion.trim().split(/\s+/).filter(Boolean).length;

  React.useEffect(() => {
    axios.get(`api/load-customfield/${belongTo}/${eventid}`).then(res => {

      if (res.status === 200) {
        const FieldLoad = res.data.customFieldLoad;
        setCustomFieldLoad(FieldLoad)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const handleChangeGender = (item) => (event) => {
    setSelectedGender({
      ...customFieldselectedRadio,
      [item]: event.target.value,
    });

  };

  const handleTypeCustomLoad = (item) => (event) => {
    setSelectedLoadDropDwon({ ...customFieldselectedLoadDropDwon, [item]: event.target.value, });

  };

  const handleChangeMulti = (item) => (event) => {
    setMultipleSeleteValue((prevValues) => ({
      ...prevValues,
      [item]: event.target.value,
    }));
  };

  const handleCheckboxChange = (id, value) => (event) => {
    setCumstomFieldCheckbox((prevValues) => ({
      ...prevValues,
      [id]: (prevValues[id] || []).concat(event.target.checked ? value : []),
    }));
  };

  const handleImageChange = (item) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setCustomtImage((prevSources) => ({
        ...prevSources,
        [item]: e.target.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeFile = (item) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setCustomtFile((prevSources) => ({
        ...prevSources,
        [item]: e.target.result,
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const abstractAdminArticleSubmit = (e) => {
    e.preventDefault();
    const eventid = localStorage.getItem('eventid')
    const data = {
      eventid,
      abstractFile,
      selectedType,
      abstractImage,
      abstractCategory,
      results: abstractResults,
      conclusion: abstractConclusion,
      description: abstractDescription,
      title: abstractAdminArticle.title,
      youtubelink: abstractAdminArticle.youtubelink,
      materials_and_methods: abstractMaterialsMethods,
      nameofpresenter: abstractAdminArticle.nameofpresenter,
      additionalauthors: abstractAdminArticle.additionalauthors,
      introduction_background: abstractIntroductionBackground,

      //customFields

      belongTo,
      customFile,
      customImage,
      customFieldInput,
      customFieldNumber,
      customFieldcheckbox,
      customFieldTextArea,
      customFieldselectedRadio,
      customFieldselectedLoadDropDwon,
      customFieldmultipleSeleteValue,


    }

    data.title == "" ? setdisabled(false) : data.abstractCategory == "" ? setdisabled(false) :
      data.selectedType == "" ? setdisabled(false) : data.introduction_background == "" ? setdisabled(false) :
        data.introduction_background == "" ? setdisabled(false) : data.materials_and_methods == "" ? setdisabled(false) :
          data.description == "" ? setdisabled(false) : data.results == "" ? setdisabled(false) :
            data.conclusion == "" ? setdisabled(false) : setdisabled(true)
    axios.get('/sanctum/csrf-cookie').then(response => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(`/api/abstractApprovleArticleStore`, data, config).then(res => {

        if (res.data.status === 200) {


          swal('Success', res.data.message, 'success');

          history.push('/admin/abstract/pending-article');
        }
        else {

          setAbstractAdminArticle({ ...abstractAdminArticle, error_list: res.data.validate_error })

        }

      });


    });

  }
  var someDate = new Date();
  var numberOfDaysToAdd = 0;
  var date = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  var defaultValue = new Date(date).toISOString().split("T")[0];
  let backGroundButtonByEvent = "";
  if (eventid == 63) {
    backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
  }
  else {
    backGroundButtonByEvent = '#145348'; // Default background color
  }
  const [offButton, setOffButton] = useState(false); // Renamed state variable
  const [deadline, setDeadline] = useState('2024-09-02T00:00:00+03:00');
  //const [deadline, setDeadline] = useState('2024-09-01T14:28:00+03:00');

  const [deadlinePassed, setDeadlinePassed] = useState(false);

  const [showRegister, setShowRegister] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  React.useEffect(() => {
    const checkDeadline = () => {
      const currentTime = new Date().getTime();
      const deadlineTime = new Date(deadline).getTime(); // Convert deadline to timestamp

      if (currentTime >= deadlineTime) {
        setOffButton(true); // Update state with new name
        setDeadlinePassed(true);
      }
    };

    // Run check on component mount
    checkDeadline();

    // Optionally, run check every minute
    const interval = setInterval(checkDeadline, 60000);

    return () => clearInterval(interval);
  }, [deadline]);
  const isButtonDisabled = disabled || offButton;

  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            backgroundColor: backGroundButtonByEvent,
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            // component={Link}
            // to="/admin/abstract/approve-article"
            onClick={() => history.goBack()} >
            BAck
          </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            {/* <Typography component="h1" variant="h5">
              Add New Abstract
            </Typography>
            {offButton && (
                  <Typography
                    component="h1" variant="h5"
                    sx={{ mt: 1, color: 'red' }}
                  >
                    Abstract Submission Deadline Over
                  </Typography>
                )} */}
            <Typography
              component="h1"
              variant="h5"
              sx={{
                mt: 1,
                color: offButton ? 'red' : 'inherit' // Set color to red if offButton is true
              }}
            >
              {offButton
                ? 'Abstract Submission Deadline Over'
                : 'Add New Abstract'}
            </Typography>

            <Box component="form" onSubmit={abstractAdminArticleSubmit} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={5}>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Abstract Title"
                    autoFocus
                    name='title' onChange={handleInput} value={abstractAdminArticle.title}
                    helperText={abstractAdminArticle.error_list.title} />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel >Area of specialty</InputLabel>
                    <Select defaultValue=""
                      name='Category' onChange={handleChange} value={abstractCategory}
                      label="Area of specialty">
                      {
                        adminAbstractCategory.map((item => {
                          return (
                            <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                          )

                        }))
                      }
                    </Select>
                    <FormHelperText>{abstractAdminArticle.error_list.abstractCategory} </FormHelperText>
                  </FormControl>
                </Grid>


                <Grid item xs={6} >
                  <label htmlFor="icon-button-file">
                    <TextField id="icon-button-file" type="file" onChange={(e) => setAbstractImage(e.target.files[0])} name="[licenseFile]" />
                    <IconButton color="primary" aria-label="upload picture" component="span"
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  <FormHelperText>{abstractAdminArticle.error_list.abstractImage}</FormHelperText>
                </Grid>
                <Grid item xs={6} >
                  <label htmlFor="contained-button-file">
                    <TextField id="contained-button-file" multiple type="file" onChange={(e) => setAbstractFile(e.target.files[0])} />

                    <IconButton color="primary" aria-label="upload picture" component="span"
                    >
                      <AttachmentIcon />
                    </IconButton>
                  </label>

                  {
                    abstractAdminArticle.error_list.abstractFile ? <FormHelperText>{abstractAdminArticle.error_list.abstractFile}</FormHelperText>
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        <br></br>
                        Note: The abstract file must be a file of type: pdf
                      </Typography>

                  }


                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="myInput"
                    label="Youtube Video ID"
                    fullWidth
                    name='nameofpresenter'
                    name='youtubelink' onChange={handleInput} value={abstractAdminArticle.youtubelink} />

                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel >Submission Type</InputLabel>
                    <Select defaultValue=""
                      name='Submission' onChange={handleType} value={selectedType}
                      label="Area of specialty">
                      {
                        adminSubmissionType.map((item => {
                          return (
                            <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                          )

                        }))
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText>{abstractAdminArticle.error_list.selectedType}</FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="myInput"
                    label="Name Of Presenter"
                    fullWidth
                    name='nameofpresenter'
                    multiline
                    rows={2} onChange={handleInput} value={abstractAdminArticle.nameofpresenter} />
                  <Typography
                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                    Add Additional Presenter Name With ,
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="myInput"
                    label="Additional Authors"
                    fullWidth
                    name='additionalauthors'
                    multiline
                    rows={2} onChange={handleInput} value={abstractAdminArticle.additionalauthors} />
                  <Typography
                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                    Add Additional Authors Name With ,
                  </Typography>
                </Grid>

                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth

                    label="Introduction/background"
                    autoFocus
                    name='introduction_background'
                    multiline
                    rows={4} onChange={handleInputBackground} value={abstractIntroductionBackground}

                    helperText={abstractAdminArticle.error_list.introduction_background} />
                  {
                    abstractAdminArticle.error_list.introduction_background ? ""
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        *  {`Remaining Words: ${remainingIntroductionBackground}`}
                      </Typography>

                  }</Grid>

                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Materials and Methods"
                    autoFocus
                    name='materials_and_methods'
                    multiline
                    rows={4} onChange={handleInputMaterialsMethods} value={abstractMaterialsMethods}
                    helperText={abstractAdminArticle.error_list.materials_and_methods} />
                  {
                    abstractAdminArticle.error_list.materials_and_methods ? ""
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        * {`Remaining Words: ${remainingMaterialsMethods}`}
                      </Typography>

                  }
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Abstract Summary"
                    autoFocus
                    name='description'
                    multiline
                    rows={6} onChange={handleInputAbstractDescription} value={abstractDescription}
                    helperText={abstractAdminArticle.error_list.description} />
                  {
                    abstractAdminArticle.error_list.description ? ""
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        * {`Remaining Words: ${remainingArticleDescription}`}
                      </Typography>

                  }
                </Grid>

                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Results"
                    autoFocus
                    name='results'
                    multiline
                    rows={4} onChange={handleInputResults} value={abstractResults}
                    helperText={abstractAdminArticle.error_list.results} />
                  {
                    abstractAdminArticle.error_list.results ? ""
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        {`Remaining Words: ${remainingResults}`}
                      </Typography>

                  }
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Conclusion"
                    autoFocus
                    name='conclusion'
                    multiline
                    rows={4} onChange={handleInputConclusion} value={abstractConclusion}
                    helperText={abstractAdminArticle.error_list.conclusion} />
                  {
                    abstractAdminArticle.error_list.conclusion ? ""
                      : <Typography
                        component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                        * {`Remaining Words: ${remainingConclusion}`}
                      </Typography>

                  }
                </Grid>

                {
                  customFieldLoad && customFieldLoad.map((item, index) => {

                    return (
                      <Grid item xs={6} key={index + 1}>
                        {
                          item.type == 'input' ?
                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={item.name}
                              autoFocus
                              name={item.field_name}
                              onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} />
                            : ""
                        }

                        {
                          item.type == 'radioBox' ?
                            <>
                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              {
                                item.options.map((radioOption, index) => {
                                  return (
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name={item.name}
                                      value={customFieldselectedRadio[item.id] || ''}
                                      onChange={handleChangeGender(item.id)}
                                      key={index + 1}>
                                      <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                    </RadioGroup>

                                  )
                                })

                              }  </> : ""}

                        {item.type == 'select' ?

                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={customFieldselectedLoadDropDwon[item.id] || ""}
                              onChange={handleTypeCustomLoad(item.id)}
                              label="Action" >
                              {item.options.map((dropDwonValue, index) => {
                                return (
                                  <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                                )

                              })
                              }
                            </Select>
                            <FormHelperText> </FormHelperText>
                          </FormControl>
                          : ""}


                        {
                          item.type == 'textarea' ?

                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label={item.name}
                              autoFocus
                              multiline
                              rows={4}
                              name={item.field_name}
                              onChange={(e) => setCustomFieldTextArea({ ...customFieldTextArea, [item.id]: e.target.value })}
                            />
                            : ""
                        }
                        {
                          item.type == 'number' ?
                            <TextField
                              autoComplete="given-name"
                              fullWidth
                              type="number"
                              inputProps={{ maxLength: 50 }}
                              label={item.name}
                              autoFocus
                              name={item.field_name}
                              onChange={(e) => setCustomFieldNumber({ ...customFieldNumber, [item.id]: e.target.value })} />
                            : ""
                        }
                        {
                          item.type == 'multiSelect' ?
                            <FormControl key={item.id} variant="outlined" sx={{ width: 395, }}>
                              <InputLabel id={`${item.id}-label`}>{item.name}</InputLabel>
                              <Select
                                label={item.id}
                                labelId={`${item.name}-label`}
                                multiple
                                value={customFieldmultipleSeleteValue[item.id] || []}
                                onChange={handleChangeMulti(item.id)}>
                                {item.options.map((multipleOption, index) => (
                                  <MenuItem key={index + 1} value={multipleOption.name}>{multipleOption.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            : ""
                        }
                        {
                          item.type == 'checkBox' ?
                            <>

                              <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                {item.options.map((multipleOption, index) => (
                                  <FormControlLabel
                                    key={index + 1}
                                    control={
                                      <Checkbox
                                        checked={(customFieldcheckbox[multipleOption.id] || []).includes(multipleOption.name)}
                                        onChange={handleCheckboxChange(multipleOption.id, multipleOption.name)}
                                      />} label={multipleOption.name} />
                                ))}
                              </FormGroup>
                            </> : ""

                        }

                        {
                          item.type == 'link' ?
                            <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""
                        }
                        {
                          item.type == 'image' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChange(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography>
                              </label> </> : ""

                        }
                        {
                          item.type == 'file' ?
                            <>
                              <label htmlFor="icon-button-file">
                                <Typography variant="h5">{item.image}</Typography>
                                <TextField id="icon-button-file"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageChangeFile(item.id)} name="[licenseFile]" />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <AttachmentIcon />
                                </IconButton>
                                <Typography
                                  component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                  {item.name}
                                </Typography>
                              </label> </> : ""

                        }
                      </Grid>
                    )
                  })
                }
              </Grid>
              <Grid container justifyContent="flex-end">
                {/* <Button
                  disabled={disabled}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 5, backgroundColor: backGroundButtonByEvent, }} >
                  Submit Abstract
                </Button> */}
                {offButton && (
                  <Typography
                    component="h1" variant="h5"
                    sx={{ mt: 1, color: 'red' }}
                  >
                    Abstract Submission Deadline Over
                  </Typography>
                )}

                <Button
                  disabled={isButtonDisabled}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 5, backgroundColor: backGroundButtonByEvent, }} >
                  {offButton ? 'Abstract Submission Deadline Over' : 'Submit Abstract'}
                </Button>


              </Grid>
            </Box>
          </CardContent>
        </Card >
      </Box >
    </div >

  );
}

export default AddApproveArticle;
