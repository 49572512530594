import React, { useEffect } from 'react';
import axios from 'axios';
import {useHistory } from 'react-router-dom';

const PdfShow = () => {
const history = useHistory();
const hardcodedMd5Id =  document.URL.split("/").slice(-1)[0];

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(`/api/guest-badge/${hardcodedMd5Id}`, {
          responseType: 'arraybuffer', // Set the response type to arraybuffer
        });

        // Convert the array buffer to a blob
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Display the PDF using the <embed> tag
        document.getElementById('pdfViewer').innerHTML = `<embed src="${pdfUrl}" width="100%" height="782px" type="application/pdf" />`;
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  return <div id="pdfViewer">Loading Badge...</div>;

};




export default PdfShow;
