import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const NewVersionNabvar = () => {
    const history = useHistory();
    const SCFMSLOGO = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/SCFMS2024-logo.png`;
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar sx={{ background: 'linear-gradient(90deg, rgba(20, 65, 102, 1) 0%, rgba(20, 66, 103, 1) 36%, rgba(7, 25, 39, 1) 100%)' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                           
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <img  src={SCFMSLOGO}
                        alt="Logo"
                        sx={{
                            width: { xs: '150px', sm: '50px', md: '200px' }, // Responsive width
                            height: { xs: '75px', sm: '87.5px', md: '100px' }, // Responsive height
                            borderRadius: '50%',
                        }} />
                        </Typography>
                        {/* <Button onClick={() => history.push('/')} color="inherit">Home</Button> */}
                        <Button onClick={() => history.push('/author/login')} color="inherit">Login</Button>
                        <Button onClick={() => history.push('/author/register')} color="inherit">Register</Button>



                    </Toolbar>
                </AppBar>


            </Box>

        </div>
    );
}

export default NewVersionNabvar;
