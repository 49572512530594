import React, { useState, useEffect } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from '@mui/icons-material';
import _debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Grid from '@mui/material/Grid';

function createData(
    ids,
    name,
    email,
    phone,


) {
    return { ids, name, email, phone, };
}



const rows = [
    createData(1, "Ahmed ", 'Ahmed@email.com', '12345678',),
    createData(2, "khan ", 'khan@email.com', '98745632',),
    createData(3, "jhon ", 'jhon@email.com', '789654',),
    createData(4, "sam ", 'sam@email.com', '9856874',),
    createData(5, "dived ", 'dived@email.com', '145863',),
    createData(6, "jam ", 'jam@email.com', '52369874',),




].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const AllExhibitor = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [permissions, setrPermissions] = useState([]);
    useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)

            } else {

            }


        })

    }, []);
    const eventid = localStorage.getItem('eventid')
    const [exhibitorList, setExhibitors] = React.useState([]);
    React.useEffect(() => {
        axios.get(`api/exhibitorlist?id=${eventid}`).then(res => {
          if (res.status === 200) {
            const setExbitorsData = res.data.exhibitors;
            setExhibitors(setExbitorsData)
          } else {
            swal('Error', "Check API Something Wrong", 'error');
          }
        })
      }, []);
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = exhibitorList.filter((row) =>
    (row.phone && row.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.name  && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.email &&  row.email.toLowerCase().includes(searchTerm.toLowerCase())) 
        //||
        // row.title.toLowerCase().includes(searchTerm.toLowerCase())
        // Add more fields as needed
    );

    const totalRecords = exhibitorList.length;
    const filteredRecordsCount = filteredPosts.length;
    // const filteredRows = exhibitorList.filter((row) =>
    //     Object.values(row).some(
    //         (value) =>
    //             typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
    //     )

    // );
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    {
                        permissions['p_exhibition-exhibitor-create'] === true ?
                            <Button sx={{
                                float: 'right',
                                marginRight: '1%',
                                marginTop: '2%',
                                ":hover": {
                                    color: "white"
                                }
                            }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/exhibition/addexhibitor">
                                Add New
                            </Button> : ""}
                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography component="h1" variant="h5">
                            Exhibitors
                            </Typography>
                            Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                            <Grid item xs={8} >
                                <TextField
                                    fullWidth
                                    label="Filter By: Name , Email , Phone"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </div>
                      
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell > #</TableCell>
                                            <TableCell align="center"> Name</TableCell>
                                            <TableCell align="center">Email</TableCell>
                                            <TableCell align="right">Phone</TableCell>
                                            { 
                                                permissions['p_exhibition-exhibitor-edit'] === true ?
                                                    <TableCell align="center" style={{ width: 260 }}>Action</TableCell>:
                                                    permissions['p_exhibition-exhibitor-delete'] === true ?
                                                    <TableCell align="center" style={{ width: 260 }}>Action</TableCell>:
                                                    <TableCell align="center" style={{ width: 260 }}></TableCell> }

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPosts && filteredPosts.slice(startIndex, endIndex).map((row, index) => (
                                            <TableRow key={index}>
                                                <>
                                                    <TableCell key={row.id}>{index + 1}</TableCell>
                                                    <TableCell align="center">{row.name}</TableCell>
                                                    <TableCell align="center">{row.email}</TableCell>
                                                    <TableCell align="right">{row.phone}</TableCell>
                                                    <TableCell >
                                                        {
                                                            permissions['p_exhibition-exhibitor-edit'] === true ?
                                                        <Button sx={{ margin: 1,backgroundColor: '#145348', ":hover": { color: "white" } }}
                                                            color='primary'
                                                            size='small'
                                                            variant='contained'
                                                            component={Link}
                                                            to={`/admin/exhibition/edit-exhibitor/${row.id}`}
                                                            >
                                                            <EditNoteIcon />
                                                        </Button>:""}
                                                        {
                                                            permissions['p_exhibition-exhibitor-delete'] === true ?
                                                        <Button sx={{ backgroundColor: '#145348',":hover": { color: "white"}}}
                                                            color='primary'
                                                            size='small'
                                                            variant='contained'
                                                           >
                                                            <DeleteIcon />
                                                        </Button>:""}
                                                    </TableCell>


                                                </>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                                    component="div"
                                    count={filteredPosts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>

                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default AllExhibitor;
