import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EmailSettings = () => {
    const history = useHistory();
   

    const [settings, setSettings] = useState({
        MAIL_PROTOCOL: '',
        MAIL_HOST: '',
        MAIL_USERNAME: '',
        MAIL_PASSWORD: '',
        MAIL_CRYPTO: '',
        MAIL_PORT: '',
        MAIL_FROM: '',
        APPLICATION_URL: '',
        ENABLED_MAIL: "",
    });

    useEffect(() => {
        const eventid = localStorage.getItem('eventid');

        axios.get(`/api/get-email-setting/${eventid}`)
            .then(response => {
                const settingsData = response.data.settings;
                setSettings(settingsData);
            })
            .catch(error => {
                console.error('Error fetching email settings:', error);
            });
    }, []);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    };


    const StoreSmtpSetting = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid');
        const test = 123;
        const data = {
            
            eventid,
            settings,

        };

        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/email-setting`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');

                    history.push('/admin/email/setting');
                }
                // else {
                //     setAddSmtpSetting({ ...addSmtpSetting, error_list: res.data.validate_error })
                // }
            });
        });


    }

    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'

                        onClick={() => history.goBack()}>
                        BAck
                    </Button>

                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Email Settings

                            <Button sx={{ float: 'right', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/general/setting">
                                Genral Setting
                            </Button>
                            <Button sx={{ marginRight: '2%', float: 'right', ":hover": { color: "white" } }}
                                color='success'
                                size='small'
                                variant='contained'
                                component={Link}
                                to="/admin/test/email"  >
                                Test  Email
                            </Button>


                        </Typography>
                        <Box component="form" onSubmit={StoreSmtpSetting} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>


                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL PROTOCOL"
                                        autoFocus
                                        name='MAIL_PROTOCOL'
                                        value={settings.MAIL_PROTOCOL || ""}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL HOST"
                                        autoFocus
                                        placeholder="smtp.gmail.com"
                                        name='MAIL_HOST'
                                        value={settings.MAIL_HOST || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL USERNAME"
                                        placeholder="abc@gmail.com"
                                        autoFocus
                                        name='MAIL_USERNAME'
                                        value={settings.MAIL_USERNAME || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="MAIL PASSWORD"
                                        //type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        name='MAIL_PASSWORD'
                                        value={settings.MAIL_PASSWORD || ""}
                                        onChange={handleChange} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL CRYPTO"
                                        autoFocus
                                        placeholder="TLS"
                                        name='MAIL_CRYPTO'
                                        value={settings.MAIL_CRYPTO || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL PORT"
                                        autoFocus
                                        placeholder="587"
                                        name='MAIL_PORT'
                                        value={settings.MAIL_PORT || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="MAIL FROM"
                                        autoFocus
                                        placeholder="abc@gmail.com"
                                        name='MAIL_FROM'
                                        value={settings.MAIL_FROM || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="APPLICATION_URL"
                                        autoFocus
                                        name='APPLICATION_URL'
                                        value={settings.APPLICATION_URL || ""}
                                        onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                   
                                    <FormControl fullWidth>
                                        <InputLabel id="mail-protocol-label">ENABLED MAIL</InputLabel>
                                        <Select
                                            labelId="mail-protocol-label"
                                            id="mail-protocol"
                                            label="ENABLED MAIL"
                                            name="ENABLED_MAIL" // Ensure this matches the property name in your state
                                            value={settings.ENABLED_MAIL | ""}
                                            onChange={handleChange}>
                                            <MenuItem value={1}>Active</MenuItem>
                                            <MenuItem value={0}>InActive</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card >
            </Box >
        </div >

    );
}

export default EmailSettings;
