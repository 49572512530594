import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

const EditNatureBusiness = (props) => {
    const history = useHistory();

    const eventid = localStorage.getItem('eventid');

    const [natureOfBusinessEditData, setNatureOfBusinessEditData] = useState({
        title: "",
        arbic_title: "",
    });

    useEffect(() => {
        const id = props.match.params.id;
        axios.get(`api/edit-natureBusiness/${id}`).then(res => {
            if (res.status == 200) {
                const data = res.data.nbd;
                setNatureOfBusinessEditData(data)
              
            }
            else {
                console.log('Error', "Check API Something Wrong", 'error');
            }
        });

    }, [props.match.params.id]);
    const handleInput = (e) => {
        setNatureOfBusinessEditData({ ...natureOfBusinessEditData, [e.target.name]: e.target.value });
    }
    const updatenatureOfBusiness = (e) => {
        e.preventDefault();

        const eventid = localStorage.getItem('eventid');
        const data = {
            title: natureOfBusinessEditData.title,
            arbic_title: natureOfBusinessEditData.arbic_title,
            eventid,
        }
   
        const id = props.match.params.id
        axios.get('/sanctum/csrf-cookie').then(response => {
          const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
          axios.post(`api/update-natureBusiness/${id}`, data, config).then(res => {
            if (res.data.status === 200) {
    
              swal('Success', res.data.message, 'success');
    
              history.push('/admin/exhibition/index-nature');
            }
            else {
              swal("Title filed is Required  !!", "", "error");
            }
          });
    
        });
    }
  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                onClick={() => history.goBack()}>
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">

                    Updated The Nature Of Business

                </Typography>
                <Box component="form" onSubmit={updatenatureOfBusiness} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={4}>

                        <Grid item xs={6} >

                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Title"
                                autoFocus
                                name='title'
                                onChange={handleInput}
                                value={natureOfBusinessEditData.title || ""}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                label="Arbic Title"
                                autoFocus
                                name='arbic_title'
                                onChange={handleInput}
                                value={natureOfBusinessEditData.arbic_title || ""}
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end">
                        <Button
                            type="submit"
                            // fullWidth
                            variant="contained"
                            sx={{ mt: 5, }} >
                            Submit
                        </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    </Box>
</div>
  );
}

export default EditNatureBusiness;
