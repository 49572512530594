import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import Navbar from '../../frontend/abstact/Navbar';
const theme = createTheme();

const Register = () => {
  const history = useHistory();
  const [selectedNameTitle, setSelectedNameTitle] = React.useState('Dr');
  const [selectedeCountryCod, setSelectedeCountryCod] = React.useState('SA');
  const [disabled, setdisabled] = useState(false);

  const [registerInput, setregister] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    eventid:"",
    error_list: [],
  });

  const validatePhone = (phone) => {
    if (!phone) {
      return 'Phone number is required';
    }
    // if (!/^\d{10}$/.test(phone)) {
    //   return 'Phone number must be exactly 10 digits';
    // }
    return '';
  };

  // const handleInput = (e) => {
  //   e.persist();
  //   setregister({ ...registerInput, [e.target.name]: e.target.value });
  // }
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    let errors = { ...registerInput.error_list };
    if (name === 'phone') {
      errors.phone = validatePhone(value);
    }

    setregister({ ...registerInput, [name]: value, error_list: errors });
  };

  const handleNameTitle = (event) => {
    setSelectedNameTitle(event.target.value);
  };

  const handlCountryCode = (event) => {
    setSelectedeCountryCod(event.target.value);
  };


  const eventid = localStorage.getItem('eventid')
  const registerSubmit = (e) => {
    e.preventDefault();

    let errors = { ...registerInput.error_list };
    errors.phone = validatePhone(registerInput.phone);

    if (errors.phone) {
      setregister({
        ...registerInput,
        error_list: errors
      });
      return; // Stop the form submission if there are frontend validation errors
    }

    const data = {
      nameTitle: selectedNameTitle,
      name: registerInput.name,
      email: registerInput.email,
      password: registerInput.password,
      phone: registerInput.phone,
      countryCode: selectedeCountryCod,
      eventid,


    }
    data.nameTitle == "" ? setdisabled(false) :
    data.name == ""?  setdisabled(false): 
    data.email == ""?  setdisabled(false): 
    data.phone == ""?  setdisabled(false): 
    data.password == ""?  setdisabled(false): 
    data.countryCode == ""?  setdisabled(false): 
    data.eventid == ""?  setdisabled(false): 
    setdisabled(true)
   

    axios.get('/sanctum/csrf-cookie').then(response => {

      axios.post(`/api/abstract-register`, data).then(res => {

        if (res.data.status === 200) {

          localStorage.setItem('auth_token', res.data.token);
          localStorage.setItem('auth_name', res.data.username);
          swal('Success', res.data.message, 'success');
          history.push('/login');
        }
        else {

          setregister({ ...registerInput, error_list: res.data.validate_error })
          

        }

      });


    });

  }
  return (
    <div>
      <Navbar />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, backgroundColor: '#145348' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {/* <Typography style={{ color: 'red' }}>

              {registerInput.error_list.eventid ? '* ' + " " + " You probably came through a wrong link Please Register from the conference Website Thank you.." : ""}
            </Typography> */}

            <Box component="form" onSubmit={registerSubmit} noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="title-label">Title</InputLabel>
                    <Select
                      labelId="title-label"
                      id="title"
                      name="title"
                      value={selectedNameTitle}
                      onChange={handleNameTitle}
                      label="Title"
                    >
                      <MenuItem value="Dr">Dr</MenuItem>
                      <MenuItem value="Prof">Prof</MenuItem>
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    autoComplete="given-name"
                    name="name"
                    required
                    fullWidth
                    id="firstName"
                    label="Full Name"
                    onChange={handleInput}
                    value={registerInput.name}
                    helperText={registerInput.error_list.name}
                  />
                </Grid>


                {/* <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    name="name"
                    required
                    fullWidth
                    id="firstName"
                    label="Full Name"
                    autoFocus
                    onChange={handleInput} value={registerInput.name}
                    helperText={registerInput.error_list.name} />
                </Grid> */}

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleInput} value={registerInput.email}
                    helperText={registerInput.error_list.email} />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormControl fullWidth required>
                    <InputLabel id="title-label">Country Code</InputLabel>
                    <Select
                      labelId="title-label"
                      id="country_code"
                      name="country_code"
                      value={selectedeCountryCod}
                      onChange={handlCountryCode}
                      label="Country Code"
                    >
                      <MenuItem value="SA">Saudi Arabia (+966)</MenuItem>
                      <MenuItem value="EG">Egypt (+20)</MenuItem>
                      <MenuItem value="AE">United Arab Emirates (+971)</MenuItem>
                      <MenuItem value="BH">Bahrain (+973)</MenuItem>
                      <MenuItem value="OM">Oman (+968)</MenuItem>
                      <MenuItem value="QA">Qatar (+974)</MenuItem>
                      <MenuItem value="KW">Kuwait (+965)</MenuItem>
                      <MenuItem value="JO">Jordan (+962)</MenuItem>
                      <MenuItem value="LB">Lebanon (+961)</MenuItem>
                      <MenuItem value="SY">Syria (+963)</MenuItem>
                      <MenuItem value="IQ">Iraq (+964)</MenuItem>
                      <MenuItem value="PS">Palestine (+970)</MenuItem>
                      <MenuItem value="YE">Yemen (+967)</MenuItem>
                      <MenuItem value="IR">Iran (+98)</MenuItem>
                      <MenuItem value="TR">Turkey (+90)</MenuItem>
                      <MenuItem value="PK">Pakistan (+92)</MenuItem>
                      <MenuItem value="IN">India (+91)</MenuItem>
                      <MenuItem value="US">United States (+1)</MenuItem>
                      <MenuItem value="DE">Germany (+49)</MenuItem>
                      <MenuItem value="FR">France (+33)</MenuItem>
                      <MenuItem value="IT">Italy (+39)</MenuItem>
                      <MenuItem value="ES">Spain (+34)</MenuItem>
                      <MenuItem value="GB">United Kingdom (+44)</MenuItem>
                      <MenuItem value="NL">Netherlands (+31)</MenuItem>
                      <MenuItem value="BE">Belgium (+32)</MenuItem>
                      <MenuItem value="SE">Sweden (+46)</MenuItem>
                      <MenuItem value="CN">China (+86)</MenuItem>

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}  sm={7}>
                  <TextField
                    required
                    fullWidth
                    label="phone"
                    name="phone"
                    autoComplete="phone"
                    onChange={handleInput} value={registerInput.phone}
                    helperText={registerInput.error_list.phone} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={handleInput} value={registerInput.password}
                    helperText={registerInput.error_list.password} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#145348' }}
                disabled={disabled} >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" variant="body2" style={{ color: '#1976d2' }}>
                    Already have an account? Sign in
                  </Link>

                </Grid>
              </Grid>
            </Box>
          </Box>

        </Container>
      </ThemeProvider>
    </div>
  );
}

export default Register;
