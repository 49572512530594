import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from "axios";
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom'
import swal from "sweetalert";
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
function createData(
    ids,
    name,
    calories,
    fat,
    carbs,
    protein,

) {
    return { ids, name, calories, fat, carbs, protein };
}




const rows = [
    createData(1, 'Frozen yoghurt', "Frozen@test.com", 123456,),
    createData(2, 'Ice cream sandwich', "cream@test.com", 123456,),
    createData(3, 'Eclair', "Eclair@test.com", 123456,),
    createData(4, 'Cupcake', "Cupcake@test.com", 123456,),
    createData(5, 'Gingerbread', "Gingerbread@test.com", 123456,)
]

const AddRole = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const history = useHistory();
    const [errorlist, setError] = useState([]);
    const [loadning, setLoadning] = useState(false);
    const [allPermissions, setAllPermissions] = useState([]);

    const [addRole, setAddRole] = useState({
        role: '',

    });
    const [addPermissionToRole, setAddPermissionToRole] = useState([]);
    const handlechnage = (e) => {

        const value = e.target.value;
        const checked = e.target.checked;

        //console.log(value, checked);
        if (checked) {
            setAddPermissionToRole([...addPermissionToRole, value])

        }
        else {
            setAddPermissionToRole(addPermissionToRole.filter((e) => (e !== value)));

        }

    }
    const handleInput = (e) => {
        e.persist();
        setAddRole({ ...addRole, [e.target.name]: e.target.value })

    }

    useEffect(() => {

        //const eventid = localStorage.getItem('eventid')
        //console.log(id);
        axios.get(`api/allpermission`).then(res => {

            if (res.status === 200) {

                //console.log(res.data.permission);
                setAllPermissions(res.data.permission);
            }
            else {
                setLoadning(true)
            }
        });

    }, []);




    const submitRole = (e) => {
        e.preventDefault();
        const data = {
            role: addRole.role,
            addPermissionToRole,
        }
       
        //eventId 
        const eventid = localStorage.getItem('eventid')
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/addrole`, data).then(res => {


                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setAddRole({
                     ...addRole,
                        name: '',
                        addPermissionToRole: '',
                    })
                    history.push('/admin/role')
                }

                else {
                    swal("Field Are Requried !!", "", "error");
                    setError(res.data.error);
                    
                }

            });
        });

    }
    return (
        <div style={{ margin: '1%', width: '80vw', }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/role">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '80vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Role
                        </Typography>
                        <Box component="form"  onSubmit={submitRole} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Role"
                                        autoFocus
                                        name="role" onChange={handleInput} value={addRole.role}
                                        helperText={errorlist.role} />
                                </Grid>
                            </Grid>
                            {/* <Box sx={{ color: '#000000', }} style={{ marginTop: '7%' }}>

                                <Card sx={{ width: '80vw', float: 'center' }} >
                                    <CardContent >
                                        <TableContainer >
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow sx={{ color: '#FFFFFF' }}>
                                                        <TableCell >#</TableCell>
                                                        <TableCell style={{ width: "20%", height: 100 }}> Module Name</TableCell>

                                                        <TableCell style={{ width: "60%", height: 100 }}>Permissions</TableCell>

                                                    </TableRow>

                                                </TableHead>
                                                <TableHead>
                                                </TableHead>
                                                <TableBody>

                                                    <TableRow
                                                        
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                        <TableCell component="th" scope="row">
                                                            1
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            User Setup
                                                        </TableCell>
                                                        <TableCell >
                                                        <FormControlLabel required control={<Checkbox color="success"/>}
                                                        label="View"
                                                        color="success"
                                                        name="permissions" onChange={handlechnage} value="1" />
                                                        <FormControlLabel required control={<Checkbox />}
                                                        label="Add"
                                                        name="permissions" onChange={handlechnage} value="2"/>
                                                         <FormControlLabel required control={<Checkbox color="secondary"/>}
                                                        label="Edit"
                                                        name="permissions" onChange={handlechnage} value="3"/>
                                                          <FormControlLabel required control={<Checkbox  
                                                          sx={{
                                                            '&.Mui-checked': {
                                                              color: pink[600],
                                                            },
                                                          }}/>}
                                                        label="Delete"
                                                        name="permissions" onChange={handlechnage} value="4"/>
                                                        </TableCell>
                                                        
                                                        </TableRow>
                                                        <TableRow
                                                        key="{row.ids}"
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            
                                                        <TableCell component="th" scope="row">
                                                            2
                                                        </TableCell>
                                                             <TableCell component="th" scope="row">
                                                            Role Setup
                                                        </TableCell>
                                                        <TableCell >
                                                        <FormControlLabel required control={<Checkbox color="success"/>}
                                                        label="View"
                                                        color="success"
                                                        name="permissions" onChange={handlechnage} value="5" />
                                                        <FormControlLabel required control={<Checkbox />}
                                                        label="Add"
                                                        name="permissions" onChange={handlechnage} value="6" />
                                                          <FormControlLabel required control={<Checkbox color="secondary"/>}
                                                        label="Edit"
                                                        name="permissions" onChange={handlechnage} value="7" />
                                                          <FormControlLabel required control={<Checkbox  sx={{
                                                            
                                                            '&.Mui-checked': {
                                                              color: pink[600],
                                                            },
                                                          }}/>}
                                                        label="Delete"
                                                        name="permissions" onChange={handlechnage} value="8" />
                                                        </TableCell>
                                                       </TableRow>
                                                       <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            3
                                                        </TableCell>
                                                             <TableCell component="th" scope="row">
                                                            Abstract Category
                                                        </TableCell>
                                                        <TableCell >
                                                        <FormControlLabel required control={<Checkbox color="success"/>}
                                                        label="View"
                                                        color="success"
                                                        name="permissions" onChange={handlechnage} value="13" />
                                                        <FormControlLabel required control={<Checkbox />}
                                                        label="Add"
                                                        name="permissions" onChange={handlechnage} value="14" />
                                                          <FormControlLabel required control={<Checkbox color="secondary"/>}
                                                        label="Edit"
                                                        name="permissions" onChange={handlechnage} value="15" />
                                                          <FormControlLabel required control={<Checkbox  sx={{
                                                            
                                                            '&.Mui-checked': {
                                                              color: pink[600],
                                                            },
                                                          }}/>}
                                                        label="Delete"
                                                        name="permissions" onChange={handlechnage} value="16" />
                                                        </TableCell>
                                                        
                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                            </Box> */}
                           
                            <Grid container justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Add Role
                            </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default AddRole
