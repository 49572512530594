import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link ,useLocation} from 'react-router-dom';
import { Button, Box } from '@mui/material';

import Table from '@mui/material/Table';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';


import DeleteIcon from '@mui/icons-material/Delete';

import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';

function AllEvents() {
    const location = useLocation();
    const currentPath = location.pathname;
   
    const [loading, setloading] = useState(false);
    const [eventlist, setEventlist] = useState([]);
    const authName = localStorage.getItem('auth_name');
    useEffect(() => {
        axios.get(`api/event`).then(res => {
            if (res.status === 200) {


                setEventlist(res.data.allEvent);


            }
            else {
                setloading(false);
            }


        });

    }, []);
    
    const eventDelete = (e, id) => {
      
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."

        axios.get(`api/deleteEvent/${id}`).then(res => {

            if (res.data.status === 200) {
                //swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();

            }
            else if (res.data.status === 404) {
                this.innText = "delete";
            }
        });

    }

    const role = localStorage.getItem('role')
    var evanetDataShowInTable = '';
    if (loading) {
        return <h1>Loading Events...</h1>
    }
    else {
        evanetDataShowInTable =
            eventlist.map((item , index) => {
                return (
                    <TableRow

                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        
                    
                    <TableCell >{index +1}</TableCell>
                    <TableCell component="th" scope="row">
                    {item.eventname}
                    </TableCell>
                    <TableCell align="right">
                        
                        <Button sx={{
                            marginRight: '10px',
                            marginTop: '2%',
                            backgroundColor: '#145348' ,
                            ":hover": {
                                color: "white",

                            }
                        }}
                            color='primary'
                            size='small'
                            variant='contained'
                            component={Link}
                            to={`/admin/dashbord/${item.id}`}> 
                            <VisibilityIcon />
                        </Button>
                        {role === "superadmin" ? 
                        <Button sx={{
                           marginRight: '11%',
                           marginTop: '2%',
                           ":hover": {
                               color: "white"
                           }
                       }}
                           color='primary'
                           size='small'
                           variant='contained'
                         
                           onClick={(e) => eventDelete(e, item.id)}
                           > 
                           <DeleteIcon/>
                       </Button> : ""}
                       
                    </TableCell>
                </TableRow>
                )

            });
    }
    return (
        <div style={{  margin: '1%', }}>
            
            <Box sx={{ color: '#000000', }} style={{  marginTop: '7%' }}>
           
                <h4 sx={{ m: 2, color: '#000000' }}>Event Management 
                {role === "superadmin" ? 
                    <Button sx={{
                        float: 'right',
                      
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="#">
                        Add EventS
                    </Button> : ""}
                </h4>
                    <Card sx={{ width: '80vw', float: 'center' }} >
                        <CardContent >
                            <TableContainer >
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow sx={{ color: '#FFFFFF' }}>
                                            <TableCell >#</TableCell>
                                            <TableCell > Event Name</TableCell>
                                            <TableCell align="center" >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {evanetDataShowInTable}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {eventlist == "" ?
                            <Typography
                                component="h2" style={{ color: "#311b92", padding: 50, lineHeight: 2, }}>
                                <Badge badgeContent={'Note::'} color="secondary" >
                                </Badge> &emsp;&nbsp;
                                {" Dear "  +authName+ `, If you are not able to see the event to submit your abstract here, 
                         you probably came through a wrong link. Please contact us with 
                         your email ID or whatsapp +966565612058 , support@microartonline.com to assign you the event.`}
                            </Typography> : ""}
                        </CardContent>
                    </Card>
                </Box>

                </div>
    )
}

export default AllEvents
