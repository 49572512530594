import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import NewVersionNabvar from "../../../frontend/abstact/NewVersiion/NewVersionNabvar";
import NewVersionAbstractFooter from "./NewVersionAnstractFooter";
import { useMediaQuery, useTheme } from '@mui/material';
const theme = createTheme();
const NewVersionAbstractRegister = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const bannerImage = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/abanner-bg.jpg`;
  const gradientBackground = '#05597D';
  const history = useHistory();
  const [selectedNameTitle, setSelectedNameTitle] = React.useState('Dr');
  const [selectedeCountryCod, setSelectedeCountryCod] = React.useState('SA');
  const [disabled, setdisabled] = useState(false);

  const [registerInput, setregister] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    eventid: "",
    error_list: [],
  });

  const validatePhone = (phone) => {
    if (!phone) {
      return 'Phone number is required';
    }
    if (!/^\d+$/.test(phone)) {
      return 'Phone number must contain only numbers';
    }
    if (!/^\d{10}$/.test(phone)) {
      return 'Phone number must be exactly 10 digits';
    }
    return '';
  };

  // const handleInput = (e) => {
  //   e.persist();
  //   setregister({ ...registerInput, [e.target.name]: e.target.value });
  // }
  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    let errors = { ...registerInput.error_list };
    if (name === 'phone') {
      errors.phone = validatePhone(value);
    }

    setregister({ ...registerInput, [name]: value, error_list: errors });
  };

  const handleNameTitle = (event) => {
    setSelectedNameTitle(event.target.value);
  };

  const handlCountryCode = (event) => {
    setSelectedeCountryCod(event.target.value);
  };


  const eventid = localStorage.getItem('eventid')
  const registerSubmit = (e) => {
    e.preventDefault();

    let errors = { ...registerInput.error_list };
    errors.phone = validatePhone(registerInput.phone);

    if (errors.phone) {
      setregister({
        ...registerInput,
        error_list: errors
      });
      return; // Stop the form submission if there are frontend validation errors
    }

    const data = {
      nameTitle: selectedNameTitle,
      name: registerInput.name,
      email: registerInput.email,
      password: registerInput.password,
      phone: registerInput.phone,
      countryCode: selectedeCountryCod,
      eventid,


    }
    data.nameTitle == "" ? setdisabled(false) :
      data.name == "" ? setdisabled(false) :
        data.email == "" ? setdisabled(false) :
          data.phone == "" ? setdisabled(false) :
            data.password == "" ? setdisabled(false) :
              data.countryCode == "" ? setdisabled(false) :
                data.eventid == null ? setdisabled(false) :
                  setdisabled(true)


    axios.get('/sanctum/csrf-cookie').then(response => {

      axios.post(`/api/abstract-register`, data).then(res => {
        if (res.data.status === 200) {
          localStorage.setItem('auth_token', res.data.token);
          localStorage.setItem('auth_name', res.data.username);
          swal('Success', res.data.message, 'success');
          if (eventid) {
            history.push('/author/login');
          }
          else
            history.push('/login');
        }
        else {

          setregister({ ...registerInput, error_list: res.data.validate_error })

        }

      });


    });

  }


  const [offButton, setOffButton] = useState(false); // Renamed state variable
  const [deadline, setDeadline] = useState('2024-09-02T00:00:00+03:00');
  

  const [deadlinePassed, setDeadlinePassed] = useState(false);

  const [showRegister, setShowRegister] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  React.useEffect(() => {
    const checkDeadline = () => {
      const currentTime = new Date().getTime();
      const deadlineTime = new Date(deadline).getTime(); // Convert deadline to timestamp

      if (currentTime >= deadlineTime) {
        setOffButton(true); // Update state with new name
        setDeadlinePassed(true);
      }
    };

    // Run check on component mount
    checkDeadline();

    // Optionally, run check every minute
    const interval = setInterval(checkDeadline, 60000);

    return () => clearInterval(interval);
  }, [deadline]);
  const isButtonDisabled = disabled || offButton;


  

  return (
    <div >

      <Grid item xs={12} sm={10} md={8} lg={6}>
        <NewVersionNabvar />
      </Grid>


      <Grid container spacing={2}>
        <Box
          sx={{
            position: 'relative',
            marginTop: '10px',
            width: '100%',
            height: isSmallScreen ? '200px' : '500px', // Adjust height for small screens
            backgroundImage: `url(${bannerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: isSmallScreen ? 'center' : 'left',
            padding: isSmallScreen ? '10px' : '20px', // Adjust padding for small screens
            color: 'white',
          }}
        >
          <Typography variant={isSmallScreen ? 'h4' : 'h2'} component="div">
            Abstract
          </Typography>
        </Box>

        <Grid container justifyContent="center" marginTop="40px">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                padding: 4,
                width: '100%',
                maxWidth: isSmallScreen ? '300px' : '600px', // Initial maxWidth for smaller screens
                textAlign: 'center',
                background: gradientBackground, // Replace with your gradient background
                color: 'white', // Set the text color to white
                marginX: 'auto', // Center the box horizontally
                '@media (min-width: 960px)': {
                  maxWidth: '800px', // Increase maxWidth on larger screens if needed
                },
              }}
            >
              {/* <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              {offButton && (
                <Typography
                  component="h1" variant="h5"
                  sx={{ mt: 1 }}
                >
                  Abstract Submission Deadline Over
                </Typography>
              )} */}

              <Typography
                component="h1"
                variant="h5"
                sx={{
                  mt: 1,
                  color: offButton ? 'red' : 'inherit' // Set color to red if offButton is true
                }}
              >
                {offButton
                  ? 'Abstract Submission Deadline Over'
                  : 'Sign Up'}
              </Typography>

              <Typography component="p" variant="p" style={{ color: 'pink' }}>
                {registerInput.error_list.eventid ? '* ' + " " + " You probably came through a wrong link Please Register from the conference Website Thank you.." : ""}
              </Typography>
              {/* {registerInput.error_list.eventid && ( 
                <Typography component="p" style={{ color: 'pink' }}>
                  * You probably came through a wrong link. Please register from the conference website. Thank you.
                </Typography>
              )}  */}
              <Box component="form" onSubmit={registerSubmit} noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required>
                      <InputLabel id="title-label" sx={{
                        color: 'white !important',
                      }}>Title</InputLabel>
                      <Select
                        labelId="title-label"
                        id="title"
                        name="title"
                        value={selectedNameTitle}
                        onChange={handleNameTitle}
                        label="Title"
                        sx={{
                          color: 'white',
                          '.MuiSelect-icon': {
                            color: 'white',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSelect-select': {
                            backgroundColor: 'transparent',
                            '&:focus': {
                              backgroundColor: 'transparent',
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: 'black',
                              color: 'white',
                            },
                          },
                        }} >
                        <MenuItem value="Dr">Dr</MenuItem>
                        <MenuItem value="Prof">Prof</MenuItem>
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Mrs">Mrs</MenuItem>
                      </Select>
                      {/* <FormHelperText
                        sx={{
                          color: 'white !important',
                        }}
                      >
                        {helperText}
                      </FormHelperText> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="firstName"
                      label="Full Name"
                      onChange={handleInput}
                      value={registerInput.name}
                      helperText={registerInput.error_list.name}
                      InputLabelProps={{
                        style: { color: 'white' },
                      }}
                      FormHelperTextProps={{
                        style: { color: 'pink !important' },
                      }}
                      InputProps={{
                        style: {
                          color: 'white',
                          '&::placeholder': {
                            color: 'white',
                            opacity: 0.7,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '25px',
                          color: 'white',
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'white !important',
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'pink !important',
                          fontWeight: 'bold',
                        },
                      }} />
                  </Grid>


                  {/* <Grid item xs={12} >
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="firstName"
                      label="Full Name"
                      autoFocus
                      onChange={handleInput} value={registerInput.name}
                      helperText={registerInput.error_list.name} />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onChange={handleInput} value={registerInput.email}
                      helperText={registerInput.error_list.email}
                      InputLabelProps={{
                        style: { color: 'white' },
                      }}
                      FormHelperTextProps={{
                        style: { color: 'white' },
                      }}
                      InputProps={{
                        style: {
                          color: 'white',
                          '&::placeholder': {
                            color: 'white',
                            opacity: 0.7,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '25px',
                          color: 'white',
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'white !important',
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'pink !important',
                          fontWeight: 'bold',
                        },
                      }} />
                  </Grid>

                  {/* <Grid item xs={12} sm={5}>
                    <FormControl fullWidth required>
                      <InputLabel id="title-label" sx={{
                        color: 'white !important', 
                      }}>Country Code</InputLabel>
                      <Select
                        labelId="title-label"
                        id="country_code"
                        name="country_code"
                        value={selectedeCountryCod}
                        onChange={handlCountryCode}
                        label="Country Code"
                        sx={{
                          color: 'white',
                          '.MuiSelect-icon': {
                            color: 'white', 
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', 
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', 
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white', 
                          },
                          '.MuiSelect-select': {
                            backgroundColor: 'transparent', 
                            '&:focus': {
                              backgroundColor: 'transparent', 
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: 'black', 
                              color: 'white', 
                            },
                          },
                        }}  >
                        <MenuItem value="SA">Saudi Arabia (+966)</MenuItem>
                        <MenuItem value="EG">Egypt (+20)</MenuItem>
                        <MenuItem value="AE">United Arab Emirates (+971)</MenuItem>
                        <MenuItem value="BH">Bahrain (+973)</MenuItem>
                        <MenuItem value="OM">Oman (+968)</MenuItem>
                        <MenuItem value="QA">Qatar (+974)</MenuItem>
                        <MenuItem value="KW">Kuwait (+965)</MenuItem>
                        <MenuItem value="JO">Jordan (+962)</MenuItem>
                        <MenuItem value="LB">Lebanon (+961)</MenuItem>
                        <MenuItem value="SY">Syria (+963)</MenuItem>
                        <MenuItem value="IQ">Iraq (+964)</MenuItem>
                        <MenuItem value="PS">Palestine (+970)</MenuItem>
                        <MenuItem value="YE">Yemen (+967)</MenuItem>
                        <MenuItem value="IR">Iran (+98)</MenuItem>
                        <MenuItem value="TR">Turkey (+90)</MenuItem>
                        <MenuItem value="PK">Pakistan (+92)</MenuItem>
                        <MenuItem value="IN">India (+91)</MenuItem>
                        <MenuItem value="US">United States (+1)</MenuItem>
                        <MenuItem value="DE">Germany (+49)</MenuItem>
                        <MenuItem value="FR">France (+33)</MenuItem>
                        <MenuItem value="IT">Italy (+39)</MenuItem>
                        <MenuItem value="ES">Spain (+34)</MenuItem>
                        <MenuItem value="GB">United Kingdom (+44)</MenuItem>
                        <MenuItem value="NL">Netherlands (+31)</MenuItem>
                        <MenuItem value="BE">Belgium (+32)</MenuItem>
                        <MenuItem value="SE">Sweden (+46)</MenuItem>
                        <MenuItem value="CN">China (+86)</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={5}>
                    <FormControl fullWidth required>
                      <InputLabel id="title-label" sx={{
                        color: 'white !important',
                      }}>Country Code</InputLabel>
                      <Select
                        labelId="title-label"
                        id="country_code"
                        name="country_code"
                        value={selectedeCountryCod}
                        onChange={handlCountryCode}
                        label="Country Code"
                        sx={{
                          color: 'white',
                          '.MuiSelect-icon': {
                            color: 'white',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'white',
                          },
                          '.MuiSelect-select': {
                            backgroundColor: 'transparent',
                            '&:focus': {
                              backgroundColor: 'transparent',
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: 'black',
                              color: 'white',
                            },
                          },
                        }}
                      >
                        <MenuItem value="AF">Afghanistan (+93)</MenuItem>
                        <MenuItem value="AL">Albania (+355)</MenuItem>
                        <MenuItem value="DZ">Algeria (+213)</MenuItem>
                        <MenuItem value="AS">American Samoa (+1684)</MenuItem>
                        <MenuItem value="AD">Andorra (+376)</MenuItem>
                        <MenuItem value="AO">Angola (+244)</MenuItem>
                        <MenuItem value="AI">Anguilla (+1264)</MenuItem>
                        <MenuItem value="AG">Antigua and Barbuda (+1268)</MenuItem>
                        <MenuItem value="AR">Argentina (+54)</MenuItem>
                        <MenuItem value="AM">Armenia (+374)</MenuItem>
                        <MenuItem value="AW">Aruba (+297)</MenuItem>
                        <MenuItem value="AU">Australia (+61)</MenuItem>
                        <MenuItem value="AT">Austria (+43)</MenuItem>
                        <MenuItem value="AZ">Azerbaijan (+994)</MenuItem>
                        <MenuItem value="BS">Bahamas (+1242)</MenuItem>
                        <MenuItem value="BH">Bahrain (+973)</MenuItem>
                        <MenuItem value="BD">Bangladesh (+880)</MenuItem>
                        <MenuItem value="BB">Barbados (+1246)</MenuItem>
                        <MenuItem value="BY">Belarus (+375)</MenuItem>
                        <MenuItem value="BE">Belgium (+32)</MenuItem>
                        <MenuItem value="BZ">Belize (+501)</MenuItem>
                        <MenuItem value="BJ">Benin (+229)</MenuItem>
                        <MenuItem value="BM">Bermuda (+1441)</MenuItem>
                        <MenuItem value="BT">Bhutan (+975)</MenuItem>
                        <MenuItem value="BO">Bolivia (+591)</MenuItem>
                        <MenuItem value="BA">Bosnia and Herzegovina (+387)</MenuItem>
                        <MenuItem value="BW">Botswana (+267)</MenuItem>
                        <MenuItem value="BR">Brazil (+55)</MenuItem>
                        <MenuItem value="IO">British Indian Ocean Territory (+246)</MenuItem>
                        <MenuItem value="VG">British Virgin Islands (+1284)</MenuItem>
                        <MenuItem value="BN">Brunei (+673)</MenuItem>
                        <MenuItem value="BG">Bulgaria (+359)</MenuItem>
                        <MenuItem value="BF">Burkina Faso (+226)</MenuItem>
                        <MenuItem value="BI">Burundi (+257)</MenuItem>
                        <MenuItem value="KH">Cambodia (+855)</MenuItem>
                        <MenuItem value="CM">Cameroon (+237)</MenuItem>
                        <MenuItem value="CA">Canada (+1)</MenuItem>
                        <MenuItem value="CV">Cape Verde (+238)</MenuItem>
                        <MenuItem value="KY">Cayman Islands (+1345)</MenuItem>
                        <MenuItem value="CF">Central African Republic (+236)</MenuItem>
                        <MenuItem value="TD">Chad (+235)</MenuItem>
                        <MenuItem value="CL">Chile (+56)</MenuItem>
                        <MenuItem value="CN">China (+86)</MenuItem>
                        <MenuItem value="CO">Colombia (+57)</MenuItem>
                        <MenuItem value="KM">Comoros (+269)</MenuItem>
                        <MenuItem value="CK">Cook Islands (+682)</MenuItem>
                        <MenuItem value="CR">Costa Rica (+506)</MenuItem>
                        <MenuItem value="HR">Croatia (+385)</MenuItem>
                        <MenuItem value="CU">Cuba (+53)</MenuItem>
                        <MenuItem value="CW">Curaçao (+599)</MenuItem>
                        <MenuItem value="CY">Cyprus (+357)</MenuItem>
                        <MenuItem value="CZ">Czech Republic (+420)</MenuItem>
                        <MenuItem value="CD">Democratic Republic of the Congo (+243)</MenuItem>
                        <MenuItem value="DK">Denmark (+45)</MenuItem>
                        <MenuItem value="DJ">Djibouti (+253)</MenuItem>
                        <MenuItem value="DM">Dominica (+1767)</MenuItem>
                        <MenuItem value="DO">Dominican Republic (+1-809)</MenuItem>
                        <MenuItem value="EC">Ecuador (+593)</MenuItem>
                        <MenuItem value="EG">Egypt (+20)</MenuItem>
                        <MenuItem value="SV">El Salvador (+503)</MenuItem>
                        <MenuItem value="GQ">Equatorial Guinea (+240)</MenuItem>
                        <MenuItem value="ER">Eritrea (+291)</MenuItem>
                        <MenuItem value="EE">Estonia (+372)</MenuItem>
                        <MenuItem value="ET">Ethiopia (+251)</MenuItem>
                        <MenuItem value="FJ">Fiji (+679)</MenuItem>
                        <MenuItem value="FI">Finland (+358)</MenuItem>
                        <MenuItem value="FR">France (+33)</MenuItem>
                        <MenuItem value="GA">Gabon (+241)</MenuItem>
                        <MenuItem value="GM">Gambia (+220)</MenuItem>
                        <MenuItem value="GE">Georgia (+995)</MenuItem>
                        <MenuItem value="DE">Germany (+49)</MenuItem>
                        <MenuItem value="GH">Ghana (+233)</MenuItem>
                        <MenuItem value="GI">Gibraltar (+350)</MenuItem>
                        <MenuItem value="GR">Greece (+30)</MenuItem>
                        <MenuItem value="GL">Greenland (+299)</MenuItem>
                        <MenuItem value="GD">Grenada (+1473)</MenuItem>
                        <MenuItem value="GU">Guam (+1671)</MenuItem>
                        <MenuItem value="GT">Guatemala (+502)</MenuItem>
                        <MenuItem value="GN">Guinea (+224)</MenuItem>
                        <MenuItem value="GW">Guinea-Bissau (+245)</MenuItem>
                        <MenuItem value="GY">Guyana (+592)</MenuItem>
                        <MenuItem value="HT">Haiti (+509)</MenuItem>
                        <MenuItem value="HN">Honduras (+504)</MenuItem>
                        <MenuItem value="HK">Hong Kong (+852)</MenuItem>
                        <MenuItem value="HU">Hungary (+36)</MenuItem>
                        <MenuItem value="IS">Iceland (+354)</MenuItem>
                        <MenuItem value="IN">India (+91)</MenuItem>
                        <MenuItem value="ID">Indonesia (+62)</MenuItem>
                        <MenuItem value="IR">Iran (+98)</MenuItem>
                        <MenuItem value="IQ">Iraq (+964)</MenuItem>
                        <MenuItem value="IE">Ireland (+353)</MenuItem>
                        <MenuItem value="IL">Israel (+972)</MenuItem>
                        <MenuItem value="IT">Italy (+39)</MenuItem>
                        <MenuItem value="CI">Ivory Coast (+225)</MenuItem>
                        <MenuItem value="JM">Jamaica (+1876)</MenuItem>
                        <MenuItem value="JP">Japan (+81)</MenuItem>
                        <MenuItem value="JO">Jordan (+962)</MenuItem>
                        <MenuItem value="KZ">Kazakhstan (+7)</MenuItem>
                        <MenuItem value="KE">Kenya (+254)</MenuItem>
                        <MenuItem value="KI">Kiribati (+686)</MenuItem>
                        <MenuItem value="XK">Kosovo (+383)</MenuItem>
                        <MenuItem value="KW">Kuwait (+965)</MenuItem>
                        <MenuItem value="KG">Kyrgyzstan (+996)</MenuItem>
                        <MenuItem value="LA">Laos (+856)</MenuItem>
                        <MenuItem value="LV">Latvia (+371)</MenuItem>
                        <MenuItem value="LB">Lebanon (+961)</MenuItem>
                        <MenuItem value="LS">Lesotho (+266)</MenuItem>
                        <MenuItem value="LR">Liberia (+231)</MenuItem>
                        <MenuItem value="LY">Libya (+218)</MenuItem>
                        <MenuItem value="LI">Liechtenstein (+423)</MenuItem>
                        <MenuItem value="LT">Lithuania (+370)</MenuItem>
                        <MenuItem value="LU">Luxembourg (+352)</MenuItem>
                        <MenuItem value="MO">Macau (+853)</MenuItem>
                        <MenuItem value="MK">Macedonia (+389)</MenuItem>
                        <MenuItem value="MG">Madagascar (+261)</MenuItem>
                        <MenuItem value="MW">Malawi (+265)</MenuItem>
                        <MenuItem value="MY">Malaysia (+60)</MenuItem>
                        <MenuItem value="MV">Maldives (+960)</MenuItem>
                        <MenuItem value="ML">Mali (+223)</MenuItem>
                        <MenuItem value="MT">Malta (+356)</MenuItem>
                        <MenuItem value="MH">Marshall Islands (+692)</MenuItem>
                        <MenuItem value="MR">Mauritania (+222)</MenuItem>
                        <MenuItem value="MU">Mauritius (+230)</MenuItem>
                        <MenuItem value="YT">Mayotte (+262)</MenuItem>
                        <MenuItem value="MX">Mexico (+52)</MenuItem>
                        <MenuItem value="FM">Micronesia (+691)</MenuItem>
                        <MenuItem value="MD">Moldova (+373)</MenuItem>
                        <MenuItem value="MC">Monaco (+377)</MenuItem>
                        <MenuItem value="MN">Mongolia (+976)</MenuItem>
                        <MenuItem value="ME">Montenegro (+382)</MenuItem>
                        <MenuItem value="MS">Montserrat (+1664)</MenuItem>
                        <MenuItem value="MA">Morocco (+212)</MenuItem>
                        <MenuItem value="MZ">Mozambique (+258)</MenuItem>
                        <MenuItem value="MM">Myanmar (+95)</MenuItem>
                        <MenuItem value="NA">Namibia (+264)</MenuItem>
                        <MenuItem value="NR">Nauru (+674)</MenuItem>
                        <MenuItem value="NP">Nepal (+977)</MenuItem>
                        <MenuItem value="NL">Netherlands (+31)</MenuItem>
                        <MenuItem value="NZ">New Zealand (+64)</MenuItem>
                        <MenuItem value="NI">Nicaragua (+505)</MenuItem>
                        <MenuItem value="NE">Niger (+227)</MenuItem>
                        <MenuItem value="NG">Nigeria (+234)</MenuItem>
                        <MenuItem value="NU">Niue (+683)</MenuItem>
                        <MenuItem value="NF">Norfolk Island (+672)</MenuItem>
                        <MenuItem value="KP">North Korea (+850)</MenuItem>
                        <MenuItem value="MP">Northern Mariana Islands (+1670)</MenuItem>
                        <MenuItem value="NO">Norway (+47)</MenuItem>
                        <MenuItem value="OM">Oman (+968)</MenuItem>
                        <MenuItem value="PK">Pakistan (+92)</MenuItem>
                        <MenuItem value="PW">Palau (+680)</MenuItem>
                        <MenuItem value="PS">Palestine (+970)</MenuItem>
                        <MenuItem value="PA">Panama (+507)</MenuItem>
                        <MenuItem value="PG">Papua New Guinea (+675)</MenuItem>
                        <MenuItem value="PY">Paraguay (+595)</MenuItem>
                        <MenuItem value="PE">Peru (+51)</MenuItem>
                        <MenuItem value="PH">Philippines (+63)</MenuItem>
                        <MenuItem value="PL">Poland (+48)</MenuItem>
                        <MenuItem value="PT">Portugal (+351)</MenuItem>
                        <MenuItem value="PR">Puerto Rico (+1-787)</MenuItem>
                        <MenuItem value="QA">Qatar (+974)</MenuItem>
                        <MenuItem value="CG">Republic of the Congo (+242)</MenuItem>
                        <MenuItem value="RO">Romania (+40)</MenuItem>
                        <MenuItem value="RU">Russia (+7)</MenuItem>
                        <MenuItem value="RW">Rwanda (+250)</MenuItem>
                        <MenuItem value="BL">Saint Barthélemy (+590)</MenuItem>
                        <MenuItem value="SH">Saint Helena (+290)</MenuItem>
                        <MenuItem value="KN">Saint Kitts and Nevis (+1869)</MenuItem>
                        <MenuItem value="LC">Saint Lucia (+1758)</MenuItem>
                        <MenuItem value="MF">Saint Martin (+590)</MenuItem>
                        <MenuItem value="PM">Saint Pierre and Miquelon (+508)</MenuItem>
                        <MenuItem value="VC">Saint Vincent and the Grenadines (+1784)</MenuItem>
                        <MenuItem value="WS">Samoa (+685)</MenuItem>
                        <MenuItem value="SM">San Marino (+378)</MenuItem>
                        <MenuItem value="ST">São Tomé and Príncipe (+239)</MenuItem>
                        <MenuItem value="SA">Saudi Arabia (+966)</MenuItem>
                        <MenuItem value="SN">Senegal (+221)</MenuItem>
                        <MenuItem value="RS">Serbia (+381)</MenuItem>
                        <MenuItem value="SC">Seychelles (+248)</MenuItem>
                        <MenuItem value="SL">Sierra Leone (+232)</MenuItem>
                        <MenuItem value="SG">Singapore (+65)</MenuItem>
                        <MenuItem value="SX">Sint Maarten (+1-721)</MenuItem>
                        <MenuItem value="SK">Slovakia (+421)</MenuItem>
                        <MenuItem value="SI">Slovenia (+386)</MenuItem>
                        <MenuItem value="SB">Solomon Islands (+677)</MenuItem>
                        <MenuItem value="SO">Somalia (+252)</MenuItem>
                        <MenuItem value="ZA">South Africa (+27)</MenuItem>
                        <MenuItem value="KR">South Korea (+82)</MenuItem>
                        <MenuItem value="SS">South Sudan (+211)</MenuItem>
                        <MenuItem value="ES">Spain (+34)</MenuItem>
                        <MenuItem value="LK">Sri Lanka (+94)</MenuItem>
                        <MenuItem value="SD">Sudan (+249)</MenuItem>
                        <MenuItem value="SR">Suriname (+597)</MenuItem>
                        <MenuItem value="SZ">Swaziland (+268)</MenuItem>
                        <MenuItem value="SE">Sweden (+46)</MenuItem>
                        <MenuItem value="CH">Switzerland (+41)</MenuItem>
                        <MenuItem value="SY">Syria (+963)</MenuItem>
                        <MenuItem value="TW">Taiwan (+886)</MenuItem>
                        <MenuItem value="TJ">Tajikistan (+992)</MenuItem>
                        <MenuItem value="TZ">Tanzania (+255)</MenuItem>
                        <MenuItem value="TH">Thailand (+66)</MenuItem>
                        <MenuItem value="TL">Timor-Leste (+670)</MenuItem>
                        <MenuItem value="TG">Togo (+228)</MenuItem>
                        <MenuItem value="TK">Tokelau (+690)</MenuItem>
                        <MenuItem value="TO">Tonga (+676)</MenuItem>
                        <MenuItem value="TT">Trinidad and Tobago (+1868)</MenuItem>
                        <MenuItem value="TN">Tunisia (+216)</MenuItem>
                        <MenuItem value="TR">Turkey (+90)</MenuItem>
                        <MenuItem value="TM">Turkmenistan (+993)</MenuItem>
                        <MenuItem value="TC">Turks and Caicos Islands (+1649)</MenuItem>
                        <MenuItem value="TV">Tuvalu (+688)</MenuItem>
                        <MenuItem value="UG">Uganda (+256)</MenuItem>
                        <MenuItem value="UA">Ukraine (+380)</MenuItem>
                        <MenuItem value="AE">United Arab Emirates (+971)</MenuItem>
                        <MenuItem value="GB">United Kingdom (+44)</MenuItem>
                        <MenuItem value="US">United States (+1)</MenuItem>
                        <MenuItem value="UY">Uruguay (+598)</MenuItem>
                        <MenuItem value="UZ">Uzbekistan (+998)</MenuItem>
                        <MenuItem value="VU">Vanuatu (+678)</MenuItem>
                        <MenuItem value="VA">Vatican (+39)</MenuItem>
                        <MenuItem value="VE">Venezuela (+58)</MenuItem>
                        <MenuItem value="VN">Vietnam (+84)</MenuItem>
                        <MenuItem value="VI">Virgin Islands (+1340)</MenuItem>
                        <MenuItem value="EH">Western Sahara (+212)</MenuItem>
                        <MenuItem value="YE">Yemen (+967)</MenuItem>
                        <MenuItem value="ZM">Zambia (+260)</MenuItem>
                        <MenuItem value="ZW">Zimbabwe (+263)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <TextField
                      required
                      fullWidth
                      label="phone"
                      name="phone"
                      autoComplete="phone"
                      onChange={handleInput} value={registerInput.phone}
                      helperText={registerInput.error_list.phone}
                      InputLabelProps={{
                        style: { color: 'pink' },
                      }}
                      FormHelperTextProps={{
                        style: { color: 'pink' },

                      }}
                      InputProps={{
                        style: {
                          color: 'white',
                          '&::placeholder': {
                            color: 'white',
                            opacity: 0.7,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '25px',
                          color: 'white',
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'white !important',
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'pink !important',
                          fontWeight: 'bold',
                        },
                      }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={handleInput} value={registerInput.password}
                      helperText={registerInput.error_list.password}
                      InputLabelProps={{
                        style: { color: 'white' },
                      }}
                      FormHelperTextProps={{
                        style: { color: 'white' },
                      }}
                      InputProps={{
                        style: {
                          color: 'white',
                          '&::placeholder': {
                            color: 'white',
                            opacity: 0.7,
                          },
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'white',
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '25px',
                          color: 'white',
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'white !important',
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'white !important',
                          color: 'pink !important',
                          fontWeight: 'bold',
                        },
                      }} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox sx={{ color: 'white' }} />}
                      label={<Typography sx={{ color: 'white' }}>I want to receive inspiration, marketing promotions and updates via email.</Typography>}
                      sx={{ color: 'white !important' }} />
                  </Grid>
                </Grid>
                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#1EB5B0',
                    borderRadius: '25px',
                  }}
                  disabled={disabled} >
                  Sign Up
                </Button> */}

                {offButton && (
                  <Typography
                    component="h1" variant="h5"
                    sx={{ mt: 1, color: 'red' }}
                  >
                    Abstract Submission Deadline Over
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: offButton ? '#FF6F61' : '#1EB5B0', // Dynamic background color
                    color: 'white', // Text color
                    borderRadius: '25px',
                  }}
                  disabled={isButtonDisabled} // Updated prop
                >
                  {offButton ? 'Abstract Submission Deadline Over' : 'Sign Up'}
                </Button>



                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link to="/author/login" variant="body2" style={{ color: 'white', textDecoration: 'none' }}>
                      Already have an account? Sign in
                    </Link>

                  </Grid>
                </Grid>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={6} marginTop="40px">
        <NewVersionAbstractFooter />
      </Grid>

    </div>
  );
}

export default NewVersionAbstractRegister;
