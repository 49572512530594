import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ListItemText from "@mui/material/ListItemText";


import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AttachmentIcon from '@mui/icons-material/Attachment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ExhibitorProfile = () => {
    const theme = useTheme();
    const history = useHistory();
    const belongTo = 11;
    const eventid = localStorage.getItem('eventid');
    const [assignBooth, setAssignBooth] = useState([]);
    const [customFieldLoad, setCustomFieldLoad] = useState([]);
    const [exhibitorCate, setExhibitorCate] = React.useState([]);
    const [exhibitorDetails, setExhibitorDetails] = useState({});
    const [exhibitorCategory, setExhibitorCategory] = React.useState([]);
    const [exhibitionBoothIndex, setExhibitionBoothIndex] = React.useState([]);
    const [profile, setProfile] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        dateofbirth: "",
        address: "",
    });
    const [exhibitionAddExhibitor, setExhibitionAddExhibitor] = useState({
        title: "",
        size: "",
        descriptions: "",
        error_list: [],
    });
    //custom states
    const [customFieldData, setCumstomFields] = React.useState([]);
    const [customFile, setCustomtFile] = useState('');
    const [customImage, setCustomtImage] = useState({});
    const [customFieldInput, setCustomFieldInput] = useState('');
    const [customFieldNumber, setCustomFieldNumber] = useState('');
    const [customFieldselectedRadio, setSelectedGender] = useState({});
    const [customFieldTextArea, setCustomFieldTextArea] = useState('');
    const [customFieldcheckbox, setCumstomFieldCheckbox] = useState({});
    const [customFieldselectedLoadDropDwon, setSelectedLoadDropDwon] = useState('');
    const [customFieldmultipleSeleteValue, setMultipleSeleteValue] = useState([]);

    React.useEffect(() => {
        axios.get(`api/exhibitorprofile/${belongTo}/${eventid}`).then(res => {
            if (res.status === 200) {
                const userprofile = res.data.userProfile;
             
                setProfile(userprofile);
               
                const exhibitionBoothCategoryId = userprofile?.exhibitionDetails?.exhibition_booth_category_id || [];
                setExhibitorCategory(exhibitionBoothCategoryId);
                //setExhibitorCategory(userprofile.exhibitionDetails.exhibition_booth_category_id || []);
                setAssignBooth(userprofile.assignedBooths.map((booth) => booth.exhibition_booth_id));
                setCumstomFields(userprofile.customFieldLoad);

                // Set the initial value for "Company Name" TextField
                setProfile((prevData) => ({
                    ...prevData,
                    name: userprofile?.exhibitionDetails?.name || prevData.name,
                    companyname: userprofile?.exhibitionDetails?.company_name || prevData.companyname,
                    type: userprofile?.exhibitionDetails?.type || prevData.type,
                  }));

                // setProfile((prevData) => ({
                //     ...prevData,
                    
                //     name: userprofile.exhibitionDetails.name,
                //     companyname: userprofile.exhibitionDetails.company_name,
                //     type: userprofile.exhibitionDetails.type,
                // }));
               
                  
            }
        });
    }, []);

 
    React.useEffect(() => {
        axios.get(`api/index-exhibitor-category?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const exCate = res.data.exhibitorcategories;
                setExhibitorCate(exCate)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    React.useEffect(() => {
        axios.get(`api/index-exhibition-booth?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const exbooth = res.data.eb;
                setExhibitionBoothIndex(exbooth)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    const handleChangeMulti = (event: SelectChangeEvent<typeof assignBooth>) => {
        const {
            target: { value },
        } = event;
        setAssignBooth(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };
    //custom fields 



    React.useEffect(() => {
        axios.get(`api/load-customfield/${belongTo}/${eventid}`).then(res => {

            if (res.status === 200) {
                const FieldLoad = res.data.customFieldLoad;
                setCustomFieldLoad(FieldLoad)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);
    const handleChangeGender = (item) => (event) => {
        setSelectedGender({
            ...customFieldselectedRadio,
            [item]: event.target.value,
        });

    };

    const handleTypeCustomLoad = (item) => (event) => {
        setSelectedLoadDropDwon({ ...customFieldselectedLoadDropDwon, [item]: event.target.value, });

    };



    const customhandleChangeMulti = (item) => (event) => {
        setMultipleSeleteValue((prevValues) => ({
            ...prevValues,
            [item]: event.target.value,
        }));
    };


    const handleCheckboxChange = (id, value) => (event) => {
        setCumstomFieldCheckbox((prevValues) => ({
            ...prevValues,
            [id]: (prevValues[id] || []).concat(event.target.checked ? value : []),
        }));
    };





    const handleImageChange = (item) => (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setCustomtImage((prevSources) => ({
                ...prevSources,
                [item]: e.target.result,
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleImageChangeFile = (item) => (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setCustomtFile((prevSources) => ({
                ...prevSources,
                [item]: e.target.result,
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };


    const exhibitionExhibitorUpdateProfile = (e) => {
        e.preventDefault();

        const data = {
            companyname: profile.companyname,
            type: profile.type,
            name: profile.userProfile.name,
            phone: profile.userProfile.phone,
            email: profile.userProfile.email,
            password: profile.password,
            exhibitorCategory,
            assignBooth,
            eventid,

            //
            exhibitorDetails,

            //custom
            belongTo,
            customFieldInput,
            customFieldselectedRadio,
            customFieldselectedLoadDropDwon,
            customFieldmultipleSeleteValue,
            customFieldcheckbox,
            customFieldTextArea,
            customFieldNumber,
            customFile,
            customImage,

        }

        const id = profile.userProfile.id;
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`api/exhibitorprofileupdate/${id}`, data, config).then(res => {
                if (res.status === 200) {
                    swal('Success', res.data.message, 'success');

                    history.push('/admin/exhibition/exhibitor-profile');
                    window.location.reload(false);
                }
                else {
                    swal('Error', "api Error", 'error');
                    history.push('/admin/exhibition/exhibitor-profile');
                }
            })
        })





    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/exhibition/booth">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Update Profile
                        </Typography>
                        <Box component="form" onSubmit={exhibitionExhibitorUpdateProfile} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Company Name"
                                        autoFocus
                                        name='companyname'
                                        value={profile.companyname || ""}
                                        disabled />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Exhibitor Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={exhibitorCategory || ''} // Ensure a default value if exhibitorCategory is falsy
                                            label="Action"
                                            disabled >
                                            {Array.isArray(exhibitorCate) &&
                                                exhibitorCate.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Type"
                                        autoFocus
                                        value={profile.type || ""}
                                        name='type'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ m: 1, width: 400 }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Booths</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={assignBooth}
                                            input={<OutlinedInput label="Article Categoy" />}
                                            renderValue={(selected) => selected.map(id => exhibitionBoothIndex.find(item => item.id === id)?.code).join(", ")}
                                            MenuProps={MenuProps}
                                            disabled>
                                            {exhibitionBoothIndex.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Checkbox checked={assignBooth.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.code} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="h6" sx={{ color: '#2196f3', fontWeight: 'bold' }}>Contact</Typography>
                                    <Typography variant="subtitle1" sx={{ color: '#2196f3', fontWeight: 'bold' }}>For event-related communication. Will not be shown on the floor plan.</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Name"
                                        autoFocus
                                        value={profile.userProfile && profile.userProfile.name || ""}
                                        name='name'
                                        disabled
                                        style={{ fontWeight: 'bold', color: 'black' }} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Phone"
                                        autoFocus
                                        value={profile.userProfile && profile.userProfile.phone || ""}
                                        name='phone'
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Contact Email"
                                        type="email"
                                        value={profile.userProfile && profile.userProfile.email || ""}
                                        autoFocus
                                        name='email'
                                        disabled
                                        style={{ fontWeight: 'bold', color: 'black' }} />
                                </Grid>
                                {/* <Grid item xs={12} >
                                    <TextField
                                        rows={6}
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        multiline
                                        label="  Company description:"
                                        autoFocus
                                        name='description:'
                                        onChange={(e) => setExhibitorDetails({ ...exhibitorDetails, [e.target.name]: e.target.value })}

                                        helperText={exhibitionAddExhibitor.error_list.description || ""} />
                                </Grid> */}

                                {
                                    profile.customFieldLoad && profile.customFieldLoad.map((item, index) => {
                                        return (
                                            <Grid item xs={6} key={index + 1}>
                                                {
                                                    item.type == 'input' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            defaultValue={item.default_value}
                                                            autoFocus
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {/* helperText={item.required == "required" ? "item.name field is required" : "" } */}

                                                {
                                                    item.type == 'radioBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            {
                                                                item.options.map((radioOption, index) => {
                                                                    return (

                                                                        <RadioGroup
                                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                                            name={item.name}
                                                                            // value={customFieldselectedRadio[item.id] || ''}
                                                                            value={customFieldselectedRadio[item.id] || item.default_value || ""}
                                                                            onChange={handleChangeGender(item.id)}
                                                                            key={index + 1}>
                                                                            <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                                                        </RadioGroup>

                                                                    )
                                                                })

                                                            }  </> : ""}

                                                {item.type == 'select' ?
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={customFieldselectedLoadDropDwon[item.id] || ""}

                                                            onChange={handleTypeCustomLoad(item.id)}
                                                            label="Action" >
                                                            {item.options.map((dropDwonValue, index) => {
                                                                return (
                                                                    <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                                                                )
                                                            })
                                                            }
                                                        </Select>
                                                        <FormHelperText> </FormHelperText>
                                                    </FormControl>
                                                    : ""}
                                                {
                                                    item.type == 'textarea' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            autoFocus={true}
                                                            multiline
                                                            defaultValue={item.default_value || ""}
                                                            rows={4}
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldTextArea({ ...customFieldTextArea, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {
                                                    item.type == 'number' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            type="number"
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            autoFocus
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldNumber({ ...customFieldNumber, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {
                                                    item.type == 'multiSelect' ?
                                                        <FormControl key={item.id} variant="outlined" sx={{ width: 395, }}>
                                                            <InputLabel id={`${item.id}-label`}>{item.name}</InputLabel>
                                                            <Select
                                                                label={item.id}
                                                                labelId={`${item.name}-label`}
                                                                multiple
                                                                value={customFieldmultipleSeleteValue[item.id] || []}
                                                                onChange={handleChangeMulti(item.id)}>
                                                                {item.options.map((multipleOption, index) => (
                                                                    <MenuItem key={index + 1} value={multipleOption.name}>{multipleOption.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        : ""
                                                }
                                                {/* {
                                                    item.type == 'checkBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {item.options.map((multipleOption, index) => (
                                                                    <FormControlLabel
                                                                        key={index + 1}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={customFieldcheckbox[multipleOption.name] || false}
                                                                                onChange={handleCheckboxChange(multipleOption.name)} />} label={multipleOption.name} />
                                                                ))}
                                                            </FormGroup>
                                                        </> : ""

                                                } */}
                                                {
                                                    item.type == 'checkBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {item.options.map((multipleOption, index) => (

                                                                    <FormControlLabel
                                                                        key={index + 1}
                                                                        control={

                                                                            <Checkbox
                                                                                checked={customFieldcheckbox[multipleOption.id]?.includes(multipleOption.name) ?? multipleOption.isChecked}
                                                                                onChange={() => handleCheckboxChange(multipleOption.id, multipleOption.name)}
                                                                            />

                                                                        } label={multipleOption.name} />

                                                                ))}
                                                            </FormGroup>
                                                        </> : ""}

                                                {
                                                    item.type == 'link' ?
                                                        <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""
                                                }
                                                {
                                                    item.type == 'image' ?
                                                        <>
                                                            <label htmlFor="icon-button-file">
                                                                <Typography variant="h5">{item.image}</Typography>
                                                                <TextField id="icon-button-file"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleImageChange(item.id)} name="[licenseFile]" />
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <PhotoCamera />
                                                                </IconButton><br></br>
                                                                <Typography
                                                                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                                                    {item.name}
                                                                </Typography> <br></br>
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    {
                                                                        item.default_value == null ? "no image upload" :
                                                                            <img src={`${profile.userProfile.base_url}/abstract/${item.default_value || ""}`} width="250" height="150" />
                                                                    }

                                                                </IconButton>
                                                            </label> </> : ""

                                                }
                                                {
                                                    item.type == 'file' ?
                                                        <>
                                                            <label htmlFor="icon-button-file">
                                                                <Typography variant="h5">{item.image}</Typography>
                                                                <TextField id="icon-button-file"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleImageChangeFile(item.id)} name="[licenseFile]" />
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <AttachmentIcon />
                                                                </IconButton>
                                                                <Typography
                                                                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                                                    {item.name}
                                                                </Typography>
                                                                <a href={`${profile.userProfile.base_url}/abstract/${item.default_value}`}
                                                                    download={`${profile.userProfile.base_url}/abstract/${item.default_value}`}
                                                                    target="_blank" rel="noreferrer">Download {item.label_name}</a>
                                                            </label> </> : ""




                                                }
                                            </Grid>
                                        )
                                    })
                                }




                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default ExhibitorProfile;
