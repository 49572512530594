import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';

import DeleteIcon from '@mui/icons-material/Delete';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(
    ids,
    title,
    date


) {
    return { ids, title, date };
}



const rows = [
    createData(1, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(2, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(3, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(4, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(5, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(6, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(7, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(8, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(9, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));


const Instruction = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
          float: 'right',
          marginRight: '1%',
          marginTop: '2%',
          ":hover": {
            color: "white"
          }
        }}
          color='success'
          size='small'
          variant='contained'
          component={Link}
          to="/admin/addinstruction/addinstruction">
         Add New
        </Button>
                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Instructions To Authors
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow sx={{ color: '#FFFFFF' }}>
                                            <TableCell > #</TableCell>
                                            <TableCell align="left">Instructions</TableCell>
                                            <TableCell align="left"> Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {(rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row , i) => (
                                            <TableRow key={row.ids}>
                                                <TableCell component="th" scope="row" style={{ width: 60 }}>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell style={{ width: 60 }} align="left">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell style={{ width: 60 }} align="left">
                                                    {row.date}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={3}
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label': 'rows per page',
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default Instruction;
