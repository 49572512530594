import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link ,useHistory} from 'react-router-dom';
import { Button, Box } from '@mui/material';
import swal from "sweetalert";
import Table from '@mui/material/Table';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DeleteIcon from '@mui/icons-material/Delete';


function AllEvents() {
  const history =useHistory();
  const [loading, setloading] = useState(false);
  const [alleventlist, setAlleventlist] = useState([]);
  const [loginInput ,setLogin]= useState({
      email:"",
      password:"" ,
      error_list:[],  

 });

  useEffect(() => {

      axios.get(`api/superadmin-event`).then(res => {

          if (res.status === 200) {


              setAlleventlist(res.data.allEventForSuperAdmin);


          }
          else {
              setloading(false);
          }
      })


  }, []);


  

  const adminDashbord= (e)=>{
      {
          e.preventDefault();
   
        
          axios.get('/sanctum/csrf-cookie').then(response => {
   
          axios.post(`api/master-login`).then(res =>{
           if(res.data.status ===200)
           {
              localStorage.setItem('auth_token',res.data.token);
              localStorage.setItem('auth_name',res.data.username);
              swal('Success',res.data.message,'success');
              if(res.data.role ==='superadmin')
              {
               history.push('/admin');
              }
              
              else if(res.data.role === 'organiser')
              {
               history.push('/admin/events');
              }
              
           }
           else if(res.data.status ===401){
               swal('Warning',res.data.message,'warning');
           }
           else{
               setLogin({...loginInput, error_list: res.data.validate_error});
           }
             
   
          });
       });
      

      }
  }
  const eventToArchive = (e, id) => {
    e.preventDefault();
    
    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Move To Archive...";

    axios.get(`api/superadmin-activeInactiveEvent/${id}`).then(res => {

      if (res.data.status === 200) {
        
        swal('Success',"Event Move To Archive Successfull !!!" , 'success');
        thisClicked.closest("tr").remove();
        history.push('/master/events')
        
      }
      else if (res.data.status === 404) {

        swal('Success', res.data.message, 'success');
        thisClicked.innerText = "Delete";
      }

    });

  }

  var evanetDataShowInTable = '';
  if (loading) {
    return <h1>Loading Events...</h1>
  }
  else {
    evanetDataShowInTable =
    alleventlist.map((item, index) => {
        return (
          <TableRow

            key={item.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>


            <TableCell >{index + 1}</TableCell>
            <TableCell component="th" scope="row">
              {item.eventname}
            </TableCell>
            <TableCell align="center">


              <Button sx={{
                marginRight: '11%',
                marginTop: '2%',
                ":hover": {
                  color: "white"
                }
              }}
                color='primary'
                size='small'
                variant='contained'
            
                onClick={adminDashbord}>
                <VisibilityIcon />
              </Button>
              
              <Button sx={{
                marginRight: '11%',
                marginTop: '2%',
                ":hover": {
                  color: "white"
                }
              }}
                color='primary'
                size='small'
                variant='contained'
             
                onClick={(e) => eventToArchive(e, item.id)}
              >
                <DeleteIcon />
              </Button>

            </TableCell>
          </TableRow>
        )

      });
  }
              return (
             
                
                <div style={{ margin: '1%', }}>
                  
                  <Box sx={{ color: '#000000', }} style={{ marginTop: '7%' }}>
                    <h4 sx={{ m: 2, color: '#000000' }}> Event Management
                      <Button sx={{
                        float: 'right',

                        marginTop: '2%',
                        ":hover": {
                          color: "white"
                        }
                      }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/master/add-event">
                        Add EventS
                      </Button>

                    </h4>
                    <Card sx={{ width: '80vw', float: 'center' }} >
                      <CardContent >
                        <TableContainer >
                          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow sx={{ color: '#FFFFFF' }}>
                                <TableCell >#</TableCell>
                                <TableCell > Event Name</TableCell>
                                <TableCell align="center" >Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {evanetDataShowInTable}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Box>

                </div>
              )
              }

export default AllEvents
