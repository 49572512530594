import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
const AddEventID = () => {
    const history = useHistory();
    const eventid = localStorage.getItem('eventid')
    const [abstactEventId, setAbstactEventId] = useState({
        eventid: eventid,
    });
    const handleInput = (e) => {
        e.persist();
        setAbstactEventId({ ...abstactEventId, [e.target.name]: e.target.value });
    }
    const absEventSubmit = (e) => {
        e.preventDefault();
        const data = {
            eventid: abstactEventId.eventid,
        }
    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/abstract/dashbord">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Abstract Active For Authors
                        </Typography>
                        <Box component="form" onSubmit={absEventSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Enter Event ID"
                                        autoFocus
                                        disabled
                                        name='eventid' onChange={handleInput} value={eventid} />
                                </Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={3} ></Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default AddEventID;
