import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(
    ids,
    title,
    date

) {
    return { ids, title, date };
}



const rows = [
    createData(1, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(2, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(3, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(4, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(5, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(6, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(7, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(8, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
    createData(9, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const ViewQuestion = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const abstractId = props.match.params.id;
    // const [viewQuestions, setViewquestion] = useState([]);
    const eventid = localStorage.getItem('eventid')
    const history = useHistory();

    const [getabstractTitle, setGetabstractTitle] = React.useState('');
    useEffect(() => {
        axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
            if (res.status === 200) {
                const getAbstTitle = res.data.abstractTitle;
                setGetabstractTitle(getAbstTitle)
            }
            else {
                swal('Error', "Check API Something Wrong", 'error');
            }

        });

    }, []);




    const [selectedValues, setSelectedValues] = useState([]);
    const [questions, setAllQuestions] = useState([]);
    const [backgroundQuestions, setAllQuestionsBackground] = useState([]);
    const [mothodQuestions, setAllQuestionsMethod] = useState([]);
    const [resultsQuestions, setAllQuestionsResults] = useState([]);
    const [discussionQuestions, setAllQuestionsDiscussion] = useState([]);
    const [clarityQuestions, setAllQuestionsClarity] = useState([]);

    // const [marks, setmarks] = useState({});
    const handleRadioChange = (event, questionId) => {
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };
    const handleBackgroundRadioChange = (event, questionId) => {
        setAllQuestionsBackground((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };

    const handleMethodRadioChange = (event, questionId) => {
        setAllQuestionsMethod((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };
    const handleResultsRadioChange = (event, questionId) => {
        setAllQuestionsResults((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };

    const handleDiscussionRadioChange = (event, questionId) => {
        setAllQuestionsDiscussion((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };
    const handleClarityRadioChange = (event, questionId) => {
        setAllQuestionsClarity((prevValues) => ({
            ...prevValues,
            [questionId]: event.target.value,
        }));

    };


    useEffect(() => {

        axios.get(`api/allquestionsForReviewerAddScore?id=${eventid}`).then(res => {
            if (res.status === 200) {
                const allquestions = res.data.allQuestionsWithOptions;

                setAllQuestions(allquestions);

            }

            else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        })

    }, []);


    const questionOptionReview = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            abstractId,
            eventid,
            selectedValues,
            backgroundQuestions,
            mothodQuestions,
            resultsQuestions,
            discussionQuestions,
            clarityQuestions,
        }
        console.clear()
        console.log(data)

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/storeAbstractRating`, data).then(res => {
                if (res.data.status === 200) {
                    swal('Success', " Rating Added SuccessFully !!!", 'success');
                    history.push('/admin/abstract/pending-article');
                }
                if (res.data.status === 404) {
                    swal('Error', "Check API Something Wrong", 'error');
                }
            });
        });
    }
    const [open, setOpen] = React.useState(false);
    const [singleData, setSingleData] = React.useState({});
    const handleClickShowSingleRecord = (id) => {
        axios.get(`api/singleAbstractQuestion/${id}`).then(res => {
            if (res.status === 200) {
                const data = res.data.singleQuestion;
                setSingleData(data)
            } else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        })
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>

                <Button sx={{
                    float: 'right',
                    marginTop: '2%',
                    ":hover": {
                        color: "white"
                    }
                }}
                    color='success'
                    size='small'
                    variant='contained'
                    component={Link}
                    to="/admin/abstract/pending-article">
                    BAck
                </Button>

                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginRight: '1%',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='primary'
                        size='small'
                        variant='contained'
                        component={Link}
                        target="_blank"
                        to={`/admin/abstract/singleabstract/${abstractId}`}>
                        View Abstract
                    </Button>
                </h4>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center" >
                    <Grid item xs={3}>
                        <h1>Abstract Title: {getabstractTitle.title}</h1>
                        <hr></hr>
                    </Grid>
                </Grid>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add
                        </Typography>

                        <Box component="form" onSubmit={questionOptionReview} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={1} ></Grid>
                                <Grid item xs={10} >
                                    {questions.map((question , i) => (
                                        <div key={question.id}>
                                            {question.view_as == 'simple' ?

                                                <div key={question.id}>

                                                    <h3>{i +1}: {question.label} </h3>
                                                    <RadioGroup
                                                        row
                                                        value={selectedValues[question.id] || ''}
                                                        onChange={(event) => handleRadioChange(event, question.id)}>
                                                        {question.options.map((option, i) => (
                                                            <FormControlLabel
                                                                key={option.value}
                                                                // value={option.value} 
                                                                value={i + 1}
                                                                control={<Radio />}
                                                                label={i + 1 + ": " + option.label} />
                                                        ))}
                                                    </RadioGroup>
                                                </div>

                                                :
                                                <div key={question.id}>

                                                    <h3> {i +1}: {question.label} </h3>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 300 }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left"></TableCell>
                                                                    {question.options.map((option, i) => (
                                                                        <TableCell align="left" key={i + 1}>{option.label}</TableCell>
                                                                    ))}
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Background</TableCell>
                                                                    {question.options.map((option, index) => (
                                                                        <TableCell align="left" key={index + 1}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={backgroundQuestions[question.id] || ''}
                                                                                onChange={(event) => handleBackgroundRadioChange(event, question.id)}>

                                                                                <FormControlLabel
                                                                                    key={option.value}
                                                                                    // value={option.value} 
                                                                                    value={index + 1}
                                                                                    control={<Radio />}
                                                                                    label="" />

                                                                            </RadioGroup>
                                                                        </TableCell>
                                                                    ))}

                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Method</TableCell>
                                                                    {question.options.map((option, i) => (
                                                                        <TableCell align="left" key={i}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={mothodQuestions[question.id] || ''}
                                                                                onChange={(event) => handleMethodRadioChange(event, question.id)}>

                                                                                <FormControlLabel
                                                                                    key={option.value}
                                                                                    // value={option.value} 
                                                                                    value={i + 1}
                                                                                    control={<Radio />}
                                                                                    label="" />

                                                                            </RadioGroup>
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>

                                                                <TableRow>
                                                                    <TableCell align="left">Result</TableCell>
                                                                    {question.options.map((option, i) => (
                                                                        <TableCell align="left" key={i}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={resultsQuestions[question.id] || ''}
                                                                                onChange={(event) => handleResultsRadioChange(event, question.id)}>

                                                                                <FormControlLabel
                                                                                    key={option.value}
                                                                                    // value={option.value} 
                                                                                    value={i + 1}
                                                                                    control={<Radio />}
                                                                                    label="" />

                                                                            </RadioGroup>
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>

                                                                <TableRow>
                                                                    <TableCell align="left">Discussion</TableCell>
                                                                    {question.options.map((option, i) => (
                                                                        <TableCell align="left" key={i}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={discussionQuestions[question.id] || ''}
                                                                                onChange={(event) => handleDiscussionRadioChange(event, question.id)}>

                                                                                <FormControlLabel
                                                                                    key={option.value}
                                                                                    // value={option.value} 
                                                                                    value={i + 1}
                                                                                    control={<Radio />}
                                                                                    label="" />

                                                                            </RadioGroup>
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Clarity </TableCell>
                                                                    {question.options.map((option, i) => (
                                                                        <TableCell align="left" key={i}>
                                                                            <RadioGroup
                                                                                row
                                                                                value={clarityQuestions[question.id] || ''}
                                                                                onChange={(event) => handleClarityRadioChange(event, question.id)}>

                                                                                <FormControlLabel
                                                                                    key={option.value}
                                                                                    // value={option.value} 
                                                                                    value={i + 1}
                                                                                    control={<Radio />}
                                                                                    label="" />

                                                                            </RadioGroup>
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>






                                                            </TableHead>
                                                        </Table>
                                                    </TableContainer>

                                                </div>

                                            }
                                        </div>
                                    ))}
                                </Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >

                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Add Score
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default ViewQuestion;
