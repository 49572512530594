import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';




const AddRequirements = () => {
    const history = useHistory();
    const [addRequirements, setAddRequirements] = useState({
        title: "",
        descriptions: "",
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setAddRequirements({ ...addRequirements, [e.target.name]: e.target.value });
    }
    const absCateSubmit = (e) => {

        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            title: addRequirements.title,
            descriptions: addRequirements.descriptions,
            eventid,

        }

        axios.get('/sanctum/csrf-cookie').then(response => {

            axios.post(`/api/abstractrequirementstore`, data).then(res => {

                if (res.data.status === 200) {


                    swal('Success', res.data.message, 'success');

                    history.push('/admin/requirements/requirements');
                }
                else {

                    setAddRequirements({ ...addRequirements, error_list: res.data.validate_error })

                }

            });


        });


    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/requirements/requirements">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Requirements
                        </Typography>
                        <Box component="form" onSubmit={absCateSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth

                                        label="Requirement Title"
                                        autoFocus
                                        name='title' onChange={handleInput} value={addRequirements.title}
                                        helperText={addRequirements.error_list.title} />
                                </Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={3} ></Grid>
                                <Grid item xs={6} >

                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth

                                        label="Requirement Details"
                                        autoFocus
                                        multiline
                                        rows={6}
                                        name='descriptions' onChange={handleInput} value={addRequirements.descriptions}
                                        helperText={addRequirements.error_list.descriptions} />
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348',}} >
                                    Add Requirements
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default AddRequirements;
