import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
const EditGuest = (props) => {
    const history = useHistory();
    const id = props.match.params.id;

    const [editGuest, setEditGuest] = useState({
        title: "",
        name: "",
        email: "",
        company_name: "",
        mobile_no: "",
        error_list: '',
    });
    useEffect(() => {
        return () => {
            axios.get(`api/edit-guest/${id}`).then(res => {
                if (res.status == 200) {
                    const editData = res.data.eg;
                    setEditGuest(editData);
                }
            });
        };
    }, [props.match.params.id]);


    const handleInput = (e) => {
        e.persist();
        setEditGuest({ ...editGuest, [e.target.name]: e.target.value });
    }
    const eventid = localStorage.getItem('eventid');
    const exhibitorCateSubmit = (e) => {
        e.preventDefault();
        const data = {
            //nameTitle,
            title: editGuest.title,
            name: editGuest.name,
            email: editGuest.email,
            company_name: editGuest.company_name,
            mobile_no: editGuest.mobile_no,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/update-guest/${id}`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/exhibition/guest');
                }
                else {
                    setEditGuest({ ...editGuest, error_list: res.data.validate_error })
                }
            });
        });

    }

    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        // component={Link}
                        // to="/admin/exhibition/exhibitor"
                        onClick={() => history.goBack()}
                        >
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Update Guest
                        </Typography>
                        <Box component="form" onSubmit={exhibitorCateSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Title</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Action"
                                            value={editGuest.title}
                                            onChange={(e) => setEditGuest({ ...editGuest, title: e.target.value })}>
                                            <MenuItem value={'Mr'}>Mr</MenuItem>
                                            <MenuItem value={'Mrs'}>Mrs</MenuItem>
                                            <MenuItem value={'Ms'}>Ms</MenuItem>
                                            <MenuItem value={'Dr'}>Dr</MenuItem>
                                            <MenuItem value={'Prof'}>Prof</MenuItem>
                                            <MenuItem value={'Eng'}>Eng</MenuItem>
                                            <MenuItem value={'Sheikh'}>Sheikh</MenuItem>
                                        </Select>
                                        <FormHelperText>{editGuest.error_list?.title || ""}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Name"
                                        autoFocus
                                        name='name'
                                        onChange={handleInput}
                                        value={editGuest.name || ""}
                                        helperText={editGuest.error_list?.name || ""} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Email"
                                        autoFocus
                                        name='email'
                                        type="email"
                                        onChange={handleInput}
                                        value={editGuest.email || ""}
                                        helperText={editGuest.error_list?.email || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Mobile No"
                                        autoFocus
                                        name='mobile_no'
                                        onChange={handleInput}
                                        value={editGuest.mobile_no || ""}
                                        helperText={editGuest.error_list?.mobile_no || ""} />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Company Name"
                                        autoFocus
                                        name='company_name'
                                        onChange={handleInput}
                                        value={editGuest.company_name || ""}
                                        helperText={editGuest.error_list?.company_name || ""} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 5, }} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default EditGuest;
