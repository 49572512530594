import Dashbord from '../components/MasterAdmin/Dashbord';
import AddEvents from "../components/MasterAdmin/Events/AddEvents";
import AllEvents from '../components/MasterAdmin/Events/AllEvents';
import ArchiveEvent from '../components/MasterAdmin/Events/ArchiveEvent';
import User from '../components/MasterAdmin/User/User';
import AddUser from '../components/MasterAdmin/User/AddUser';

const MasterRoutes = [
	
	
	 { path: '/master/admin', exact: true, name: 'MasterAdmin' },
	{ path: '/master/dashboard/:id', exact: true, name: 'User', component: Dashbord },
	// {/*User Routes*/ },
	 { path: '/master/user', exact: true, name: 'User', component: User } ,
	 { path: '/master/add-user', exact: true, name: 'User', component: AddUser } ,

	
	// {/*Event Routes*/ },
	
	{ path: '/master/events', exact: true, name: 'Events', component: AllEvents },
	 { path: '/master/add-event', exact: true, name: 'Events', component: AddEvents },
	// { path: '/master/edit-event/:id', exact: true, name: 'Event', component: EditEvent },
    //  {/*archive Routes*/ },
 
 {path: '/master/archiv-event',exact:true,name:'Events',component:ArchiveEvent},

];



export default MasterRoutes;