import React, { useState ,useEffect} from "react";
import QrReader from "modern-react-qr-reader";
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import axios from 'axios';
import swal from "sweetalert";

function ScanByQrcode() {
    const [result, setResult] = useState(null);

    const handleScan = async (data) => {
      if (data) {
        try {
          setResult(data);
          const response = await axios.get(`/api/show-single-record-print/${data}`);
  
          if (response.status === 200) {
            const detailedData = response.data;
  
            // Open a new tab with a data URI containing the content
            const printWindow = window.open();
            printWindow.document.write(`
              <html>
                <head>
                  <title>Print</title>
                  <style>
                    body {
                      text-align: center;
                      margin: 0;
                      padding: 20px;
                    }
  
                    #content {
                      display: inline-block;
                      text-align: left;
                      padding-top: 190px;
                    }
  
                    #printButton {
                      display: block;
                      position: fixed;
                      top: 0;
                      right: 0;
                      margin: 20px;
                      padding: 10px;
                      background-color: green;
                      color: white;
                      border: none;
                      cursor: pointer;
                    }
  
                    @media print {
                      #printButton {
                        display: none;
                      }
  
                      /* Remove default browser headers and footers */
                      @page {
                        size: auto;
                        margin: 0;
                      }
                    }
                  </style>
                  <script type="text/javascript" src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js"></script>
                  <script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.4/dist/JsBarcode.all.min.js"></script>
                </head>
                <body>
                  <!-- Print Button -->
                  <button id="printButton" onclick="window.print()">Print</button>
  
                  <div id="content">
                    <h1>${detailedData.title}: ${detailedData.name}</h1>
                    <p style="text-align: center;">Company Name: ${detailedData.company_name}</p>
                    <!-- Include a QR code with the record ID -->
                    <div id="qrcode"></div>
                    
  
                    <!-- Include a barcode with the record ID -->
                    <div id="barcode-container">
                      <svg id="barcode"></svg>
                    </div>
                    <p style="text-align: center;">ID: ${detailedData.id}</p>
                    <script type="text/javascript">
                      if (typeof JsBarcode !== 'undefined') {

                        // Get the SVG element for the QR code
                        const qrcodeSvg = document.getElementById("qrcode");
  
                        // Generate QR code using QRCode
                        var qrcode = new QRCode(qrcodeSvg, {
                          text: '${data}',
                          width: 128,
                          height: 128
                        });
  
                        // Get the SVG element for the barcode
                        const barcodeSvg = document.getElementById("barcode");
                        // Generate barcode using JsBarcode
                        JsBarcode(barcodeSvg, '${data}', {
                          format: "CODE128",
                          displayValue: true,
                          fontSize: 14,
                          textMargin: 10,
                        });

                        const textElement = barcodeSvg.querySelector('text');
                        if (textElement) {
                          textElement.remove();
                        }
                        // Set the desired width directly in the SVG element for the barcode
                        barcodeSvg.setAttribute('width', '128'); // Adjust the width here
  
                        // Set the desired height directly in the SVG element for the barcode
                        barcodeSvg.setAttribute('height', '80'); // Adjust the height here
  
                        // Disable aspect ratio preservation for the barcode
                        barcodeSvg.setAttribute('preserveAspectRatio', 'none');
  
                        // Add spacing between the QR code and the barcode
                        qrcodeSvg.style.marginBottom = '20px'; // Adjust the margin here
                      } else {
                        console.error('JsBarcode library not loaded.');
                      }
                    </script>
                  </div>
                  <script>
                    // Trigger the print functionality automatically
                    window.print();
                  </script>
                </body>
              </html>
            `);
  
            // Close the print window after printing
            printWindow.document.close();
  
            // Show a success message
            swal("Done", `QR Code for ${detailedData.title}: ${detailedData.name} (ID: ${detailedData.id}) printed successfully!`, "success");
          } else {
            throw new Error('Error fetching record details');
          }
        } catch (error) {
          console.error('Error printing:', error);
          swal('Error', 'Error printing record details. Please check your browser settings.', 'error');
        }
      }
    };
  
    const handleError = (err) => {
      console.error(err);
    };
  
    useEffect(() => {
      // Automatically trigger the scanning and printing functionality when the component mounts
      
      handleScan(result);
    }, [result]); // The effect will run whenever the result changes
  
    return (
      <Grid
        container
        spacing={24}
        justify="center"
        style={{ minHeight: '100vh', maxWidth: '100%' }}>
        <Grid item xs={3} align="center">
       
        </Grid>
        <Grid item xs={6} align="center">
         
          <Typography variant="h4" sx={{ m: 2,  mt: 12}}>Self Scan Qr Code </Typography>
          <div>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '350px' }} 
            />
            {result && (
              <div>
                <Typography variant="h5">Scanned ID: {result}</Typography>
                {/* Additional information or styling can be added here */}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={3} align="center">
        </Grid>
      </Grid>
    );
  }
  
export default ScanByQrcode;
