import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import EmsNavbar from "../../frontend/EmsNavbar";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
import { FormatBold } from "@mui/icons-material";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormLabel from '@mui/material/FormLabel';

const theme = createTheme();

const ExhibitionRegister = () => {
  const history = useHistory();
  const [exhibitionRegisterInput, setExhibitionRegisterInput] = useState({

    name: "",
    email: "",
    phone: "",
    password: "",
    error_list: [],
  });


  const exhibition_email = localStorage.getItem('exhibition_email');
  const handleInput = (e) => {
    e.persist();
    setExhibitionRegisterInput({ ...exhibitionRegisterInput, [e.target.name]: e.target.value });
  }
  const exhibitionRegisterSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: exhibitionRegisterInput.name,
      email: exhibitionRegisterInput.email,
      password: exhibitionRegisterInput.password,
      phone: exhibitionRegisterInput.phone,
    }
   
    // axios.get('/sanctum/csrf-cookie').then(response => {

    // axios.post(`/api/register`,data).then( res=> {

    //  if(res.data.status ===200){

    //         localStorage.setItem('auth_token',res.data.token);
    //         localStorage.setItem('auth_name',res.data.username);
    //         swal('Success', res.data.message,'success');
    //         history.push('/login');
    //  }
    //  else{

    //      setregister({...registerInput,error_list: res.data.validate_error})

    //  }

    // });


    // });

  }
  return (
    <>
      <EmsNavbar />


      <div style={{ margin: '1%', width: '80vw', marginLeft: '10%' }}>
        <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={exhibitionRegisterSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.name}
                  helperText={exhibitionRegisterInput.error_list.name}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleInput} value={exhibitionRegisterInput.email || exhibition_email}
                  helperText={exhibitionRegisterInput.error_list.email}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Title</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    label="Action" >
                    <MenuItem value={1}>Mr</MenuItem>
                    <MenuItem value={2}>Mrs</MenuItem>
                    <MenuItem value={3}> Ms</MenuItem>
                    <MenuItem value={4}>Dr </MenuItem>
                    <MenuItem value={5}>Prof</MenuItem>
                    <MenuItem value={6}>Eng </MenuItem>
                    <MenuItem value={7}>Sheikh</MenuItem>
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="jobTatile"
                  required
                  fullWidth
                  id="jobTatile"
                  label="Job Title"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.jobTatile}
                  helperText={exhibitionRegisterInput.error_list.jobTatile}
                />
              </Grid>
              <Grid item xs={6} >
                <TextField
                  autoComplete="given-name"
                  name="companyName"
                  required
                  fullWidth
                  id="companyName"
                  label="company Name"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.companyName}
                  helperText={exhibitionRegisterInput.error_list.companyName}
                />
              </Grid>
              <Grid item xs={6} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Title</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    label="Action" >
                    <MenuItem value={1}>Mr</MenuItem>
                    <MenuItem value={2}>Mrs</MenuItem>
                    <MenuItem value={3}> Ms</MenuItem>
                    <MenuItem value={4}>Dr </MenuItem>
                    <MenuItem value={5}>Prof</MenuItem>
                    <MenuItem value={6}>Eng </MenuItem>
                    <MenuItem value={7}>Sheikh</MenuItem>
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={6} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    label="Action" >
                    <MenuItem value={1}>A</MenuItem>
                    <MenuItem value={2}>B</MenuItem>
                    <MenuItem value={3}> C</MenuItem>
                    <MenuItem value={4}>D </MenuItem>
                    <MenuItem value={5}>E</MenuItem>
                    <MenuItem value={6}>F </MenuItem>
                    <MenuItem value={7}>G</MenuItem>
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={6} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    label="Action" >
                    <MenuItem value={1}>A</MenuItem>
                    <MenuItem value={2}>B</MenuItem>
                    <MenuItem value={3}> C</MenuItem>
                    <MenuItem value={4}>D </MenuItem>
                    <MenuItem value={5}>E</MenuItem>
                    <MenuItem value={6}>F </MenuItem>
                    <MenuItem value={7}>G</MenuItem>
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={6} >
                <TextField
                  autoComplete="given-name"
                  name="phone"
                  required
                  fullWidth
                  id="firstName"
                  label="Mobile No:"
                  autoFocus
                  onChange={handleInput} value={exhibitionRegisterInput.phone}
                  helperText={exhibitionRegisterInput.error_list.phone}
                />
              </Grid>

              <Grid item xs={12} >
                <hr />
                <Typography component="h4" variant="subtitle1">
                  Please select your company nature of business / يُرجى تحديد طبيعة الأعمال التجارية لشركتك
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} >
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group" style={{padding:"50%"}} spacing={5}>
                    <FormControlLabel value="Architecture " control={<Radio />} label="Architecture" />

                   <FormControlLabel value="Contractor" control={<Radio />} label="Contractor" />
                    <FormControlLabel value="Distributor" control={<Radio />} label="Distributor" />

                  </RadioGroup>
                </FormControl>
                
              </Grid>
              
             

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />

              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2" style={{ color: '#1976d2' }}>
                  Already have an account? Sign in
                </Link>

              </Grid>
            </Grid>
          </Box>
        </Box>

      </div >

    </>
  );
}

export default ExhibitionRegister;
