import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
const EditApproveArticle = (props) => {
  const [disabled, setdisabled] = useState(false);
  const [permissions, setrPermissions] = useState([]);
  const AuthUserName = localStorage.getItem('auth_name');
  React.useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)
      } else {

      }
    })

  }, []);
 

  const [adminAbstractCategory, setAdminAbstractCategory] = React.useState([]);
  const eventid = localStorage.getItem('eventid')
  React.useEffect(() => {
    axios.get(`api/abstractCategory?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractCategoryData = res.data.articleCategory;
        setAdminAbstractCategory(abstractCategoryData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);
 
  const [adminSubmissionType, setAdminSubmissionType] = React.useState([]);
  
  React.useEffect(() => {
    axios.get(`api/abstract-submisson-index?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const abstractsubmissionTypeData = res.data.submissionType;
        setAdminSubmissionType(abstractsubmissionTypeData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  const [aproveArticleEdit, setAproveArticleEdit] = useState({
    id: '',
    writer_id: '',
    title: '',
    description: '',
    image: '',
    attachment: '',
    video_link: '',
    reject: '',
    created_by: '',
    start_date: '',
    error_list: [],

  });

  const belongTo = 2;
  useEffect(() => {
    const id = props.match.params.id;
    axios.get(`api/abstractArticleEdit/${id}/${eventid}/${belongTo}`).then(res => {

      if (res.status === 200) {
        const data = res.data.abstractEdit;
        setAproveArticleEdit(data)
      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }

    });

  }, [props.match.params.id]);
  const handleInput = (e) => {
    e.persist();
    setAproveArticleEdit({ ...aproveArticleEdit, [e.target.name]: e.target.value });
  }
 
  const [status, setStatus] = React.useState(1);
  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  

  const [abstractImage, setAbstractImage] = useState("");
  const [abstractFile, setAbstractFile] = useState("");
  const [errorlist, setError] = useState([]);

  const history = useHistory();

  const cateid = aproveArticleEdit.category;
  const [articleCategoryId, setAprovedArticle] = useState("");
  
  useEffect((e) => {
    setAprovedArticle(cateid);
  }, [cateid]);


 
  const type = aproveArticleEdit.submission_id;
  const [selectedType, setSelectedType] = React.useState("");
  useEffect((e) => { 
    setSelectedType(type);
   
   }, [type]);
   

  const reviewStatus = aproveArticleEdit.review_status;
  const [reStatus, setReStatus] = useState("");
   useEffect((e) => {
    setReStatus(reviewStatus);
   
   }, [reviewStatus]);
  

   

  const abstractAdminArticleupdate = (e) => {
    e.preventDefault();
    const eventid = localStorage.getItem('eventid')
    const data = {
      writer_id: aproveArticleEdit.writer_id,
      title: aproveArticleEdit.title,
      description: aproveArticleEdit.description,
      video_link: aproveArticleEdit.video_link,
      reject: aproveArticleEdit.reject,
      status,
      reStatus,
      //abstractAdminCategory,
      articleCategoryId,
      abstractImage,
      abstractFile,
      selectedType,

    }
    setdisabled(true)
   
    const id = props.match.params.id
    axios.get('/sanctum/csrf-cookie').then(response => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(`/api/abstractArticleOrgUpdate/${id}`, data, config).then(res => {

        if (res.data.status == 200) {
          swal('Success', res.data.message, 'success');
          data.reStatus == 1 ? history.push('/admin/abstract/approve-article') : 
          data.reStatus == 2 ? history.push('/admin/abstract/pending-article') :
          history.push('/admin/abstract/reject-article') 
         
         }
        else {
          swal("Review Status Field Is Requried !!", "", "error");
          setError(res.data.validate_error);

        }

      });


    });



  }

  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            onClick={() => history.goBack()} >
            BAck
          </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
            Edit  Abstract 
            </Typography>
            <Box component="form" onSubmit={abstractAdminArticleupdate} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={5}>
                <input
                  hidden
                  name='writer_id' onChange={handleInput} value={aproveArticleEdit.writer_id || ""} />

                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Article Title"
                    autoFocus
                    name='title' onChange={handleInput} value={aproveArticleEdit.title || ""} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel >Select Category</InputLabel>
                    <Select
                      name='abstractAdminCategory' value={articleCategoryId || ""}
                      onChange={(e) => setAprovedArticle(e.target.value)} 
                      input={<OutlinedInput label="Category" />}>

                      {
                        adminAbstractCategory.map((item => {
                          return (

                            <MenuItem value={item.id} key={item.id}> {item.title}</MenuItem>
                          )

                        }))
                      }
                    </Select>
                    
                  </FormControl>

                </Grid> 
                {/* <Grid item xs={6} >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Selete Submission Type</InputLabel>
                     <Select
                        name='selete_type'
                        value={selectedType || ""}
                        label="Select Type" onChange={(e)=> setSelectedType(e.target.value)} 
                        input={<OutlinedInput label="Category" />}>
                         <MenuItem value={1}>Podium</MenuItem>
                      <MenuItem value={2}>Poster</MenuItem>
                      <MenuItem value={1}>Abstract</MenuItem>
                      <MenuItem value={2}>conf presentation</MenuItem>
                      <MenuItem value={3}> Poster</MenuItem>
                      <MenuItem value={4}>Workshop </MenuItem>
                      <MenuItem value={5}>Full Research Paper</MenuItem>
                      <MenuItem value={6}>Competition </MenuItem>
                      <MenuItem value={7}>Research Award </MenuItem>
                      </Select>
                   
                  </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel >Submission Type</InputLabel>
                    <Select
                      name='selete_type' value={selectedType || ""}
                      label="Select Type" onChange={(e)=> setSelectedType(e.target.value)}
                      input={<OutlinedInput label="Category" />}>

                      {
                        adminSubmissionType.map((item => {
                          return (

                            <MenuItem value={item.id} key={item.id}> {item.title}</MenuItem>
                          )

                        }))
                      }
                    </Select>
                    
                  </FormControl>

                </Grid> 
                <Grid item xs={6} >
                  <label htmlFor="icon-button-file">
                    <TextField id="icon-button-file" type="file" onChange={(e) => setAbstractImage(e.target.files[0])} name="[licenseFile]" />
                    <IconButton color="primary" aria-label="upload picture" component="span"
                    >
                      <PhotoCamera />
                      <img src={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.image || ""}`} width="50" height="60" />
                    </IconButton>

                  </label>
                </Grid>


                <Grid item xs={6} >
                  <label htmlFor="contained-button-file">
                    <TextField id="contained-button-file" multiple type="file" onChange={(e) => setAbstractFile(e.target.files[0])} />

                    <IconButton color="primary" aria-label="upload picture" component="span"
                    >
                      <AttachmentIcon />
                    </IconButton>
                    {aproveArticleEdit.attachment ?
                <a href={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.attachment}`}
                  download={`${aproveArticleEdit.base_url}/abstract/${aproveArticleEdit.attachment}`}
                  target="_blank" rel="noreferrer">View Attachment</a> : <Button>No Attachment</Button>}
                  </label>
                </Grid>

                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Youtube Video ID"
                    autoFocus
                    name='video_link' onChange={handleInput} value={aproveArticleEdit.video_link || ""} />
                </Grid>


                <Grid item xs={12} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Article Details"
                    autoFocus
                    name='description'
                    multiline
                    rows={6} onChange={handleInput} value={aproveArticleEdit.description || ""} />
                </Grid>
                {/* {
                    permissions['p_abstract-review-status'] === true ?
                    <Grid item xs={6} >
                    <FormControl
                      margin="dense"
                      fullWidth >
                      <InputLabel >Review Status</InputLabel >
                      <Select
                        name='review_status'
                        value={reStatus || ""}
                        label="Review Status" onChange={(e)=> setReStatus(e.target.value)} 
                        input={<OutlinedInput label="Category" />}>
                        <MenuItem value={1}>Approve</MenuItem>
                        <MenuItem value={2}>Pending</MenuItem>
                        <MenuItem value={3}>Reject</MenuItem>
                      </Select>
                      <FormHelperText>{errorlist.reStatus} </FormHelperText>
                    </FormControl>
                  </Grid>
                      : ""} */}
                

                <Grid item xs={6} >
                  <FormControl
                    margin="dense"
                    fullWidth  >

                    <InputLabel >Status</InputLabel>
                    <Select
                      name='status'
                      value={status}

                      label="Status"
                      onChange={handleChangeStatus} defaultValue={aproveArticleEdit.status || ""}
                    >

                      <MenuItem value={1} >Active</MenuItem>
                      {/* <MenuItem value={0}>InActive</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                {/* {
                    permissions['p_abstract-review-status'] === true ?
                <Grid item xs={12} >
                  <TextField
                    margin="dense"
                    label="Article Reject"
                    name='reject'
                    multiline
                    fullWidth
                    rows={6}
                    required
                    onChange={handleInput} value={aproveArticleEdit.reject} />
                </Grid>
                : ""} */}

              </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  disabled={disabled}
                  variant="contained"
                  sx={{ mt: 5,  backgroundColor: '#145348',}} >
                  Update Abstract
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default EditApproveArticle;
