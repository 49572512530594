import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import swal from "sweetalert";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grade } from "@mui/icons-material";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PrintView from "./PrintView";
import { createPortal } from 'react-dom';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function createData(
    ids,
    title,
    Category,
    Issue,
    SubmitStatus,
    RewiveStatus,
    Status,


) {
    return { ids, title, Category, Issue, SubmitStatus, RewiveStatus, Status };
}




const IndexGuest = () => {
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [loadning, setLoadning] = useState(false);

    const [permissions, setrPermissions] = useState([]);
    const AuthUserName = localStorage.getItem('auth_name');
    React.useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)
            } else {

            }
        })

    }, []);

    const [abstractArticlePending, setAbstractArticlePending] = React.useState([]);
    const eventid = localStorage.getItem('eventid')
    React.useEffect(() => {
        axios.get(`api/index-guest-register?id=${eventid}`).then(res => {

            if (res.status === 200) {
                const abstractArticleData = res.data.guests;
                setAbstractArticlePending(abstractArticleData)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = abstractArticlePending.filter((row) =>
        (row.id && row.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.mobile_no && row.mobile_no.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (row.company_name && row.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
        // Add more fields as needed
    );
    const totalRecords = abstractArticlePending.length;
    const filteredRecordsCount = filteredPosts.length;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - abstractArticlePending.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    // model single data show
    //for model open'
    const [open, setOpen] = useState(false);

    const [openEdit, setOpenForEdit] = useState(false);
    const abstracPendingtDelete = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = " Deleting..."
        axios.get(`api/abstractArticlestatus/${id}`).then(res => {
            if (res.data.status === 200) {
                //swal("Success", res.data.message, "success");
                thisClicked.closest("tr").remove();
            }
            else if (res.data.status === 404) {
                this.innText = "delete";
            }
        });

    }
    const role = localStorage.getItem('role')


    // Import the Barcode component here if needed
    // import Barcode from 'path-to-barcode-component';

    const handlePrint = async (id) => {
        try {
            const response = await axios.get(`api/show-single-record-print/${id}`);

            if (response.status === 200) {
                const detailedData = response.data;

                // Open a new tab with a data URI containing the content
                const printWindow = window.open();
                printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
              body {
                text-align: center;
                margin: 0;
                padding: 20px;
              }
            
              #content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: left;
                padding-top: 190px;
              }
            
              #printButton {
                display: block;
                position: fixed;
                top: 0;
                right: 0;
                margin: 20px;
                padding: 10px;
                background-color: green;
                color: white;
                border: none;
                cursor: pointer;
              }
            
              @media print {
                #printButton {
                  display: none;
                }
            
                /* Remove default browser headers and footers */
                @page {
                  size: auto;
                  margin: 0;
                }
              }
            </style>
              <script type="text/javascript" src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js"></script>
              <script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.4/dist/JsBarcode.all.min.js"></script>
            </head>
            <body>
              <!-- Print Button -->
              <button id="printButton" onclick="window.print()">Print</button>
  
              <div id="content">
              <h1 style="margin-bottom: 10px;">${detailedData.title}: ${detailedData.name}</h1>
              <p style="text-align: center; margin-top: 0;">Company Name: ${detailedData.company_name}</p>
                <!-- Include a QR code with the record ID -->
                <div id="qrcode"></div>
  
                <!-- Include a barcode with the record ID -->
                <div id="barcode-container">
                  <svg id="barcode"></svg>
                </div>
                <p style="text-align: center;">ID: ${detailedData.id}</p>
                <script type="text/javascript">
                if (typeof JsBarcode !== 'undefined') {
                    // Get the SVG element for the QR code
                    const qrcodeSvg = document.getElementById("qrcode");
                  
                    // Generate QR code using QRCode
                    var qrcode = new QRCode(qrcodeSvg, {
                      text: '${id}',
                      width: 128,
                      height: 128
                    });
                  
                    // Get the SVG element for the barcode
                    const barcodeSvg = document.getElementById("barcode");
                  
                    // Generate barcode using JsBarcode
                    JsBarcode(barcodeSvg, '${id}', {
                      format: "CODE128",
                      displayValue: true,
                      fontSize: 14,
                      textMargin: 10,
                    });
                    const textElement = barcodeSvg.querySelector('text');
                    if (textElement) {
                      textElement.remove();
                    }
                    // Set the desired width directly in the SVG element for the barcode
                    barcodeSvg.setAttribute('width', '128'); // Adjust the width here
                  
                    // Set the desired height directly in the SVG element for the barcode
                    barcodeSvg.setAttribute('height', '80'); // Adjust the height here
                  
                    // Disable aspect ratio preservation for the barcode
                    barcodeSvg.setAttribute('preserveAspectRatio', 'none');
                  
                    // Add spacing between the QR code and the barcode
                    qrcodeSvg.style.marginBottom = '20px'; // Adjust the margin here
                  } else {
                    console.error('JsBarcode library not loaded.');
                  }
                </script>
              </div>
            </body>
          </html>
        `);

                // Close the print window after printing
                printWindow.document.close();
            } else {
                throw new Error('Error fetching record details');
            }
        } catch (error) {
            console.error('Error printing:', error);
            swal('Error', 'Error printing record details. Please check your browser settings.', 'error');
        }
    };



    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>

                <h4 sx={{ m: 2, color: '#000000' }} >
                    {/* <Button sx={{
                        float: 'right',
                        marginTop: '2%',

                        ":hover": {
                            color: "white"
                        },
                        backgroundColor: '#145348'
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/abstract/issue-pending-article">

                        All Issue
                    </Button> */}
                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography component="h1" variant="h5">
                                Register Guest
                            </Typography>
                            Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                            <Grid item xs={8} >
                                <TextField
                                    fullWidth
                                    label="Filter By: ID , Name , Email"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </div>
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow sx={{ color: '#FFFFFF' }}>
                                            <TableCell align="left">ID</TableCell>
                                            <TableCell align="left">Title</TableCell>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Mobile No</TableCell>
                                            <TableCell align="left">Company Name</TableCell>
                                            <TableCell align="center" style={{ width: 150 }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                            <TableRow key={row.id}>
                                                <TableCell style={{ width: 160 }} align="left">
                                                    {row.id}
                                                </TableCell> 
                                                <TableCell style={{ width: 160 }} align="left">
                                                {row.title}
                                                </TableCell>
                                                <TableCell style={{ width: 160 }} align="left">
                                                {row.name}
                                                </TableCell>
                                                <TableCell style={{ width: 160 }} align="left">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell style={{ width: 160 }} align="left">
                                                    {row.mobile_no}
                                                </TableCell>
                                                <TableCell style={{ width: 160 }} align="left">
                                                    {row.company_name}
                                                </TableCell>
                                                <TableCell style={{ width: 155 }} align="right">
                                                {
                                                        permissions['p_exhibition-register-guest-print'] === true ?
                                                    <Button
                                                        sx={{
                                                            margin: 1,
                                                            ":hover": {
                                                                color: "white",
                                                            },
                                                            backgroundColor: '#145348',
                                                        }}
                                                        color='primary'
                                                        size='small'
                                                        variant='contained'
                                                        onClick={(e) => { e.preventDefault(); handlePrint(row.id); }}>
                                                        Print
                                                    </Button>:""}
                                                    {
                                                        permissions['p_exhibition-register-guest-edit'] === true ?
                                                          
                                                                <Button sx={{
                                                                    margin: 1,
                                                                    ":hover": {
                                                                        color: "white"
                                                                    },
                                                                    backgroundColor: '#145348'
                                                                }}
                                                                    color='primary'
                                                                    size='small'
                                                                    variant='contained'
                                                                  component={Link}
                                                                  to={`/admin/exhibition/edit-guest/${row.id}`} >
                                                                    <EditNoteIcon />
                                                                </Button> :""
                                                                }
                                                    {
                                                        permissions['p_exhibition-register-guest-delete'] === true ?
                                                            <Button sx={{
                                                                 margin: 1,
                                                                ":hover": {
                                                                    color: "white"
                                                                },
                                                                backgroundColor: '#145348',
                                                            }}
                                                                color='primary'
                                                                size='small'
                                                                variant='contained'
                                                                onClick={(e) => abstracPendingtDelete(e, row.id)}>
                                                                <DeleteIcon />
                                                            </Button> : ""}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};


export default IndexGuest;
