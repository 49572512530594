import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CardActions from '@mui/material/CardActions';
import SpeedIcon from '@mui/icons-material/Speed';
import { Padding } from "@mui/icons-material";
const approve = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(2)', pl: 5 }}>
    <SpeedIcon />
  </Box>
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>

  );
}
function createData(
  ids,
  title,
  date


) {
  return { ids, title, date };
}
const rows = [
  createData(1, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", '8/5/2023'),
].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const Summary = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const abstractRatingId = props.match.params.id;
  const eventid = localStorage.getItem('eventid')
  const history = useHistory();
  const abstractId = localStorage.getItem('abstractId')
  const [getabstractTitle, setGetabstractTitle] = React.useState('');
  const [getabstractTotal, setGetabstractTotal] = React.useState('');
  useEffect(() => {
    axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
      if (res.status === 200) {
        const getAbstTitle = res.data.abstractTitle;
        const getAbstTotal = res.data;
        setGetabstractTitle(getAbstTitle)
        setGetabstractTotal(getAbstTotal)
      }
      else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    });

  }, []);

  const [selectedValues, setSelectedValues] = useState([]);
  const [questions, setAllQuestions] = useState([]);
  const [reviewerTotalScore, setReviewerTotalScore] = useState(0);
  const [reviewerObtainScore, setReviewerObtainScore] = useState(0);
  const [reviewerName, setReviewerName] = useState('');


  const handleRadioChange = (event, questionId) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [questionId]: event.target.value,
    }));
  };
 

  useEffect(() => {
    axios.get(`api/allquestionsForWithScore/` + abstractRatingId + `/` + eventid + ``).then(res => {
      if (res.status === 200) {
        const allquestions = res.data.allQuestions;
        const reviewer_total_score = res.data.reviewer_total_score;
        const reviewer_obtain_score = res.data.reviewer_obtain_score;
        const reviewer_name = res.data.reviewer_name;
        setAllQuestions(allquestions);
        setReviewerTotalScore(reviewer_total_score);
        setReviewerObtainScore(reviewer_obtain_score);
        setReviewerName(reviewer_name);
      }
      else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
  }, []);

  const questionOptionReview = (e) => {
    e.preventDefault();
    const eventid = localStorage.getItem('eventid')
    const data = {
      abstractRatingId,
      eventid,
      selectedValues,
    }
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/storeAbstractRating`, data).then(res => {
        if (res.data.status === 200) {
          swal('Success', " Rating Added SuccessFully !!!", 'success');
          history.push('/admin/abstract/pending-article');
        }
      });
    });
  }
  const [open, setOpen] = React.useState(false);
  const [singleData, setSingleData] = React.useState({});
  const handleClickShowSingleRecord = (id) => {
    axios.get(`api/singleAbstractQuestion/${id}`).then(res => {
      if (res.status === 200) {
        const data = res.data.singleQuestion;
        setSingleData(data)
      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    })
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function goBack() {
    history.goBack();
  }
  return (
    <Box sx={{ width: '100%' }}>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center" sx={{ mt: 10, }}>
        <Grid item xs={3}>
          <h1 >Abstract Title: {getabstractTitle.title}</h1>
          <hr></hr>
        </Grid> 
        <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            onClick={goBack}>
            Back
          </Button>
      </Grid>
     
      
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={5}>
        <Grid item xs={6} style={{

          color: "purple",
          //backgroundColor: "green",
          marginTop: "2%"
        }}>

          <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', }}>
            <Grid item xs={6} >
              <h4>Reviewed by: {reviewerName}</h4>
              <hr></hr>
            </Grid>
            <Card sx={{ width: '40vw', float: 'center' }} >

              <CardContent >

                <Box component="form" onSubmit={questionOptionReview} noValidate sx={{ mt: 3 }}>

                  <Grid container spacing={4}>

                    <Grid item xs={6} >
                      {questions.map((question , i) => (
                        <div key={question.id}>
                          <h3>{i +1}: {question.label}</h3>
                          
                          {/* {
                            question.rating_table.map((qt)=>{
                             <div>
                              <h3>name:{qt.evaluation_name}</h3>
                             </div>
                            })
                          } */}
                          <div>
                           {question.rating_table.map((qq, i) => (<>
                           
                                <h4>{qq.evaluation_name} -  {qq.obtain_scores}/{qq.total_scores}</h4>
                                
                                </>
                            ))}
                              </div>

                          <h3>Score: {question.evaluation_name} -  {question.obtain_scores}/{question.total_scores}</h3>
                          <hr></hr>
                        </div>
                      ))}
                    </Grid>
                    <Grid item xs={3} ></Grid>
                    <Grid item xs={3} ></Grid>
                    <Grid item xs={6} >
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={6} style={{
          color: "purple",
          //backgroundColor: "green",
          marginTop: "2%"
        }}>
          <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '11%' }}>
            <Card sx={{ width: '30vw', float: 'center', }} >
              <CardContent >
                <Grid item xs={4}>
                  <Card sx={{ minWidth: 450 }} >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Total Score
                      </Typography>
                      <Typography variant="body2" variant="h2" color="success.main" key={1}>
                        {reviewerObtainScore}/{reviewerTotalScore}
                        {/* {approve}. {getabstractTotal.abstractObtainScore}/{getabstractTotal.abstractTotalScore}

                        :   */}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small">Avarage Score </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Summary;
