import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

const AddBoothCategory = () => {
    const history = useHistory();
    const [exhibitionAddCategory, setExhibitionAddCategory] = useState({
        title: "",
        size: "",
        descriptions: "",
        error_list: [],
    });
    const [disabled, setdisabled] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setExhibitionAddCategory({ ...exhibitionAddCategory, [e.target.name]: e.target.value });
    }
    const eventid = localStorage.getItem('eventid');
    const exhibitionCateSubmit = (e) => {
        e.preventDefault();
        const data = {
            title: exhibitionAddCategory.title,
            size: exhibitionAddCategory.size,
            descriptions: exhibitionAddCategory.descriptions,
            eventid,

        }

        if (data.title == '') {
            setdisabled(false);
        }
        else if (data.size == '') {
            setdisabled(false);
        }
        else if (data.descriptions == '') {
            setdisabled(false);
        }
        else {
            setdisabled(true);
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(`/api/store-exhibition-booth-category`, data,).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/exhibition/boothcategory');
                }
                else {
                    setExhibitionAddCategory({ ...exhibitionAddCategory, error_list: res.data.validate_error })
                }
            });
        });






    }
    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/exhibition/boothcategory">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Exhibition Booth category
                        </Typography>
                        <Box component="form" onSubmit={exhibitionCateSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Title"
                                        autoFocus
                                        name='title' onChange={handleInput}
                                        helperText={exhibitionAddCategory.error_list.title} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Size"
                                        autoFocus
                                        name='size' onChange={handleInput}
                                        helperText={exhibitionAddCategory.error_list.size} />
                                </Grid>
                                <Grid item xs={6} >

                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth

                                        label="Add Descriptions"
                                        autoFocus
                                        name='descriptions' onChange={handleInput}
                                        multiline
                                        rows={6}
                                        helperText={exhibitionAddCategory.error_list.descriptions} />

                                </Grid>
                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    disabled={disabled}
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348'}} >
                                    Submit
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>

    );
}

export default AddBoothCategory;
