import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
const AddQuestionScore = (props) => {
    const [val, setVal] = useState([]);
    const history = useHistory();
    const eventid = localStorage.getItem('eventid')
    const [aproveArticleEdit, setAproveArticleEdit] = useState([]);
    useEffect(() => {
        const id = props.match.params.id;
        axios.get(`api/abstractArticleEdit/${id}`).then(res => {
            if (res.status === 200) {
                const data = res.data.abstractEdit;
                setAproveArticleEdit(data)
            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        });

    }, [props.match.params.id]);
    const [question, setQuestion] = useState({
        question: "",
    });

    const handleInput = (e) => {
        e.persist();
        setQuestion({ ...question, [e.target.name]: e.target.value });
    }

    const handleAdd = () => {
        const filedAdd = [...val, []]
        setVal(filedAdd)
    }

    const handleChange = (onChangeValue, i) => {
        const inputdata = [...val]
        inputdata[i] = onChangeValue.target.value;
        setVal(inputdata)

    }
    const handleDelete = (i) => {
        const deleteVale = [...val]
        deleteVale.splice(i, 1)
        setVal(deleteVale);

    }
    const addScore = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            eventid,
            article_id: aproveArticleEdit.id,
            question: question.question,
            val,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/addAbstractQuestion`, data).then(res => {
                if (res.data.status === 200) {
                    swal('Success', res.data.message, 'success');
                    history.push('/admin/abstract/pending-article');
                }
            });
        });
    }

    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} onSubmit={addScore} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/abstract/pending-article">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Abstract Title:   {aproveArticleEdit.title}
                        </Typography>
                        <Box component="form" encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Question"
                                        name='question' onChange={handleInput} value={question.question} />
                                </Grid>
                                <Grid container  >
                                    <Button
                                        onClick={handleAdd}
                                        variant="contained"
                                        sx={{ ml: 5, mt: 5 ,}} >
                                        Add Options
                                    </Button>
                                </Grid>
                                <Grid item xs={6} >
                                    {
                                        val.map((data, i) => {
                                            return (
                                                <div key={i + 1}>
                                                    <TextField
                                                        autoComplete="given-name"
                                                        required
                                                        fullWidth
                                                        value={data}
                                                        label="option"
                                                        onChange={e => handleChange(e, i)}
                                                        sx={{ mt: 3 }} />
                                                    <Button variant="contained" onClick={() => handleDelete(i)}>Remove Field</Button>
                                                </div>
                                            )

                                        })}

                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348',}} >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}


export default AddQuestionScore;
