import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';


const EditRequirements = (props) => {
  const history = useHistory();
  const [editRequirement, setEidtRequirement] = useState({
    title: "",
    description: "",
  });


  useEffect(() => {


    const id = props.match.params.id;

    axios.get(`api/abstractrequirementEdit/${id}`).then(res => {

      if (res.status === 200) {
        const requirementeditListEdit = res.data.requirementedit;
        setEidtRequirement(requirementeditListEdit)


      } else {
        swal('Error', "Check API Something Wrong", 'error');
      }

    });


  }, [props.match.params.id]);

  const handleInput = (e) => {
    e.persist();
    setEidtRequirement({ ...editRequirement, [e.target.name]: e.target.value });
  }

  const requirementUpdate = (e) => {

    e.preventDefault();
    const data = {
      title: editRequirement.title,
      description: editRequirement.description

    }
    const rid = props.match.params.id;
    axios.post(`api/abstractrequirementUpdate/${rid}`, data).then(res => {

      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
        history.push('/admin/requirements/requirements');

      }
      else {
        setEidtRequirement({ ...editRequirement, error_list: res.data.validate_error })
      }

    })
  }


  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            component={Link}
            to="/admin/requirements/requirements">
            BAck
          </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Edit Requirements
            </Typography>
            <Box component="form" onSubmit={requirementUpdate} noValidate sx={{ mt: 3 }}>
              <Grid container spacing={4}>
                <Grid item xs={3} ></Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="firstName"
                    label="Requirement Title"
                    autoFocus
                    name='title' onChange={handleInput} value={editRequirement.title}
                  />
                </Grid>
                <Grid item xs={3} ></Grid>
                <Grid item xs={3} ></Grid>
                <Grid item xs={6} >

                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth

                    label=" Details"
                    autoFocus
                    name='description'
                    multiline
                    rows={6} onChange={handleInput} value={editRequirement.description} />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  sx={{ mt: 5, backgroundColor: '#145348',}} >
                  Update
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default EditRequirements;
