import React, { useState } from 'react'
import axios from "axios";
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Menu } from '@mui/material';
import {  MenuItem } from '@mui/material';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
//import Badge from '@mui/material/Badge';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ArticleIcon from '@mui/icons-material/Article';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import FeedIcon from '@mui/icons-material/Feed';
import CommentIcon from '@mui/icons-material/Comment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InfoIcon from '@mui/icons-material/Info';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AddCardIcon from '@mui/icons-material/AddCard';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BadgeIcon from '@mui/icons-material/Badge';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { useLocation} from 'react-router-dom';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



function NavBar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorElm, setAnchorElm] = useState(null);
  const [opens, setOpens] = useState(false);
  const handleClose = () => {
    setAnchorElm(null);
    setOpens(false);
  }
  const handleClick = (e) => {

    setAnchorElm(e.currentTarget);
    setOpens(true);

  }

  const history = useHistory();

  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post(`api/logout`).then(res => {

      if (res.data.status === 200) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_name');
        //swal('Success',res.data.message,'success');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        history.push('/');
      }

    });

  }
      
    
    const [openAbstractMenu, setOpenAbstractMenu] = React.useState(false);
  
    const handleClickAbstractMenu = () => {
      setOpenAbstractMenu(!openAbstractMenu);
    }
    
    const [openAbstractSubMenu, setOpenAbstractSubMenu] = React.useState(false);
  
    const handleClickAbstractSubMenu = () => {
      setOpenAbstractSubMenu(!openAbstractSubMenu);
    }

  return (
    <Box  >
      <CssBaseline />

      <AppBar position="absolute" open={open} >
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
            backgroundColor:"success",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Master Dashbord
          </Typography>
          {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

          <IconButton onClick={handleClick}
            sx={{
              color: "white",
              cursor: "pointer",
              marginRight: "1%"
            }}>
            <SettingsIcon />
          </IconButton>
          <Menu anchorEl={anchorElm} open={opens} onClose={handleClose}>
            <MenuItem onClick={handleClose} >Profile  &nbsp;&nbsp;
            <ManageAccountsIcon 
            sx={{
              color:'#1976d2',
             ":hover": {
                color: "#1976d2"
              }
            }} 
            />
            </MenuItem>
            <MenuItem onClick={handleClose}>Setting  &nbsp;&nbsp;
            <AdminPanelSettingsIcon
            sx={{
              color:'#1976d2',
            }} 
             />
            </MenuItem>
            <MenuItem  onClick={logoutSubmit}>LogOut  &nbsp;&nbsp;
            <PowerSettingsNewIcon  
            sx={{
              color:'#1976d2',
            }} 
            /> 
            </MenuItem>

          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            
           
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton onClick={() => history.push('/master/events')}>
            <ListItemIcon  
              sx={{
                color:'#1976d2',
              }}
             >
              <EventNoteIcon onClick={() => history.push('/master/events')} />
            </ListItemIcon>
            <ListItemText primary="Events"
              onClick={() => history.push('/master/events')} />
          </ListItemButton>

          <ListItemButton onClick={() => history.push('/master/archiv-event')}>
            <ListItemIcon   sx={{
                  color:'#1976d2',
                }}
              >
              <RestoreFromTrashIcon onClick={() => history.push('/master/archiv-event')}
               />
            </ListItemIcon>
            <ListItemText primary="Archiv Event" onClick={() => history.push('/master/archiv-event')} />
          </ListItemButton>

          <ListItemButton onClick={() => history.push('/master/user')}>
            <ListItemIcon   sx={{
                  color:'#1976d2',
                }}
              >
              <GroupAddIcon onClick={() => history.push('/master/user')}
               />
            </ListItemIcon>
            <ListItemText primary="User" onClick={() => history.push('/master/user')} />
          </ListItemButton>
          {/* <ListItemButton onClick={() => history.push('/admin/role')}>
            <ListItemIcon sx={{
                  color:'#1976d2',
                }}>
              <PlaylistAddCheckIcon  onClick={() => history.push('/admin/role')} />
            </ListItemIcon>
            <ListItemText primary="Role" onClick={() => history.push('/admin/role')} />
          </ListItemButton> */}
          <Divider sx={{ my: 1 }} />
          { (currentPath === '/master/events' || currentPath ==='/master/archiv-event') ?  null :
          <ListItemButton onClick={handleClickAbstractMenu} sx={{
              color:'#1976d2'
            }}>
        <ListItemIcon  sx={{
              color:'#1976d2'
            }} >
          <DashboardIcon />
        </ListItemIcon >
          <ListItemText primary="Abstract MS" sx={{
              color:'#1976d2'
            }}/> 
        {openAbstractMenu ? <ExpandLess /> : <ExpandMore sx={{
              color:'#1976d2'
            }}/>}
      </ListItemButton>}
      <Collapse in={openAbstractMenu} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon  sx={{
              color:'#1976d2'
            }}>
              <ArticleIcon />
            </ListItemIcon >
          <ListItemText primary="Articles" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <FactCheckIcon sx={{
              color:'#1976d2'
            }}/>
            </ListItemIcon>
          <ListItemText primary="Approve List" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <SwipeLeftIcon />
            </ListItemIcon>
          <ListItemText primary="Reject List" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <FeedIcon />
            </ListItemIcon>
          <ListItemText primary="Article Category" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <CommentIcon />
            </ListItemIcon>
          <ListItemText primary="Comments" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <SupervisedUserCircleIcon />
            </ListItemIcon >
          <ListItemText primary="Authors" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <ContactPageIcon />
            </ListItemIcon>
          <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <NotificationsActiveIcon/>
            </ListItemIcon>
          <ListItemText primary="Notifications" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <InfoIcon />
            </ListItemIcon>
          <ListItemText primary="About Us" />
          </ListItemButton>
          </List>
        <ListItemButton onClick={handleClickAbstractSubMenu} sx={{
              color:'#1976d2'
            }}>
        <ListItemIcon sx={{
              color:'#1976d2'
            }}>
          <AppSettingsAltIcon />
        </ListItemIcon>
      {/*Sub in sub menue */}
         <ListItemText primary="Setting"  sx={{
              color:'#1976d2'
            }}/>
        {openAbstractSubMenu ? <ExpandLess /> : <ExpandMore  sx={{
              color:'#1976d2'
            }}/>}
      </ListItemButton>
      </Collapse>

      <Collapse in={openAbstractSubMenu} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <LocalPostOfficeIcon /> 
            </ListItemIcon>
            <ListItemText primary="Email Template" /> 
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary="Couponse" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <AddCardIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Gateways" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Pages" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon sx={{
              color:'#1976d2'
            }}>
              <BadgeIcon />
            </ListItemIcon >
            <ListItemText primary="Organiser Details" />
          </ListItemButton>
          </List>
          </Collapse>
          <Divider sx={{ my: 1 }} />
        </List>
      </Drawer>
    </Box>
  )
}

export default NavBar
