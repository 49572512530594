import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';


const AddEvents = () => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [allmodules, setAllmodules] = useState([]);

  const [checkmodules, setCheckedmodules] = useState([]);
  const [addevent, setAddevent] = useState({
    event: "",
    user_id: "",
    modules: [],

  });


  const handlechnage = (e) => {

    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckedmodules([...checkmodules, value])
    }
    else {
      setCheckedmodules(checkmodules.filter((e) => (e !== value)));

    }

  }
  const [user, setUser] = React.useState('');
    const handleChange = (e) => {
      setUser(e.target.value);
    };
    console.log(user);

  const handleInput = (e) => {
    e.persist();

    setAddevent({ ...addevent, [e.target.name]: e.target.value });
  }


  const addEventforUser = (e) => {
    e.preventDefault();


    const data = {
      event: addevent.event,
      user_id:user,
      checkmodules,
      
    }


    axios.get('/sanctum/csrf-cookie').then(response => {

      axios.post(`api/superadmin-storeEvent`, data).then(res => {

        //console.log(res);
        if (res.data.status === 200) {
          history.push('/master/event');
          swal("Success", res.data.message, "success");


        }
        else {
          swal("Field Are Requried !!", "", "error");

        }


      });





    });

  }



  useEffect(() => {
    axios.get(`api/superadmin-alluser`).then(res => {

      if (res.status === 200) {

        setUserList(res.data.users);
        //console.log(res.data.user);

      }
      else {
        setloading(false);
      }


    });




  }, []);

  useEffect(() => {
    axios.get(`api/superadmin-allmodules`).then(res => {

      if (res.status === 200) {

        setAllmodules(res.data.modules);
        //console.log(res.data.modules);

      }
      else {
        setloading(false);
      }


    });


  }, []);




  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
    <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
            <Button sx={{
                float: 'right',
                marginTop: '2%',
                ":hover": {
                    color: "white"
                }
            }}
                color='success'
                size='small'
                variant='contained'
                component={Link}
                to="/master/events">
                BAck
            </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
            <CardContent >
                <Typography component="h1" variant="h5">
                    Add Event
                </Typography>
                <Box component="form" onSubmit={addEventforUser} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                autoComplete="given-name"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                name='event' onChange={handleInput} value={addevent.event}
                                helperText="" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel >Selete User</InputLabel>
                                <Select defaultValue=""
                                   name='user_id'  onChange={handleChange} value={user}
                                    label="User">

                                    {
                                        userList.map((item => {
                                            return (
                                                <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                            )

                                        }))
                                    }

                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormGroup row>  
                                {
                                    allmodules.map((item) => {
                                        return (
                                             
                                            <FormControlLabel required control={<Checkbox />}
                                                label={item.module}
                                                onChange={handlechnage} value={item.id}
                                                name="checkmodules" key={item.id} />


                                        )
                                    })
                                }
                            </FormGroup>
                        </Grid>

                    </Grid>
                   
                    <Grid container justifyContent="flex-end">
                    <Button
                        type="submit"
                        // fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }} >
                        Add User
                    </Button>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    </Box>
</div>
  );
}

export default AddEvents;
