import React from 'react';

const AuthorDashbord = () => {
  return (
    <div>
      <h1>Author DashbordAuthor 
        DashbordAuthor DashbordAuthor
         DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor
          DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor 
          DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor 
          DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor
           DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor 
           DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor
            DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor 
            DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor DashbordAuthor
             DashbordAuthor DashbordAuthor DashbordAuthor Dashbord</h1>
    </div>
  );
}

export default AuthorDashbord;
