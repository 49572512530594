import * as React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {HashRouter , Route , Switch} from 'react-router-dom';
import Login from './Auth/Login';

import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import Home from './frontend/Home';
import MasterLogin from './Auth/MasterLogin';
import AbstractHome from './frontend/abstact/Home';
import AbstractLogin from './Auth/AbstractAuth/Login';
import NewVerstionAbstractLogin from './Auth/AbstractAuth/NewVersion/NewVerstionAbstractLogin';
import NewVersionAbstractRegister from './Auth/AbstractAuth/NewVersion/NewVersionAbstractRegister';
import AbstractRegister from './Auth/AbstractAuth/Register';
import EventIDPage from './frontend/abstact/EventIDPage';
import PdfShow from './frontend/Exhibition/GuestBadge/PdfShow';
import Verifiction from './Auth/Exhibition/Verifiction';
import ExhibitionRegister from './Auth/Exhibition/ExhibitionRegister';
import EmailFound from './Auth/Exhibition/EmailFound';
import ExhibitionEventIDPage from './frontend/Exhibition/ExhibitionEventIDPage';
import Form from './Auth/Exhibition/Form';
import Formbuilder from './Auth/Exhibition/Formbuilder';
import AdminPrivateRoute from './Routes/AdminPrivateRoute';
import MasterAdminPrivateRoute from './Routes/MasterAdminPrivateRoute';
import './App.css';
import axios from 'axios';



 //axios.defaults.baseURL = "http://127.0.0.1:8000/";
//axios.defaults.baseURL ="http://5.78.54.158/v2/laravel/public/";
//axios.defaults.baseURL ="https://dev.cloudregis.com/v2/laravel/public/";
//this one usinig 
axios.defaults.baseURL ="https://cloudregis.com/v2/laravel/public/";





axios.defaults.headers.post['Content-Type'] = "application/json";
axios.defaults.headers.post['Accept'] = "application/json";
axios.defaults.withCredentials = true; 
{/*For logout use*/}
axios.interceptors.request.use( function(config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` :'';
  return config;
});

const mdTheme = createTheme();


function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <HashRouter>
      <Switch>
          <Route exact path="/"  component={Home}/>
          <Route  path="/login"  component={Login}/>
          <Route path="/register" component={Register}/>
          <Route path="/forgot/password" component={ForgotPassword}/>
          <Route path="/reset/password/:id" exact={true} component={ResetPassword}/>
          <Route path="/abstract" component={AbstractHome}/>
          {/* Abstract FrontEnd Routs */}
          <Route path="/abstract-login" component={AbstractLogin}/>
          <Route path="/author/login" component={NewVerstionAbstractLogin}/>
          <Route path="/author/register" component={NewVersionAbstractRegister}/>

          

          
          <Route path="/abstract-register" component={AbstractRegister}/>
          <Route path="/abstract-author" component={EventIDPage}/>
           {/* Exhibition FrontEnd Routs */}
           <Route path="/exhibition/verification" component={Verifiction}/>
           <Route path="/exhibition/register" component={ExhibitionRegister}/>
           <Route path="/exhibition/email-found" component={EmailFound}/>
           <Route path="/exhibition/guest" component={ExhibitionEventIDPage}/>
           <Route path="/exhibition/guest-form" component={Form}/>
           <Route path="/exhibition/formbuilder" component={Formbuilder}/>
           <Route path="/exhibition/guest-badge" component={PdfShow}/>
          
           {/*Routes For Admin */}
        <AdminPrivateRoute path='/admin' name="Admin" />
         <Route exact path="/master/login" component={MasterLogin}/>
        <MasterAdminPrivateRoute  path='/master' name="MasterAdmin"/>
        {/* <AuthorPrivateRoutes path ='/author' name ="Author"/> */}
        
      </Switch>
    </HashRouter>
    </ThemeProvider>
  );
}

export default App;
