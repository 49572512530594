import React, { useState, useEffect } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from '@mui/icons-material';
import _debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';





import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import axios from "axios";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { useHistory } from "react-router-dom";
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Grid from '@mui/material/Grid';

function createData(
    ids,
    title,
    code, type, dimensions, price, exhibitor, status,


) {
    return { ids, title, code, type, dimensions, price, exhibitor, status };
}



const rows = [
    createData(1, "ssss ipsum dolor sit amet, ", '01', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(2, "ssss ipsum dolor sit amet, ", '09', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(3, "ssss ipsum dolor sit amet, ", '07', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(4, "ssss ipsum dolor sit amet, ", '08', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(5, "ssss ipsum dolor sit amet, ", '05', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(6, "ssss ipsum dolor sit amet, ", '09', 'squir', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(7, "ssss ipsum dolor sit amet, ", '07', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(8, "ssss ipsum dolor sit amet, ", '11', 'circle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(9, "ssss ipsum dolor sit amet, ", '41', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(10, "ssss ipsum dolor sit amet, ", '51', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(11, "ssss ipsum dolor sit amet, ", '21', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(12, "ssss ipsum dolor sit amet, ", '31', 'Round', '5m', '500/-SR', 'Meshall', 'Booked'),
    createData(13, "ssss ipsum dolor sit amet, ", '41', 'Tringle', '5m', '500/-SR', 'Meshall', 'Booked'),


].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const BookingBooth = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [permissions, setrPermissions] = useState([]);
    useEffect(() => {
        axios.get(`api/getpermissionByRole`).then(res => {

            if (res.status === 200) {
                const datad = res.data.data.permissions;
                setrPermissions(datad)

            } else {

            }


        })

    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPosts = rows.filter((row) =>
    (row.code  && row.code.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.type &&  row.type.toLowerCase().includes(searchTerm.toLowerCase()))  ||
    (row.exhibitor &&  row.exhibitor.toLowerCase().includes(searchTerm.toLowerCase()))  ||
    (row.title && row.title.toLowerCase().includes(searchTerm.toLowerCase())) 
        // Add more fields as needed
    );

    const totalRecords = rows.length;
    const filteredRecordsCount = filteredPosts.length;



    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginRight: '1%',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/exhibition/reserve">
                        Reserve
                    </Button>
                    <Button sx={{
                        float: 'right',
                        marginRight: '1%',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/exhibition/hold">
                        Hold
                    </Button>

                </h4>
                <Card sx={{ width: '82vw', float: 'center' }} >
                    <CardContent >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography component="h1" variant="h5">
                                Booking History
                            </Typography>
                            Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
                            <Grid item xs={8} >
                                <TextField
                                    fullWidth
                                    label="Filter By: code ,type ,exhibitor , boothcategory"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Grid>
                        </div>
                        <Box component="form" noValidate sx={{ mt: 3 }}>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell > #</TableCell>
                                            <TableCell align="left"> Booth Category</TableCell>
                                            <TableCell align="left">Code</TableCell>
                                            <TableCell align="left">Type</TableCell>
                                            <TableCell align="left">Dimensions	</TableCell>
                                            <TableCell align="left">Price</TableCell>
                                            <TableCell align="left">Exhibitor</TableCell>
                                            <TableCell align="left">Status</TableCell>
                                            {
                                                permissions['p_exhibition-booking-edit'] === true ?
                                                    <TableCell align="center" style={{ width: 260 }}>Action</TableCell> :
                                                    permissions['p_exhibition-booking-delete'] === true ?
                                                        <TableCell align="center" style={{ width: 260 }}>Action</TableCell> :
                                                        <TableCell align="center"></TableCell>}

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPosts.slice(startIndex, endIndex).map((row, index) => (
                                            <TableRow key={index}>
                                                <>
                                                    <TableCell key={row.ids}>{row.ids}</TableCell>
                                                    <TableCell >{row.title}</TableCell>
                                                    <TableCell >{row.code}</TableCell>
                                                    <TableCell >{row.type}</TableCell>
                                                    <TableCell >{row.dimensions}</TableCell>
                                                    <TableCell >{row.price}</TableCell>
                                                    <TableCell >{row.exhibitor}</TableCell>
                                                    <TableCell >{row.status}</TableCell>
                                                    <TableCell >
                                                        {
                                                            permissions['p_exhibition-booking-edit'] === true ?
                                                                <Button sx={{ marginRight: 1, ":hover": { color: "white" } }}
                                                                    color='primary'
                                                                    size='small'
                                                                    variant='contained'
                                                                    component={Link}
                                                                >
                                                                    <EditNoteIcon />
                                                                </Button> : ""}
                                                        {
                                                            permissions['p_exhibition-booking-delete'] === true ?
                                                                <Button sx={{ ":hover": { color: "white" } }}
                                                                    color='primary'
                                                                    size='small'
                                                                    variant='contained'
                                                                >
                                                                    <DeleteIcon />
                                                                </Button> : ""}
                                                    </TableCell>

                                                </>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                                    component="div"
                                    count={filteredPosts.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>

                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default BookingBooth;
