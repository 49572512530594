import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ListItemText from "@mui/material/ListItemText";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  'Booth One',
  'Booth Two',
  'Booth Three',
  'Booth Four',
  'Booth Five',
  'Booth Six',
  'Booth Seven',

];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EditExhibitor = (props) => {
  const id = props.match.params.id;
  const theme = useTheme();
  const eventid = localStorage.getItem('eventid')
  const [exhibitiorCategory, setExhibitiorCategory] = React.useState([]);
  const [exhibitionBoothIndex, setExhibitionBoothIndex] = React.useState([]);
  const [exhibitorCategory, setExhibitorCategory] = React.useState('');
  const [assignBooth, setAssignBooth] = useState([]);
  const [editExibitior, setEditExibitior] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    error_list: [],
  });





  useEffect(() => {
    axios.get(`api/edit-orgniserExhibitor/${id}`).then((res) => {
      if (res.status === 200) {
        const editData = res.data.data;
        setEditExibitior(editData.exEh); // Update state with exhibitor data
        setExhibitorCategory(editData.exhibitionDetails.exhibition_booth_category_id);
        setAssignBooth(editData.assignedBooths.map((booth) => booth.exhibition_booth_id));

        // Set the initial value for "Company Name" TextField
        setEditExibitior((prevData) => ({
          ...prevData,

          companyname: editData.exhibitionDetails.company_name,
          type: editData.exhibitionDetails.type,


        }));
      }

    });
  }, [id]);


  React.useEffect(() => {
    axios.get(`api/index-exhibitor-category?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const exCate = res.data.exhibitorcategories;
        setExhibitiorCategory(exCate)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);

  React.useEffect(() => {
    axios.get(`api/index-exhibition-booth?id=${eventid}`).then(res => {

      if (res.status === 200) {
        const exbooth = res.data.eb;
        setExhibitionBoothIndex(exbooth)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);



  const handleChangeMulti = (event: SelectChangeEvent<typeof assignBooth>) => {
    const {
      target: { value },
    } = event;
    setAssignBooth(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [exhibitionBooth, setExhibitionBooth] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setExhibitionBooth(

      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const history = useHistory();
  const [exhibitionAddExhibitor, setExhibitionAddExhibitor] = useState({
    title: "",
    size: "",
    descriptions: "",
    error_list: [],
  });


  const handleBooth = (event) => {
    setExhibitorCategory(event.target.value);
  };

  // const handleInput = (e) => {
  //   e.persist();
  //   setEditExibitior({ ...editExibitior, [e.target.name]: e.target.value });
  // }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditExibitior((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };




  const exhibitionupdateExhibitorSubmit = (e) => {
    e.preventDefault();

    // // Check for empty fields
    // if (!editExibitior.companyname || !editExibitior.type || !editExibitior.name || !editExibitior.phone || !editExibitior.email || !editExibitior.password || !exhibitorCategory || !assignBooth) {
    //   // Handle empty fields (e.g., display an error message)
    //   console.error('Please fill in all required fields.');
    //   return;
    // }

    const data = {
      companyname: editExibitior.companyname,
      type: editExibitior.type,
      name: editExibitior.name,
      phone: editExibitior.phone,
      email: editExibitior.email,
      password: editExibitior.password,
      exhibitorCategory,
      assignBooth,
      eventid,
    };

    console.log(data);

    // Rest of your code...

    console.log(data);
    axios.get('/sanctum/csrf-cookie').then(response => {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(`/api/orgniserUpdateExhibitor/${id}`, data,).then(res => {
        if (res.data.status === 200) {
          swal('Success', "", 'success');
          history.push('/admin/exhibition/allexhibitor');
        }
        else {
          setExhibitionAddExhibitor({ ...exhibitionAddExhibitor, error_list: res.data.validate_error })
        }
      });
    });
  }
  return (
    <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            // component={Link}
            // to="/admin/exhibition/booth"
            onClick={() => history.goBack()}>
            BAck
          </Button>
        </h4>
        <Card sx={{ width: '60vw', float: 'center' }} >
          <CardContent >
            <Typography component="h1" variant="h5">
              Update Exhibitor 
            </Typography>
            <Box component="form" onSubmit={exhibitionupdateExhibitorSubmit} noValidate sx={{ mt: 3 }}>
              <Grid container spacing={4}>

                <Grid item xs={6}>
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Company Name"
                    autoFocus
                    name='companyname'
                    value={editExibitior.companyname || ""}
                    onChange={handleInput}

                  />
                </Grid>
                <Grid item xs={6} >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Exhibitor Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={exhibitorCategory}
                      onChange={handleBooth}
                      label="Action" >
                      {exhibitiorCategory.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Type"
                    autoFocus
                    value={editExibitior.type || ""}
                    name='type' onChange={handleInput}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl sx={{ m: 1, width: 400 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Booths</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={assignBooth}
                      onChange={handleChangeMulti}
                      input={<OutlinedInput label="Article Categoy" />}
                      renderValue={(selected) => selected.join(" , ")}
                      MenuProps={MenuProps}
                    >
                      {exhibitionBoothIndex.map((item) => (
                        <MenuItem key={item.id} value={item.id} >
                          <Checkbox checked={assignBooth.indexOf(item.id) > -1} />
                          <ListItemText primary={item.code} />
                        </MenuItem>
                      ))}
                    </Select>
                   
                  </FormControl>
                </Grid> */}
                <Grid item xs={6}>
                  <FormControl sx={{ m: 1, width: 400 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Booths</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={assignBooth}
                      onChange={handleChangeMulti}
                      input={<OutlinedInput label="Article Categoy" />}
                      renderValue={(selected) => selected.map(id => exhibitionBoothIndex.find(item => item.id === id)?.code).join(", ")}
                      MenuProps={MenuProps}
                    >
                      {exhibitionBoothIndex.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={assignBooth.indexOf(item.id) > -1} />
                          <ListItemText primary={item.code} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12} >
                  <Typography variant="h6" sx={{ color: '#2196f3', fontWeight: 'bold' }}>Contact</Typography>
                  <Typography variant="subtitle1" sx={{ color: '#2196f3', fontWeight: 'bold' }}>For event-related communication. Will not be shown on the floor plan.</Typography>
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Contact Name"
                    autoFocus
                    value={editExibitior.name || ""}
                    name='name' onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Contact Phone"
                    autoFocus
                    value={editExibitior.phone}
                    name='phone' onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={6} >
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Contact Email"
                    type="email"
                    value={editExibitior.email}
                    autoFocus
                    name='email' onChange={handleInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    name='password'
                    onChange={handleInput}
                  />
                </Grid>



              </Grid>

              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  sx={{ mt: 5, backgroundColor: '#145348' }} >
                  Submit
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default EditExhibitor;
