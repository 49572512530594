import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import { useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

import TextField from '@mui/material/TextField';
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function createData(
  ids,
  title,
  fullname,
  auhtoremail,
  reviewername,
  revieweremail,
  abstractcategory,
  status,
  type,
  score,
) {
  return { ids, title, fullname, auhtoremail, auhtoremail, reviewername, revieweremail, abstractcategory, status, type, score, };
}
const rows = [
  createData(1, "Lorem ipsum dolor sit amet, consectetuer adipiscing elit", "Author", "author@gmail.com", "Reviewer", "Reviewer@gmail.com", "Lorem ipsum dolor sit amet", "Accept", "Abstract", '28/30'),

].sort((a, b) => (a.SubmitStatus < b.SubmitStatus ? -1 : 1));

const AllReview = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const history = useHistory();
  const abstractId = props.match.params.id;
  localStorage.setItem('abstractId', abstractId);

  const location = useLocation();
  const currentPath = location.pathname;

  const [permissions, setrPermissions] = React.useState([]);
  const AuthUserName = localStorage.getItem('auth_name');
  React.useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad);
        setLoading(false);
      } else {

      }
    })

  }, []);

  const eventId = localStorage.getItem('eventid')
  const [getabstractTitle, setGetabstractTitle] = React.useState('');
  const [getabstractTotal, setGetabstractTotal] = React.useState('');



  useEffect(() => {
    axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
      if (res.status === 200) {
        const getAbstTitle = res.data.abstractTitle;
        const getAbstTotal = res.data;
        setGetabstractTitle(getAbstTitle)
        setGetabstractTotal(getAbstTotal)
      }
      else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    });

  }, []);


  console.log("getabstractTotal", getabstractTotal)
  const [getAllReview, setGetAllReview] = React.useState([]);
  useEffect(() => {
    axios.get(`api/getAllReviews/` + abstractId + `/` + eventId + ``).then(res => {
      if (res.status === 200) {
        const getAllReviewData = res.data.data.reviews;
        setGetAllReview(getAllReviewData)
      }
      else {
        swal('Error', "Check API Something Wrong", 'error');
      }
    });

  }, []);


  const [aproveArticleEdit, setAproveArticleEdit] = React.useState({
    id: '',
    writer_id: '',
    title: '',
    description: '',
    image: '',
    attachment: '',
    video_link: '',
    reject: '',
    created_by: '',
    start_date: '',
    error_list: [],

  });

  const id = props.match.params.id;
  // useEffect(() => {

  //   axios.get(`api/abstractArticleEdit/${id}`).then(res => {

  //     if (res.status === 200) {
  //       const data = res.data.abstractEdit;
  //       setAproveArticleEdit(data)


  //     } else {
  //       swal('Error', "Check API Something Wrong", 'error');
  //     }

  //   });

  // }, [id]);
  //  console.clear()
  // console.log(aproveArticleEdit.title);
  //console.log(getabstractTitle.writer_id)

  const [selectedValue, setSelectedValue] = React.useState(2);
  const [loading, setLoading] = React.useState(true)
  const [abstractReject, setAbstractReject] = React.useState({
    reject_reason: "",
    error_list: [],
  })
  const handleInput = (e) => {
    e.persist();
    setAbstractReject({ ...abstractReject, [e.target.name]: e.target.value });
  }
  const [isVisible, setIsVisible] = React.useState(false);
  const handleDropdownChange = (event) => {

    if (event.target.value === 1) {
      const strconfirm = window.confirm("Are you sure you want to Approve. once Approved Letter of Acceptance will be sent to author.");
      if (strconfirm == false) {
        return event.target.value === 2;
      }
    }
    if (event.target.value === 3) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    setSelectedValue(event.target.value);
  };

  const scoreActionSubmit = (e) => {
    e.preventDefault();

    const data = {
      abstractId,
      eventId,
      selectedValue,
      reject_reason: abstractReject.reject_reason,
      writer_id: getabstractTitle.writer_id,
      title: getabstractTitle.title
    }

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.post(`/api/abstractActionUpdate`, data).then(res => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success');
          history.push('/admin/abstract/pending-article');
        }
        else if (res.data.status === 401) {
          swal('Warning', res.data.message, 'warning');
        }
        else {

          setAbstractReject({ ...abstractReject, error_list: res.data.validate_error })

        }
      });
    });



  }

  return (
    <div style={{ marginTop: '3%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ width: '100%' }}>
        <h4 sx={{ m: 2, color: '#000000' }} >
          <Button sx={{
            float: 'right',
            marginRight: '1%',
            marginTop: '2%',
            ":hover": {
              color: "white"
            }
          }}
            color='success'
            size='small'
            variant='contained'
            onClick={() => history.goBack()}>
            Back
          </Button>
        </h4>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center" >
          <Grid item xs={3}>

            <h1>Abstract Title: {getabstractTitle.title}</h1>

            <hr></hr>
            {
              getabstractTitle.issues_count > 0 ?
                <Typography
                  variant="h6" component="h1" style={{ color: "#aa2e25" }}>
                  Note: This Abstract has  pending issues
                </Typography> :
                ""
            }

          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop={5}>

          <Grid item xs={6} style={{
            color: "purple",
            marginTop: "2%"
          }}>

            <Box sx={{ color: '#000000', }} style={{ marginLeft: '11%', marginTop: '7%' }}>
              {
                isVisible === false ?

                  <Card sx={{ width: '40vw', float: 'center', height: '14vw' }} >
                    <CardContent >

                      <Box component="form" onSubmit={scoreActionSubmit} noValidate sx={{ mt: 3 }}>

                        <Grid item xs={6} sx={{ ml: 15 }}>
                          <FormControl fullWidth>
                            {
                              permissions['p_abstract-review-status'] === true ?
                                <InputLabel id="demo-simple-select-label" >Action</InputLabel>
                                : ""}
                            {
                              permissions['p_abstract-review-status'] === true ?
                                getabstractTitle.review_status === 1 ?
                                  <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center" >
                                    <Grid item xs={3}>
                                      <h4>Approved</h4>
                                      <hr></hr>
                                    </Grid>
                                  </Grid>
                                  :

                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Action"

                                    onChange={handleDropdownChange} >
                                    {
                                      getabstractTitle.issues_count === 0 ?
                                        <MenuItem value={1}>Approved</MenuItem> :
                                        ""
                                    }
                                    <MenuItem value={2}>Pending</MenuItem>
                                    <MenuItem value={3}>Reject</MenuItem>
                                  </Select> : ""
                            }

                          </FormControl>
                          <h4>{loading === true ? <span>Loading....</span> : ""}</h4>


                          {/* onChange={handleInput} value={aproveArticleEdit.reject} */}

                          {permissions['p_abstract-review-status'] === true ?
                            getabstractTitle.review_status === 1 ? "" :
                              <Grid container justifyContent="flex-end">
                                <Button
                                  type="submit"
                                  // fullWidth
                                  variant="contained"
                                  sx={{ mt: 5, backgroundColor: '#145348', }} >
                                  Save Changes
                                </Button>
                              </Grid> : ""
                          }

                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                  : <Card sx={{ width: '40vw', float: 'center', height: '34vw' }} >
                    <CardContent >
                      <Box component="form" onSubmit={scoreActionSubmit} noValidate sx={{ mt: 3 }}>
                        <Grid item xs={6} sx={{ ml: 15 }}>
                          <FormControl fullWidth>
                            {
                              permissions['p_abstract-review-status'] === true ?
                                <InputLabel id="demo-simple-select-label" >Action</InputLabel>
                                : ""}
                            {
                              permissions['p_abstract-review-status'] === true ?
                                getabstractTitle.review_status === 1 ?
                                  <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center" >
                                    <Grid item xs={3}>
                                      <h4>Approved</h4>
                                      <hr></hr>
                                    </Grid>
                                  </Grid>
                                  :
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    label="Action"

                                    onChange={handleDropdownChange} >
                                    {
                                      getabstractTitle.issues_count === 0 ?
                                        <MenuItem value={1}>Approved</MenuItem> :
                                        ""
                                    }
                                    <MenuItem value={2}>Pending</MenuItem>
                                    <MenuItem value={3}>Reject</MenuItem>
                                  </Select> : ""
                            }

                          </FormControl>
                          {isVisible ? (
                            <Grid item xs={12} >
                              <TextField
                                margin="dense"
                                label="Abstract Reject Reason"
                                name='reject_reason'
                                multiline
                                fullWidth
                                rows={6}
                                required
                                rows={6} onChange={handleInput} value={abstractReject.reject_reason}
                                helperText={abstractReject.error_list.reject_reason}
                              />
                            </Grid>) : ""}
                          {/* onChange={handleInput} value={aproveArticleEdit.reject} */}

                          {permissions['p_abstract-review-status'] === true ?
                            getabstractTitle.review_status === 1 ? "" :
                              <Grid container justifyContent="flex-end">
                                <Button
                                  type="submit"
                                  // fullWidth
                                  variant="contained"
                                  sx={{ mt: 5, }} >
                                  Save Changes
                                </Button>
                              </Grid> : ""
                          }

                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>}
            </Box>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "2%" }}>
            <Box sx={{ color: '#000000', marginLeft: '11%', marginTop: '7%' }}>
              <Grid container spacing={15}>
                {/* Total Score Card */}
                <Grid item xs={4}>
                  <Card sx={{ minWidth: 250, width: '100%' }}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Total Score
                      </Typography>
                      <Typography variant="h2" color="success.main">
                        {getabstractTotal.abstractObtainScore}/{getabstractTotal.abstractTotalScore}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small">Total Score</Button>
                    </CardActions>
                  </Card>
                </Grid>

                {/* Average Score Card */}
                <Grid item xs={4}>
                  <Card sx={{ minWidth: 350, width: '100%' }}>
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Average Score
                      </Typography>
                      <Typography variant="h2" color="success.main">
                        {/* Replace with actual average score */}
                        {getabstractTotal.averageObtainScore}/{getabstractTotal.averageTotalScore}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small">Average Score</Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>

        </Grid>
        <hr></hr>
      </Box>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
        <Card sx={{ width: '82vw', float: 'center' }} >

          <CardContent >
            <Typography component="h1" variant="h5">
              List of Review
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="left">Submission Title</TableCell>
                      <TableCell align="left"> Full Name</TableCell>
                      <TableCell align="left"> Email</TableCell>
                      <TableCell align="left">Reviewer Name</TableCell>
                      <TableCell align="left"> Reviewer Email</TableCell>
                      <TableCell align="left">Abstract Category</TableCell>
                      <TableCell align="left"> Status </TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Score </TableCell>
                      <TableCell align="left">Summary </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? getAllReview.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : getAllReview
                    ).map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 60 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="left">
                          {row.title}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.writer_name}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.writer_email}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.reviewer_name}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.reviewer_email}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="left">
                          {row.abstract_category}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.rating_status}
                        </TableCell>
                        <TableCell style={{ width: 80 }} align="left" >
                          {
                            row.type === 1 ?
                              <Typography variant="h6" sx={{ color: '#009688', fontWeight: 'bold' }}>Abstract</Typography> :
                              row.type === 2 ?
                                <Typography variant="h6" sx={{ color: '#1b5e20', fontWeight: 'bold' }}>Conf Presentation</Typography> :
                                row.type === 3 ?
                                  <Typography variant="h6" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Poster</Typography> :
                                  row.type === 4 ?
                                    <Typography variant="h6" sx={{ color: '#795548', fontWeight: 'bold' }}>Workshop Paper</Typography> :
                                    row.type === 5 ?
                                      <Typography variant="h6" sx={{ color: '#3e2723', fontWeight: 'bold' }}> Full Research Paper</Typography> :
                                      row.type === 6 ?
                                        <Typography variant="h6" sx={{ color: '#607d8b', fontWeight: 'bold' }}>Competition</Typography> :
                                        row.type === 7 ?
                                          <Typography variant="h6" sx={{ color: '#827717', fontWeight: 'bold' }}>Research Award</Typography> :
                                          <Typography variant="h6" sx={{ color: '#d50000', fontWeight: 'bold' }}>N:A</Typography>


                          }
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          {row.abstract_obtain_score}/{row.abstract_total_score}
                        </TableCell>
                        <TableCell style={{ width: 10 }} align="left">
                          <Button sx={{
                            margin: 1,
                            ":hover": {
                              color: "white"
                            }
                          }}
                            color='primary'
                            size='small'
                            variant='contained'

                            component={Link}
                            to={`/admin/abstract/summary/${row.id}`}>
                            <VisibilityIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={getAllReview.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export default AllReview;
