import React from 'react';
import { Link } from "react-router-dom";
import {  Box } from '@mui/material';


// const url = '/abstract-author/53'
// const url = '/abstract-author'

// let AbstractbaseURL =url;
function NavbarForFront() {
  return (
   
        <Box sx={{ color: '#000000', }} style={{ marginTop: '2%',  marginLeft: '39%' }}>
             
             <Link to="/" style={{color:'#1976d2' }}>Home</Link>
             <Link to="/login"  style={{ margin: '7%',color:'#1976d2'  }}>Login</Link>
             <Link to="/register"   style={{color:'#1976d2' }}>Register</Link>
             {/* <Link to="/abstract-author"   style={{margin: '7%' ,color:'#1976d2' }}>Abstract</Link> */}
             
             </Box>    
           
             
    
  )
}

export default NavbarForFront
