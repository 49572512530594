import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';


import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
import { FormatBold } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormLabel from '@mui/material/FormLabel';
const Profile = () => {
    const history = useHistory();
    const [abstractReviewerImage, setAbstractReviewerImage] = useState("");
    const [profile, setProfile] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        dateofbirth: "",
        address: "",
    });

    const eventid = localStorage.getItem('eventid');
    const belongTo = 10;

    const [customFieldLoad, setCustomFieldLoad] = React.useState([]);

    React.useEffect(() => {
        axios.get(`api/load-customfield/${belongTo}/${eventid}`).then(res => {

            if (res.status === 200) {
                const FieldLoad = res.data.customFieldLoad;
                setCustomFieldLoad(FieldLoad)

            } else {
                swal('Error', "Check API Something Wrong", 'error');

            }
        })

    }, []);


    const [customFieldInput, setCustomFieldInput] = useState('');

    const [customFieldTextArea, setCustomFieldTextArea] = useState('');

    const [customFieldNumber, setCustomFieldNumber] = useState('');

    const [customFieldselectedRadio, setSelectedGender] = useState({});
    const handleChangeGender = (item) => (event) => {
        setSelectedGender({
            ...customFieldselectedRadio,
            [item]: event.target.value,
        });

    };
    const [customFieldselectedLoadDropDwon, setSelectedLoadDropDwon] = React.useState('');
    const handleTypeCustomLoad = (item) => (event) => {
        setSelectedLoadDropDwon({ ...customFieldselectedLoadDropDwon, [item]: event.target.value, });

    };

    const [customFieldmultipleSeleteValue, setMultipleSeleteValue] = React.useState([]);
    const handleChangeMulti = (item) => (event) => {
        setMultipleSeleteValue((prevValues) => ({
            ...prevValues,
            [item]: event.target.value,
        }));
    };

    const [customFieldcheckbox, setCumstomFieldCheckbox] = useState({});
    const handleCheckboxChange = (fieldId, optionName) => {
        setCumstomFieldCheckbox((prevValues) => {
          const newState = { ...prevValues };
      
          if (newState[fieldId]) {
            // If the fieldId exists, toggle the optionName
            newState[fieldId] = newState[fieldId].includes(optionName)
              ? newState[fieldId].filter((name) => name !== optionName)
              : [...newState[fieldId], optionName];
          } else {
            // If the fieldId doesn't exist, create a new array with optionName
            newState[fieldId] = [optionName];
          }
      
         
          return newState;
        });
      };
    // const handleCheckboxChange = (handleCheckboxChange) => (event) => {
    //     setCumstomFieldCheckbox((prevValues) => ({
    //         ...prevValues,
    //         [handleCheckboxChange]: event.target.checked,
    //     }));
    // };


    const [customImage, setCustomtImage] = useState({});
    const handleImageChange = (item) => (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setCustomtImage((prevSources) => ({
                ...prevSources,
                [item]: e.target.result,
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const [customFile, setCustomtFile] = useState('')
    const handleImageChangeFile = (item) => (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setCustomtFile((prevSources) => ({
                ...prevSources,
                [item]: e.target.result,
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    React.useEffect(() => {

        axios.get(`api/userprofile/${belongTo}/${eventid}`).then(res => {
            if (res.status === 200) {
                const userprofile = res.data.userProfile;
                setProfile(userprofile);

            }
        });
    }, []);

    // React.useEffect(() => {

    //     axios.get(`api/userprofile/${belongTo}/${eventid}`).then(res => {

    //         if (res.status === 200) {
    //             const userprofile = res.data.userProfile;
    //             setProfile(userprofile);
    //         }
    //     })

    // }, []);
    const handleInput = (e) => {
        e.persist();
        setProfile({ ...profile, [e.target.name]: e.target.value })

    }


    const updateUserProfile = (e) => {
        e.preventDefault();
        const data = {

            abstractReviewerImage,
            name: profile.name,
            email: profile.email,
            phone: profile.phone,
            address: profile.address,
            date: profile.dateofbirth,

            belongTo,
            eventid,
            customFieldInput,
            customFieldselectedRadio,
            customFieldselectedLoadDropDwon,
            customFieldmultipleSeleteValue,
            customFieldcheckbox,
            customFieldTextArea,
            customFieldNumber,
            customFile,
            customImage,
        }
   
        const id = profile.id;
        axios.get('/sanctum/csrf-cookie').then(response => {
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(`api/reviewerupdate/${id}`, data, config).then(res => {
                if (res.status === 200) {
                    swal('Success', res.data.message, 'success');

                    history.push('/admin/profile/profile');
                    window.location.reload(false);
                }
                else {
                    swal('Error', "api Error", 'error');
                    history.push('/admin/profile/profile');
                }
            })
        })
    }

    return (
        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/profile/profile">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Profile
                        </Typography>
                        <Box component="form" onSubmit={updateUserProfile} encType="multipart/form-data" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={4}>

                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Name"
                                        name='name' onChange={handleInput} value={profile.name || ""} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        name='email'
                                        disabled
                                        onChange={handleInput} value={profile.email || ""} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Phone"
                                        type="text"
                                        name='phone'
                                        onChange={handleInput} value={profile.phone || ""} />
                                </Grid>


                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        label="Address"
                                        name='address'
                                        onChange={handleInput} value={profile.address || ""} />
                                </Grid>

                                <Grid item xs={6} >
                                    <TextField
                                        type="date"
                                        required
                                        fullWidth
                                        autoFocus
                                        label="Date Of Bith"
                                        name='dateofbirth'

                                        onChange={handleInput} value={profile.dateofbirth || ""} />
                                </Grid>

                                <Grid item xs={6} >
                                    <label htmlFor="icon-button-file">
                                        <TextField accept="image/*" id="icon-button-file" type="file" onChange={(e) => setAbstractReviewerImage(e.target.files[0])} />
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                        </IconButton><br />
                                        {
                                            profile.image === "" ?
                                                <span>No Image Uploaded</span> :
                                                <><img src={`${profile.base_url}/abstract/${profile.image}`} width="150" height="150" />
                                                </>
                                        }
                                    </label>
                                </Grid>

                                {
                                    profile.customFieldLoad && profile.customFieldLoad.map((item, index) => {
                                        return (
                                            <Grid item xs={6} key={index + 1}>
                                                {
                                                    item.type == 'input' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            defaultValue={item.default_value}
                                                            autoFocus
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldInput({ ...customFieldInput, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {/* helperText={item.required == "required" ? "item.name field is required" : "" } */}

                                                {
                                                    item.type == 'radioBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            {
                                                                item.options.map((radioOption, index) => {
                                                                    return (

                                                                        <RadioGroup
                                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                                            name={item.name}
                                                                            // value={customFieldselectedRadio[item.id] || ''}
                                                                            value={customFieldselectedRadio[item.id] || item.default_value || ""}
                                                                            onChange={handleChangeGender(item.id)}
                                                                            key={index + 1}>
                                                                            <FormControlLabel value={radioOption.name || ""} control={<Radio />} label={radioOption.name} />
                                                                        </RadioGroup>

                                                                    )
                                                                })

                                                            }  </> : ""}

                                                {item.type == 'select' ?
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">{item.name}</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={customFieldselectedLoadDropDwon[item.id] || ""}

                                                            onChange={handleTypeCustomLoad(item.id)}
                                                            label="Action" >
                                                            {item.options.map((dropDwonValue, index) => {
                                                                return (
                                                                    <MenuItem key={index + 1} value={dropDwonValue.name}>{dropDwonValue.name}</MenuItem>
                                                                )
                                                            })
                                                            }
                                                        </Select>
                                                        <FormHelperText> </FormHelperText>
                                                    </FormControl>
                                                    : ""}
                                                {
                                                    item.type == 'textarea' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            autoFocus={true}
                                                            multiline
                                                            defaultValue={item.default_value || ""}
                                                            rows={4}
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldTextArea({ ...customFieldTextArea, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {
                                                    item.type == 'number' ?
                                                        <TextField
                                                            autoComplete="given-name"
                                                            fullWidth
                                                            type="number"
                                                            inputProps={{ maxLength: 50 }}
                                                            label={item.name}
                                                            autoFocus
                                                            name={item.field_name}
                                                            onChange={(e) => setCustomFieldNumber({ ...customFieldNumber, [item.id]: e.target.value })} />
                                                        : ""
                                                }
                                                {
                                                    item.type == 'multiSelect' ?
                                                        <FormControl key={item.id} variant="outlined" sx={{ width: 395, }}>
                                                            <InputLabel id={`${item.id}-label`}>{item.name}</InputLabel>
                                                            <Select
                                                                label={item.id}
                                                                labelId={`${item.name}-label`}
                                                                multiple
                                                                value={customFieldmultipleSeleteValue[item.id] || []}
                                                                onChange={handleChangeMulti(item.id)}>
                                                                {item.options.map((multipleOption, index) => (
                                                                    <MenuItem key={index + 1} value={multipleOption.name}>{multipleOption.name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        : ""
                                                }
                                                {/* {
                                                    item.type == 'checkBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {item.options.map((multipleOption, index) => (
                                                                    <FormControlLabel
                                                                        key={index + 1}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={customFieldcheckbox[multipleOption.name] || false}
                                                                                onChange={handleCheckboxChange(multipleOption.name)} />} label={multipleOption.name} />
                                                                ))}
                                                            </FormGroup>
                                                        </> : ""

                                                } */}
                                                {
                                                    item.type == 'checkBox' ?
                                                        <>
                                                            <FormLabel id="demo-radio-buttons-group-label">{item.name}</FormLabel>
                                                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {item.options.map((multipleOption, index) => (

                                                                    <FormControlLabel
                                                                        key={index + 1}
                                                                        control={

                                                                            <Checkbox
                                                                                checked={customFieldcheckbox[multipleOption.id]?.includes(multipleOption.name) ?? multipleOption.isChecked}
                                                                                onChange={() => handleCheckboxChange(multipleOption.id, multipleOption.name)}
                                                                            />

                                                                        } label={multipleOption.name} />

                                                                ))}
                                                            </FormGroup>
                                                        </> : ""}

                                                {
                                                    item.type == 'link' ?
                                                        <> <a href={item.default_value} target="_blank">{item.name}</a> </> : ""
                                                }
                                                {
                                                    item.type == 'image' ?
                                                        <>
                                                            <label htmlFor="icon-button-file">
                                                                <Typography variant="h5">{item.image}</Typography>
                                                                <TextField id="icon-button-file"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleImageChange(item.id)} name="[licenseFile]" />
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <PhotoCamera />
                                                                </IconButton><br></br>
                                                                <Typography
                                                                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                                                    {item.name}
                                                                </Typography> <br></br>
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    {
                                                                        item.default_value == null ? "no image upload" :
                                                                            <img src={`${profile.base_url}/abstract/${item.default_value || ""}`} width="250" height="150" />
                                                                    }

                                                                </IconButton>
                                                            </label> </> : ""

                                                }
                                                {
                                                    item.type == 'file' ?
                                                        <>
                                                            <label htmlFor="icon-button-file">
                                                                <Typography variant="h5">{item.image}</Typography>
                                                                <TextField id="icon-button-file"
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={handleImageChangeFile(item.id)} name="[licenseFile]" />
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <AttachmentIcon />
                                                                </IconButton>
                                                                <Typography
                                                                    component="span" style={{ color: "#311b92", fontSize: "90%" }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </label> </> : ""

                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 5, backgroundColor: '#145348', }} >
                                    Save Changes
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default Profile;
